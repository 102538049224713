import { DeliveryMethod, IOrder, Status } from '../../../repository/OrderModel';

export const emptyOrder: IOrder = {
    billingAddress: {
        firstName: '',
        lastName: '',
        city: '',
        country: '',
        county: '',
        email: '',
        line1: '',
        line2: '',
        phoneNumber: '',
        postCode: ''
    },
    deliveryAddress: {
        firstName: '',
        lastName: '',
        city: '',
        country: '',
        county: '',
        email: '',
        line1: '',
        line2: '',
        phoneNumber: '',
        postCode: ''
    },
    deliveryAddressSameAsBilling: true,
    deliveryMethod: DeliveryMethod.COURIER,
    referenceNumber: '',
    status: {
        isPaused: false,
        status: Status.ANONYMISED,
        statusDescription: '',
        possibleActions: []
    },
    submittedAt: '',
    comments: [],
    orderLines: []
};

const { comments, ...rest } = emptyOrder;

export const emptyBaseOrder = { ...rest };
