import { action } from 'typesafe-actions';
import {
    Actions,
    IOrder,
    IOrderUpdateRequest
} from '../../repository/OrderModel';
import {
    ACCEPT_ORDER,
    ACCEPT_ORDER_LINE,
    CANCEL_ORDER,
    CANCEL_ORDER_LINE,
    GET_ORDER,
    PAUSE_ORDER,
    RESUBMIT_ORDER,
    RESUBMIT_ORDER_LINE,
    RESUPPLY_ORDER,
    RESUPPLY_ORDER_LINE,
    SET_COVER,
    SET_ORDER,
    UNPAUSE_ORDER,
    UPDATE_ORDER
} from './constants';

export const getOrder = (orderRef: string) => action(GET_ORDER, orderRef);

export const setOrder = (order: IOrder) => action(SET_ORDER, order);

export const cancelOrder = (
    orderRef: string,
    reason: string,
    comment: string
) =>
    action(CANCEL_ORDER, {
        orderRef,
        reason,
        comment,
        successMessage: 'Order was canceled',
        failureMessage: 'An error occurred while cancelling order',
        action: Actions.CANCEL
    });

export const resubmitOrder = (
    orderRef: string,
    reason: string,
    comment: string
) =>
    action(RESUBMIT_ORDER, {
        orderRef,
        reason,
        comment,
        successMessage: 'Order was resubmitted',
        failureMessage: 'An error occurred while resubmitting order',
        action: Actions.RESUBMIT
    });

export const resupplyOrder = (
    orderRef: string,
    reason: string,
    comment: string
) =>
    action(RESUPPLY_ORDER, {
        orderRef,
        reason,
        comment,
        successMessage: 'Order was resupplied',
        failureMessage: 'An error occurred while resuppling order',
        action: Actions.RESUPPLY
    });

export const pauseOrder = (orderRef: string, reason: string, comment: string) =>
    action(PAUSE_ORDER, {
        orderRef,
        reason,
        comment,
        successMessage: 'Order was put on hold',
        failureMessage: 'An error occurred while holding order',
        action: Actions.PAUSE
    });

export const unpauseOrder = (orderRef: string) =>
    action(UNPAUSE_ORDER, {
        orderRef,
        successMessage: 'Order was released',
        failureMessage: 'An error occurred while releasing order',
        action: Actions.UNPAUSE
    });

export const acceptOrder = (orderRef: string) =>
    action(ACCEPT_ORDER, {
        orderRef,
        successMessage: 'Order was accepted',
        failureMessage: 'An error occurred while accepting order',
        action: Actions.ACCEPT
    });

export const cancelOrderLine = (
    orderRef: string,
    orderLineRef: string,
    reason: string,
    comment: string
) =>
    action(CANCEL_ORDER_LINE, {
        orderRef,
        orderLineRef,
        reason,
        comment,
        successMessage: 'Order line was canceled',
        failureMessage: 'An error occurred while canceling order line',
        action: Actions.CANCEL
    });

export const resupplyOrderLine = (
    orderRef: string,
    orderLineRef: string,
    reason: string,
    comment: string
) =>
    action(RESUPPLY_ORDER_LINE, {
        orderRef,
        orderLineRef,
        reason,
        comment,
        successMessage: 'Order line was resupply',
        failureMessage: 'An error occurred while resuppling order line',
        action: Actions.RESUPPLY
    });

export const resubmitOrderLine = (
    orderRef: string,
    orderLineRef: string,
    reason: string,
    comment: string
) => {
    return action(RESUBMIT_ORDER_LINE, {
        orderRef,
        orderLineRef,
        reason,
        comment,
        successMessage: 'Order line was resubmit',
        failureMessage: 'An error occurred while resubmitting order line',
        action: Actions.RESUBMIT
    });
};

export const acceptOrderLine = (orderRef: string, orderLineRef: string) =>
    action(ACCEPT_ORDER_LINE, {
        orderRef,
        orderLineRef,
        successMessage: 'Order line was accepted',
        failureMessage: 'An error occurred while accepting order line',
        action: Actions.ACCEPT
    });

export const updateOrder = (order: IOrderUpdateRequest) =>
    action(UPDATE_ORDER, order);

export const setCover = (coverIndex: number, url: string, file: File) =>
    action(SET_COVER, { coverIndex, url, file });
