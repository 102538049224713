import React, { useCallback, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Checkbox } from '../../../../../../../../../../Components/Checkbox';
import {
    IOrder,
    IAddress
} from '../../../../../../../../../../repository/OrderModel';

import './SameAsBilling.scss';

export interface ISameAsBillingProps {
    name: string;
    disabled: boolean;
}

const SameAsBilling: React.FC<ISameAsBillingProps> = ({ name, disabled }) => {
    const [deliveryAddress, setDeliveryAddress] = useState<
        IAddress | undefined
    >(undefined);
    const [field] = useField<boolean>(name);
    const { setFieldValue, values, setFieldTouched } = useFormikContext<
        IOrder
    >();

    useEffect(() => {
        if (values.deliveryAddressSameAsBilling) {
            setFieldTouched(
                'deliveryAddress.phoneNumber' as keyof IOrder,
                true
            );
            setFieldValue(
                'deliveryAddress',
                { ...values.billingAddress } as IAddress,
                true
            );
        }
    }, [
        values.billingAddress,
        values.deliveryAddressSameAsBilling,
        setFieldTouched,
        setFieldValue
    ]);

    const onChange = useCallback(
        (value: string) => {
            setFieldValue(name as keyof IOrder, !field.value, true);
            if (!field.value) {
                setFieldTouched(
                    'deliveryAddress.phoneNumber' as keyof IOrder,
                    true
                );
                setDeliveryAddress({ ...values.deliveryAddress });
                setFieldValue(
                    'deliveryAddress',
                    { ...values.billingAddress } as IAddress,
                    true
                );
            } else {
                if (!deliveryAddress) {
                    return;
                }
                setFieldTouched(
                    'deliveryAddress.phoneNumber' as keyof IOrder,
                    true
                );
                setFieldValue(
                    'deliveryAddress',
                    { ...deliveryAddress } as IAddress,
                    true
                );
                setDeliveryAddress(undefined);
            }
        },
        [
            field.value,
            setFieldValue,
            name,
            values.deliveryAddress,
            values.billingAddress,
            deliveryAddress,
            setDeliveryAddress,
            setFieldTouched
        ]
    );

    return (
        <Checkbox
            checked={field.value}
            label='Same as Billing Address'
            value={name}
            onChange={onChange}
            inOrderForm={true}
            disabled={disabled}
        />
    );
};

export default SameAsBilling;
