import * as React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import { FullPageSpinner } from '../../Components/FullPageSpinner';
import { TitleSection } from '../../Components/TitleSection';
import { orderActions, orderSelectors } from '../../features/Order';
import { OrderForm } from './Components/OrderForm';
import { OrderSummary } from './Components/OrderSummary';

export interface IIdProps {
    id: string;
}

export interface IOrderEditPageProps {
    match: match<IIdProps>;
    loadingOrder: boolean;
    getOrder: (orderRef: string) => void;
}

export class OrderEditPage extends React.PureComponent<IOrderEditPageProps> {
    public componentDidMount() {
        this.props.getOrder(decodeURIComponent(this.props.match.params.id));
    }

    public render() {
        const { loadingOrder } = this.props;

        return (
            <React.Fragment>
                <TitleSection subtitle='Edit order' />
                <div className='order-edit-page'>
                    {loadingOrder && <FullPageSpinner />}
                    <OrderSummary />
                    <OrderForm />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: Types.RootState) => ({
    loadingOrder: orderSelectors.getLoadingOrder(state)
});

const mapDispatchToProp = (dispatch: Dispatch) =>
    bindActionCreators({ getOrder: orderActions.getOrder }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProp
)(OrderEditPage);
