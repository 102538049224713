import { reducer as toastr } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { filtersReducer as filters } from '../features/Filters';
import { orderReducer as order } from '../features/Order';
import { ordersReducer as ordersList } from '../features/OrdersList';

const rootReducer = combineReducers({
    ordersList,
    filters,
    order,
    toastr
});

export default rootReducer;
