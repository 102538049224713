export enum WIDTH_FIELD_NAMES {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    SPINE = 'spine'
}

export const MAX_FIELD_WIDTH = 1.0;
export const ROUNDING_FACTOR = 100;

export const MIN_COORDINATES_VALUE = 0;
export const MAX_X_COORDINATE_VALUE = 700000;
export const MAX_Y_COORDINATE_VALUE = 1300000;

export const ALLOWED_FILE_FORMATS = ['png', 'jpg', 'jpeg', 'bmp'];
export const MAX_FILE_SIZE = 4000000;

export const MIN_IMAGE_DIMENSION = 400;
export const MAX_IMAGE_DIMENSION = 4200;
export const MIN_IMAGE_ASPECT_RATIO = 0.9;
export const MAX_IMAGE_ASPECT_RATIO = 1.1;
