export interface IProductUI {
    name: string;
    checked: boolean;
    sku: number[];
}
export interface ICategoryUI {
    name: string;
    products: IProductUI[];
}

export interface IProductCacheSelector {
    product: string;
    framing: boolean;
    material: string;
    size: string;
    [index: string]: string | boolean;
}

export interface IProductsData {
    name: string;
    products: IProductUI[];
}

export const productsData: IProductsData[] = [
    {
        name: 'Paper Maps',
        products: [
            {
                name: 'Small Folded Map',
                checked: false,
                sku: [9780319093788, 9780319093795]
            },
            {
                name: 'Large Folded Map',
                checked: false,
                sku: [
                    9780319086926,
                    9780319241691,
                    9780319086940,
                    9780319241677
                ]
            },
            {
                name: 'Large Flat Map',
                checked: false,
                sku: [
                    9780319241721,
                    9780319241783,
                    9780319241714,
                    9780319241776,
                    9780319093573,
                    9780319093702
                ]
            }
        ]
    },
    {
        name: 'Canvas & Framed',
        products: [
            {
                name: 'Small Framed Map (A3)',
                checked: false,
                sku: [
                    9780319093801,
                    9780319093818,
                    9780319093825,
                    9780319093832,
                    9780319093849,
                    9780319093856
                ]
            },
            {
                name: 'Medium Framed Map (A2)',
                checked: false,
                sku: [
                    9780319093863,
                    9780319093870,
                    9780319093887,
                    9780319093894,
                    9780319093900,
                    9780319093917
                ]
            },
            {
                name: 'Large Framed Map',
                checked: false,
                sku: [
                    9780319089699,
                    9780319089798,
                    9780319089750,
                    9780319089835,
                    9780319093580,
                    9780319093719,
                    9780319093726,
                    9780319093542,
                    9780319093733,
                    9780319093559,
                    9780319093740,
                    9780319093566
                ]
            },
            {
                name: 'Large Canvas',
                checked: false,
                sku: [
                    9780319089712,
                    9780319089811,
                    9780319089774,
                    9780319089859,
                    9780319093610,
                    9780319093757
                ]
            },
            {
                name: 'Large Canvas Framed',
                checked: false,
                sku: [
                    9780319089729,
                    9780319089828,
                    9780319089781,
                    9780319089866,
                    9780319093641,
                    9780319093764,
                    9780319093658,
                    9780319093689,
                    9780319093665,
                    9780319093696,
                    9780319093672,
                    9780319093771
                ]
            }
        ]
    }
];

export const getSKUs = (productData: IProductsData[]): number[] => {
    return productData[0].products
        .concat(productData[1].products)
        .filter(product => product.checked)
        .map(product => product.sku)
        .flat();
};
