import * as React from 'react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './Toastr.scss';

export const Toastr: React.FunctionComponent = () => {
    return (
        <ReduxToastr
            timeOut={5000}
            preventDuplicates={true}
            position='bottom-right'
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            className='toastr-control'
        />
    );
};
