import { Formik, Form } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';

import { ConfirmModal } from '../../../../Components/ConfirmModal';
import { orderActions, orderSelectors } from '../../../../features/Order';
import { Actions, IOrder } from '../../../../repository/OrderModel';
import { CustomerSection } from './Components/CustomerSection';
import { FormButtons } from './Components/FormButtons';
import { ProductSection } from './Components/ProductSection';
import { addressUpdateHandler } from '../../../../utils/addressesUpdateHandler';
import { ReasonAndCommentInputs } from '../../../../Components/ReasonAndCommentInputs';
import { CommentsSection } from './Components/CommentsSection';

import './OrderForm.scss';

export interface IOrderFormProps {
    order: IOrder;
    updateOrder: typeof orderActions.updateOrder;
}

interface IOrderFormState {
    showConfirmModal: boolean;
    values?: IOrder;
}

export class OrderForm extends React.PureComponent<
    IOrderFormProps,
    IOrderFormState
> {
    constructor(props: IOrderFormProps) {
        super(props);

        this.state = {
            showConfirmModal: false
        };
    }

    public render() {
        const { order } = this.props;
        const { showConfirmModal } = this.state;

        return (
            <div className='container order-form'>
                <Formik
                    enableReinitialize={true}
                    initialValues={order}
                    onSubmit={this.onSubmit}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validateOnMount={true}
                >
                    {this.renderForm}
                </Formik>
                {showConfirmModal && (
                    <ConfirmModal
                        confirmText='Update order'
                        isOpen={showConfirmModal}
                        header='Update?'
                        content='Are you sure you want to update this order?'
                        type='primary'
                        onClose={this.onClose}
                        onConfirm={this.onConfirm}
                        reasonAndComment={true}
                    >
                        <ReasonAndCommentInputs />
                    </ConfirmModal>
                )}
            </div>
        );
    }

    private renderForm = () => {
        const { order } = this.props;

        const disabled =
            order.status.possibleActions.indexOf(Actions.EDIT) <= -1;

        return (
            <Form className='form-wrapper'>
                <CustomerSection
                    deliveryMethod={order.deliveryMethod}
                    disabled={disabled}
                />
                {order.orderLines.map((product, index) => {
                    return (
                        <ProductSection
                            orderRef={order.referenceNumber}
                            orderLineIndex={index}
                            disabled={
                                disabled ||
                                product.status.possibleActions.indexOf(
                                    Actions.EDIT
                                ) <= -1
                            }
                            orderLine={product}
                            key={product.referenceNumber}
                        />
                    );
                })}
                <CommentsSection comments={order.comments} />
                <FormButtons disabled={disabled} />
            </Form>
        );
    };

    private onClose = () => {
        this.setState({ showConfirmModal: false, values: undefined });
    };

    private onConfirm = (comment: string, reason?: string) => {
        if (this.state.values) {
            const { comments, ...rest } = this.state.values;
            this.props.updateOrder({
                ...rest,
                comment: { text: comment, reason }
            });
        }
        this.onClose();
    };

    private showConfirmModal = () => {
        this.setState({ showConfirmModal: true });
    };

    private onSubmit = (values: IOrder) => {
        const order = addressUpdateHandler(values);
        this.setState({ values: order });
        this.showConfirmModal();
    };
}

const mapStateToProps = (state: Types.RootState) => {
    return { order: orderSelectors.getOrder(state) };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        { updateOrder: orderActions.updateOrder },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
