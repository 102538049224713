import * as React from 'react';
import classNames from 'classnames';
import './Checkbox.scss';

export interface ICheckboxProps {
    label: string;
    value: string;
    checked: boolean;
    inOrderForm: boolean;
    disabled: boolean;
    onChange: (value: string) => void;
}

export class Checkbox extends React.PureComponent<ICheckboxProps> {
    static defaultProps = {
        inOrderForm: false,
        disabled: false
    };

    public render() {
        const { checked, value, disabled, inOrderForm, label } = this.props;
        const basicClassName = 'checkbox-container';
        const className = classNames(basicClassName, {
            [`${basicClassName}--in-order-form`]: inOrderForm,
            [`${basicClassName}--disabled`]: disabled
        });

        return (
            <label className={className} id={value}>
                {label}
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={this.onChekced}
                    disabled={disabled}
                />
                <span className='checkmark' />
            </label>
        );
    }

    private onChekced = () => {
        this.props.onChange(this.props.value);
    };
}
