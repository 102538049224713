import * as React from 'react';
import './RouteSection.scss';
import { IRouteSpecification } from '../../../../../../../../../../repository/OrderModel';

export const RouteSection = ({ routeId, settings }: IRouteSpecification) => {
    const transparency = React.useMemo(
        () => (Math.round((1 - settings.lineOpacity) * 10) / 10) * 100,
        [settings.lineOpacity]
    );

    return (
        <>
            <h1 className='route-detail__title'>Route Specification</h1>
            <div className='inputs'>
                <div className='column'>
                    <div className='route-detail'>
                        id:
                        <span className='route-detail__bold'>{routeId}</span>
                    </div>
                    <div className='route-detail'>
                        colour:
                        <span className='route-detail__bold'>
                            {settings.lineColourHex}
                        </span>
                        <span
                            className='route-detail__color'
                            style={{ background: settings.lineColourHex }}
                        />
                    </div>
                    <div className='route-detail'>
                        thickness:
                        <span className='route-detail__bold'>
                            {settings.lineThickness}
                        </span>
                    </div>
                    <div className='route-detail'>
                        transparency:
                        <span className='route-detail__bold'>
                            {`${transparency}%`}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
