import { Field } from 'formik';
import * as React from 'react';
import { FormInput } from '../../../../../../../../../../Components/FormInput';
import {
    Framing,
    IRouteSpecification,
    Language,
    Material,
    ProductType,
    Scale,
    SheetSize
} from '../../../../../../../../../../repository/OrderModel';
import {
    validateXCoordinate,
    validateYCoordinate
} from '../../../../../../../../../../utils/validators';
import { RadioButton } from './Components/RadioButton';
import { RadioGroup } from './Components/RadioGroup';
import './ProductDetails.scss';
import { RouteSection } from '../RouteSection';

export interface IProductDetailsProps {
    orderLineIndex: number;
    disabled: boolean;
    type: ProductType;
    route: IRouteSpecification | undefined;
}

export const ProductDetails: React.FunctionComponent<IProductDetailsProps> = props => {
    const { orderLineIndex, disabled, type, route } = props;

    return (
        <div className='inputs'>
            <div className='column'>
                <FormInput
                    label='Quantity'
                    name={`orderLines[${orderLineIndex}].quantity`}
                    placeholder='Quantity'
                    disabled={true}
                    type='text'
                />
                <RadioGroup label='Language'>
                    <Field
                        component={RadioButton}
                        name={`orderLines[${orderLineIndex}].product.language`}
                        label={Language['EN' as keyof typeof Language]}
                        id={'EN'}
                        disabled={disabled}
                    />
                    <Field
                        component={RadioButton}
                        name={`orderLines[${orderLineIndex}].product.language`}
                        label={Language['CY' as keyof typeof Language]}
                        id={'CY'}
                        disabled={disabled}
                    />
                </RadioGroup>

                <FormInput
                    label='Scale'
                    name={`orderLines[${orderLineIndex}].product.scale`}
                    placeholder=''
                    disabled={true}
                    type='text'
                />

                <RadioGroup label='Size'>
                    <Field
                        component={RadioButton}
                        name={`orderLines[${orderLineIndex}].product.size`}
                        label={SheetSize['LARGE' as keyof typeof SheetSize]}
                        id={'LARGE'}
                        disabled={true}
                    />
                    <Field
                        component={RadioButton}
                        name={`orderLines[${orderLineIndex}].product.size`}
                        label={SheetSize['A3' as keyof typeof SheetSize]}
                        id={'A3'}
                        disabled={true}
                    />
                    <Field
                        component={RadioButton}
                        name={`orderLines[${orderLineIndex}].product.size`}
                        label={SheetSize['A2' as keyof typeof SheetSize]}
                        id={'A2'}
                        disabled={true}
                    />
                </RadioGroup>
            </div>
            <div className='column'>
                <FormInput
                    label='X co-ordinate'
                    name={`orderLines[${orderLineIndex}].product.geometry.coordinates.x`}
                    placeholder='x'
                    disabled={disabled}
                    type='text'
                    validate={validateXCoordinate}
                />
                <FormInput
                    label='Y co-ordinate'
                    name={`orderLines[${orderLineIndex}].product.geometry.coordinates.y`}
                    placeholder='y'
                    disabled={disabled}
                    type='text'
                    validate={validateYCoordinate}
                />
                {type === ('FLAT_MAP' as ProductType) && (
                    <div className='column'>
                        <RadioGroup label='Frame' className='frame-buttons'>
                            <Field
                                component={RadioButton}
                                name={`orderLines[${orderLineIndex}].product.framing`}
                                label={Framing['NONE' as keyof typeof Framing]}
                                id={'NONE'}
                                disabled={true}
                            />
                            <Field
                                component={RadioButton}
                                name={`orderLines[${orderLineIndex}].product.framing`}
                                label={
                                    Framing['LIGHT_OAK' as keyof typeof Framing]
                                }
                                id={'LIGHT_OAK'}
                                disabled={true}
                            />
                            <Field
                                component={RadioButton}
                                name={`orderLines[${orderLineIndex}].product.framing`}
                                label={Framing['BLACK' as keyof typeof Framing]}
                                id={'BLACK'}
                                disabled={true}
                            />
                        </RadioGroup>
                        <RadioGroup label='Material'>
                            <Field
                                component={RadioButton}
                                name={`orderLines[${orderLineIndex}].product.material`}
                                label={
                                    Material['PAPER' as keyof typeof Material]
                                }
                                id={'PAPER'}
                                disabled={true}
                            />
                            <Field
                                component={RadioButton}
                                name={`orderLines[${orderLineIndex}].product.material`}
                                label={
                                    Material['CANVAS' as keyof typeof Material]
                                }
                                id={'CANVAS'}
                                disabled={true}
                            />
                        </RadioGroup>
                    </div>
                )}
            </div>
            {route && (
                <div className='column'>
                    <RouteSection {...route} />
                </div>
            )}
        </div>
    );
};
