import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Types from 'Types';
import { ButtonSection } from '../../../../../../Components/ButtonsSection';
import { SectionHeader } from '../../../../../../Components/SectionHeader';
import { orderActions, orderSelectors } from '../../../../../../features/Order';
import {
    ICover,
    IOrderLine,
    ProductType
} from '../../../../../../repository/OrderModel';
import { ProductSectionContent } from './Components/ProductSectionContent';

export interface IProductSectionProps {
    orderLineIndex: number;
    disabled: boolean;
    orderLine: IOrderLine;
    covers: ICover[];
    orderRef: string;
    acceptOrderLine: typeof orderActions.acceptOrderLine;
    cancelOrderLine: typeof orderActions.cancelOrderLine;
    resupplyOrderLine: typeof orderActions.resupplyOrderLine;
    resubmitOrderLine: typeof orderActions.resubmitOrderLine;
}

interface IProductSectionState {
    expanded: boolean;
}

export class ProductSection extends React.PureComponent<
    IProductSectionProps,
    IProductSectionState
> {
    constructor(props: IProductSectionProps) {
        super(props);
        this.state = { expanded: false };
    }

    public render() {
        const { orderLineIndex, disabled, orderLine, covers } = this.props;

        return (
            <React.Fragment>
                <SectionHeader
                    expanded={this.state.expanded}
                    sectionTitle={`${
                        ProductType[
                            (orderLine.product
                                .productType as unknown) as keyof typeof ProductType
                        ]
                    } no. ${orderLine.referenceNumber}`}
                    toggleSection={this.toggleSection}
                    initialArrowDown={true}
                >
                    <ButtonSection
                        possibleActions={orderLine.status.possibleActions}
                        status={orderLine.status.status}
                        subject='Order Line'
                        resubmit={this.resubmit}
                        resupply={this.resupply}
                        cancel={this.cancel}
                        accept={this.accept}
                        downloadLink={orderLine.productOutputDownloadLink}
                    />
                </SectionHeader>
                <ProductSectionContent
                    expanded={this.state.expanded}
                    orderLineIndex={orderLineIndex}
                    orderLine={orderLine}
                    disabled={disabled}
                    type={orderLine.product.productType}
                    coverUrl={covers[orderLineIndex].url}
                    status={orderLine.status}
                    route={orderLine.product.routeSpecification}
                />
            </React.Fragment>
        );
    }

    private toggleSection = () => {
        this.setState(prevState => ({ expanded: !prevState.expanded }));
    };

    private cancel = (reason: string, comment: string) => {
        const { orderRef, orderLine, cancelOrderLine } = this.props;
        cancelOrderLine(orderRef, orderLine.referenceNumber, reason, comment);
    };

    private resubmit = (reason: string, comment: string) => {
        const { orderRef, orderLine, resubmitOrderLine } = this.props;
        resubmitOrderLine(orderRef, orderLine.referenceNumber, reason, comment);
    };

    private resupply = (reason: string, comment: string) => {
        const { orderRef, orderLine, resupplyOrderLine } = this.props;
        resupplyOrderLine(orderRef, orderLine.referenceNumber, reason, comment);
    };

    private accept = () => {
        const { orderRef, orderLine, acceptOrderLine } = this.props;
        acceptOrderLine(orderRef, orderLine.referenceNumber);
    };
}

const mapStateToProps = (state: Types.RootState) => {
    const covers = orderSelectors.getCovers(state);
    return { covers };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            cancelOrderLine: orderActions.cancelOrderLine,
            resupplyOrderLine: orderActions.resupplyOrderLine,
            resubmitOrderLine: orderActions.resubmitOrderLine,
            acceptOrderLine: orderActions.acceptOrderLine
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSection);
