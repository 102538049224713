import * as React from 'react';
import ReactSelect from 'react-select';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { StylesConfig } from 'react-select/lib/styles';
import { ValueType } from 'react-select/lib/types';
import { DropdownArrow } from '../../../../../../../../Components/DropdownArrow';

export interface IOptionType {
    value: number;
    label: string;
}

export interface ISelectProps {
    options: IOptionType[];
    value: IOptionType;
    width: number;
    onChange: (value: ValueType<IOptionType>) => void;
}

export class Select extends React.PureComponent<ISelectProps> {
    private controlStyles: StylesConfig = {
        control: (
            styles: React.CSSProperties,
            { isFocused }: { isFocused: boolean }
        ) => {
            return {
                ...styles,
                width: this.props.width,
                height: 40,
                border: '1px solid #757575',
                borderRadius: 3,
                paddingLeft: 16,
                cursor: 'pointer',
                boxShadow: isFocused
                    ? '0 0 3px 2px rgba(0, 113, 188, 0.5)'
                    : 'none'
            };
        },
        indicatorSeparator: () => ({
            display: 'none'
        }),
        menu: original => ({ ...original, maxWidth: this.props.width }),
        option: (styles: React.CSSProperties, { isSelected, isFocused }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? '#453c90'
                    : isFocused
                    ? '#f1efff'
                    : '#fff'
            };
        },
        valueContainer: original => ({ ...original, padding: '0' })
    };

    public render() {
        const { options, value, onChange } = this.props;
        return (
            <ReactSelect
                options={options}
                styles={this.controlStyles}
                value={value}
                components={{
                    DropdownIndicator: this.renderDropdownIndicator
                }}
                blurInputOnSelect={true}
                id='page-size-select'
                onChange={onChange}
                isClearable={false}
                isSearchable={false}
                backspaceRemovesValue={false}
            />
        );
    }

    private renderDropdownIndicator = (
        dropdownIndicatorProps: IndicatorProps<any>
    ) => {
        return (
            <DropdownArrow
                expanded={dropdownIndicatorProps.isFocused}
                initialArrowDown={true}
            />
        );
    };
}
