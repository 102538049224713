export const GET_ORDER = 'order/GET_ORDER';
export const SET_ORDER = 'order/SET_ORDER';
export const CANCEL_ORDER = 'order/CANCEL_ORDER';
export const RESUPPLY_ORDER = 'order/RESUPPLY_ORDER';
export const RESUBMIT_ORDER = 'order/RESUBMIT_ORDER';
export const PAUSE_ORDER = 'order/PAUSE_ORDER';
export const UNPAUSE_ORDER = 'order/UNPAUSE_ORDER';
export const CANCEL_ORDER_LINE = 'order/CANCEL_ORDER_LINE';
export const ACCEPT_ORDER = 'order/ACCEPT_ORDER';
export const RESUPPLY_ORDER_LINE = 'order/RESUPPLY_ORDER_LINE';
export const RESUBMIT_ORDER_LINE = 'order/RESUBMIT_ORDER_LINE';
export const ACCEPT_ORDER_LINE = 'order/ACCEPT_ORDER_LINE';
export const UPDATE_ORDER = 'order/UPDATE_ORDER';
export const SET_COVER = 'order/SET_COVER';
