import * as React from 'react';
import { Checkbox } from '../../../../../../../../Components/Checkbox';

export interface IRouteFiltersProps {
    hasRoute: boolean;
    onChange: () => void;
}

export const RouteFilters = ({ hasRoute, onChange }: IRouteFiltersProps) => {
    return (
        <>
            <Checkbox
                label='Show only orders with route'
                checked={hasRoute}
                onChange={onChange}
                value={''}
            />
        </>
    );
};
