import * as React from 'react';
import { Provider } from 'react-redux';
import store from '../../store';
import { App } from './Components/App';

export const Root: React.FunctionComponent = () => (
    <Provider store={store}>
        <App />
    </Provider>
);
