import moment from 'moment';
import { ActionType } from 'typesafe-actions';
import { Status, DeliveryMethod } from '../../repository/OrderModel';
import * as filtersActions from './actions';
import {
    CLEAR_ALL,
    SET_IS_PAUSED,
    SET_SEARCH,
    SET_STATUS,
    SET_SUBMITTED_FROM,
    SET_SUBMITTED_TO,
    SET_DELIVERY_METHOD,
    SET_SKUS,
    SET_HAS_ROUTE
} from './constants';

export type FiltersState = {
    readonly statuses: Status[];
    readonly submittedFrom?: moment.Moment;
    readonly submittedTo?: moment.Moment;
    readonly keywords: string[];
    readonly isPaused: boolean;
    readonly hasRoute: boolean;
    readonly deliveryMethods: DeliveryMethod[];
    readonly productSkus: number[];
};

export type FiltersActions = ActionType<typeof filtersActions>;

const initialState: FiltersState = {
    statuses: [],
    submittedFrom: undefined,
    submittedTo: undefined,
    keywords: [],
    isPaused: false,
    hasRoute: false,
    deliveryMethods: [],
    productSkus: []
};

const filtersReducer = (
    state: FiltersState = initialState,
    action: FiltersActions
) => {
    let index = -1;
    switch (action.type) {
        case CLEAR_ALL:
            return initialState;
        case SET_STATUS:
            const statuses = [...state.statuses];
            index = statuses.indexOf(action.payload);
            if (index >= 0) {
                statuses.splice(index, 1);
            } else {
                statuses.push(action.payload);
            }
            return { ...state, statuses };
        case SET_SUBMITTED_FROM:
            return { ...state, submittedFrom: action.payload };
        case SET_SUBMITTED_TO:
            return { ...state, submittedTo: action.payload };
        case SET_SEARCH:
            return { ...state, keywords: action.payload.split(' ') };
        case SET_IS_PAUSED:
            return { ...state, isPaused: action.payload };
        case SET_HAS_ROUTE:
            return { ...state, hasRoute: action.payload };
        case SET_DELIVERY_METHOD:
            const deliveryMethods = [...state.deliveryMethods];
            index = deliveryMethods.indexOf(action.payload);
            if (index >= 0) {
                deliveryMethods.splice(index, 1);
            } else {
                deliveryMethods.push(action.payload);
            }

            return { ...state, deliveryMethods };
        case SET_SKUS:
            return { ...state, productSkus: action.payload };
        default:
            return state;
    }
};

export default filtersReducer;
