import * as React from 'react';
import './OrderNoSection.scss';

export interface IOrderNoSectionProps {
    paused: boolean;
    referenceNumber: string;
}

export const OrderNoSection: React.FunctionComponent<
    IOrderNoSectionProps
> = props => {
    const { referenceNumber, paused } = props;
    return (
        <div className='order-no-section'>
            <div className='order-ref'>
                <span>Order no. </span>
                <span>{referenceNumber}</span>
                {paused && <span> - ON HOLD</span>}
            </div>
        </div>
    );
};
