import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { DropdownArrow } from '../../../../../../Components/DropdownArrow';
import './FiltersSection.scss';

export interface IFiltersSectionProps {
    name: string;
    id: string;
    children: React.ReactNode;
}

interface IFiltersSectionState {
    expanded: boolean;
}

export class FiltersSection extends React.PureComponent<
    IFiltersSectionProps,
    IFiltersSectionState
> {
    constructor(props: IFiltersSectionProps) {
        super(props);
        this.state = {
            expanded: true
        };
    }

    public render() {
        return (
            <>
                <div
                    className='filters-section-header'
                    onClick={this.toggleSection}
                    id={this.props.id}
                >
                    <span>{this.props.name}</span>
                    <DropdownArrow expanded={this.state.expanded} />
                </div>
                <CSSTransition
                    classNames='filters-section'
                    timeout={500}
                    in={this.state.expanded}
                    unmountOnExit={false}
                >
                    <div className='filters-section'>{this.props.children}</div>
                </CSSTransition>
            </>
        );
    }

    private toggleSection = () => {
        this.setState(prevState => {
            return { expanded: !prevState.expanded };
        });
    };
}
