import axios from 'axios';
import authenticationService from './authenticationService';

class RefreshFlag {
    public gettingToken: Promise<string | null> | null = null;
}

export const refreshFlag = new RefreshFlag();

const setInterceptors = () => {
    axios.interceptors.request.use(async config => {
        // if not getting token
        if (!refreshFlag.gettingToken) {
            // check if token is valid and get new one if required
            refreshFlag.gettingToken = authenticationService.getToken();
        }

        // wait till getting token is done
        const token: string = (await refreshFlag.gettingToken) || '';
        if (!token) {
            // tslint:disable-next-line:no-empty
            let cancel = () => {};
            config.cancelToken = new axios.CancelToken(c => {
                cancel = c;
            });
            cancel();
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['Cache-Control'] = 'no-cache';
        refreshFlag.gettingToken = null;

        return config;
    });
};

export default setInterceptors;
