import React from 'react';
import { FormInput } from '../FormInput';

import styles from './CommentInput.module.scss';

const CommentInput = () => {
    return (
        <div className={styles['comment-input']}>
            <FormInput
                label='Comment'
                name='text'
                placeholder='Put your comment'
                type='text'
                disabled={false}
            />
        </div>
    );
};

export default CommentInput;
