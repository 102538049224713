import { Status } from '../repository/OrderModel';

export const statusClassNameFormatter = (statusValue: Status) => {
    switch (Status[(statusValue as unknown) as keyof typeof Status]) {
        case Status.ERROR:
            return 'error';
        case Status.REVIEW:
            return 'review';
        case Status.COOL_OFF:
            return 'cool-off';
        case Status.IMG_GEN_READY:
        case Status.IMAGE_GENERATION:
        case Status.FULFILLMENT_READY:
            return 'automated-steps';
        case Status.FULFILLMENT:
            return 'fulfillment';
        case Status.ARCHIVED:
        case Status.CANCELLED:
        case Status.ANONYMISED:
            return 'done';
    }
};
