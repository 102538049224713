import * as React from 'react';
import { Actions, Status, Reason } from '../../repository/OrderModel';
import { statusClassNameFormatter } from '../../utils/statusFormatter';
import { ConfirmModal } from '../ConfirmModal';
import { CommentInput } from '../CommentInput';
import { ReasonAndCommentInputs } from '../ReasonAndCommentInputs';

import './ButtonSection.scss';

export interface IButtonSectionProps {
    possibleActions: Actions[];
    status: Status;
    subject: string;
    accept: () => void;
    cancel: (reason: string, comment: string) => void;
    resubmit: (reason: string, comment: string) => void;
    resupply: (reason: string, comment: string) => void;
    pause?: (reason: string, comment: string) => void;
    unpause?: () => void;
    downloadLink?: string;
}

interface IButtonSectionState {
    showCancelModal: boolean;
    showResubmitModal: boolean;
    showResupplyModal: boolean;
    showAcceptModal: boolean;
    showPauseModal: boolean;
    showUnpauseModal: boolean;
}

export class ButtonSection extends React.PureComponent<
    IButtonSectionProps,
    IButtonSectionState
> {
    constructor(props: IButtonSectionProps) {
        super(props);
        this.state = {
            showCancelModal: false,
            showResubmitModal: false,
            showResupplyModal: false,
            showAcceptModal: false,
            showPauseModal: false,
            showUnpauseModal: false
        };
    }

    public render() {
        const { possibleActions, status, subject } = this.props;
        return (
            <div className='buttons-section'>
                <div className='buttons'>
                    {this.props.downloadLink && (
                        <div className='download' onClick={this.downloadFile}>
                            <i className='mdi mdi-download' />
                            <span>Download PDF</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.PAUSE) >= 0 && (
                        <div className='pause' onClick={this.showPauseModal}>
                            <i className='mdi mdi-pause-circle-outline' />
                            <span>Hold</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.UNPAUSE) >= 0 && (
                        <div
                            className='unpause'
                            onClick={this.showUnpauseModal}
                        >
                            <i className='mdi mdi-play-circle-outline' />
                            <span>Release</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.RESUBMIT) >= 0 && (
                        <div
                            className='resubmit'
                            onClick={this.showResubmitModal}
                        >
                            <i className='mdi mdi-refresh' />
                            <span>Resubmit</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.RESUPPLY) >= 0 && (
                        <div
                            className='resubmit'
                            onClick={this.showResupplyModal}
                        >
                            <i className='mdi mdi-reply' />
                            <span>Resupply</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.CANCEL) >= 0 && (
                        <div className='cancel' onClick={this.showCancelModal}>
                            <i className='mdi mdi-close-circle-outline' />
                            <span>Cancel</span>
                        </div>
                    )}
                    {possibleActions.indexOf(Actions.ACCEPT) >= 0 && (
                        <div className='accept' onClick={this.showAcceptModal}>
                            <i className='mdi mdi-checkbox-marked-circle-outline' />
                            <span>Accept</span>
                        </div>
                    )}
                </div>
                <div className='status'>
                    Status:{' '}
                    <span className={statusClassNameFormatter(status)}>
                        {Status[(status as unknown) as keyof typeof Status]}
                    </span>
                </div>
                {this.state.showCancelModal && (
                    <ConfirmModal
                        content={`Are you sure you want to cancel selected ${subject}?`}
                        header='Cancel?'
                        type='danger'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.cancelConfirmed}
                        confirmText={`Cancel ${subject}`}
                    >
                        <CommentInput />
                    </ConfirmModal>
                )}
                {this.state.showResubmitModal && (
                    <ConfirmModal
                        content={`Are you sure you want to resubmit selected ${subject}?`}
                        header='Resubmit?'
                        type='primary'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.resubmitConfirmed}
                        confirmText={`Resubmit ${subject}`}
                        reasonAndComment={true}
                    >
                        <ReasonAndCommentInputs />
                    </ConfirmModal>
                )}
                {this.state.showResupplyModal && (
                    <ConfirmModal
                        content={`Are you sure you want to resupply selected ${subject}?`}
                        header='Resupply?'
                        type='primary'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.resupplyConfirmed}
                        confirmText={`Resupply ${subject}`}
                        reasonAndComment={true}
                    >
                        <ReasonAndCommentInputs />
                    </ConfirmModal>
                )}
                {this.state.showAcceptModal && (
                    <ConfirmModal
                        content={`Are you sure you want to accept selected ${subject}?`}
                        header='Accept?'
                        type='primary'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.acceptConfirmed}
                        confirmText={`Accept ${subject}`}
                    />
                )}
                {this.state.showPauseModal && (
                    <ConfirmModal
                        content={`Are you sure you want to put on hold selected ${subject}?`}
                        header='Put on hold?'
                        type='primary'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.pauseConfirmed}
                        confirmText={`Hold ${subject}`}
                    >
                        <CommentInput />
                    </ConfirmModal>
                )}
                {this.state.showUnpauseModal && (
                    <ConfirmModal
                        content={`Are you sure you want to release selected ${subject}?`}
                        header='Release?'
                        type='primary'
                        isOpen={true}
                        onClose={this.onClose}
                        onConfirm={this.unpauseConfirmed}
                        confirmText={`Release ${subject}`}
                    />
                )}
            </div>
        );
    }

    private onClose = () => {
        this.setState({
            showCancelModal: false,
            showResubmitModal: false,
            showResupplyModal: false,
            showAcceptModal: false,
            showPauseModal: false,
            showUnpauseModal: false
        });
    };

    private downloadFile = () => {
        if (this.props.downloadLink) {
            window.location.href = this.props.downloadLink;
        }
    };

    private showResubmitModal = () => {
        this.setState({ showResubmitModal: true });
    };

    private showResupplyModal = () => {
        this.setState({ showResupplyModal: true });
    };

    private resubmitConfirmed = (comment: string, reason?: string) => {
        this.onClose();
        this.props.resubmit(reason || '', comment);
    };

    private resupplyConfirmed = (comment: string, reason?: string) => {
        this.onClose();
        this.props.resupply(reason || '', comment);
    };

    private showCancelModal = () => {
        this.setState({ showCancelModal: true });
    };

    private cancelConfirmed = (comment: string) => {
        this.onClose();
        this.props.cancel(Reason[Reason.CANCELLED], comment);
    };

    private showAcceptModal = () => {
        this.setState({ showAcceptModal: true });
    };

    private acceptConfirmed = () => {
        this.onClose();
        this.props.accept();
    };

    private showPauseModal = () => {
        this.setState({ showPauseModal: true });
    };

    private pauseConfirmed = (comment: string) => {
        this.onClose();
        if (this.props.pause) {
            this.props.pause(Reason[Reason.ON_HOLD] as string, comment);
        }
    };

    private showUnpauseModal = () => {
        this.setState({ showUnpauseModal: true });
    };

    private unpauseConfirmed = () => {
        this.onClose();
        if (this.props.unpause) {
            this.props.unpause();
        }
    };
}
