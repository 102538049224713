import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { OrderEditPage } from '../../../../../../Pages/OrderEditPage';
import { OrdersPage } from '../../../../../../Pages/OrdersPage';
import { PageNotFound } from '../../../../../../Pages/PageNotFound/PageNotFound';
import { AppFooter } from './Components/AppFooter/AppFooter';
import { AppHeader } from './Components/AppHeader/AppHeader';
import './PrivatePageWrapper.scss';

export const PrivatePageWrapper: React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <AppHeader />
            <div className='content'>
                <Switch>
                    <Route exact={true} path='/orders' component={OrdersPage} />
                    <Route
                        exact={true}
                        path='/orders/:id'
                        component={OrderEditPage}
                    />
                    <Redirect exact={true} from='/' to='/orders' />
                    <Route component={PageNotFound} />
                </Switch>
            </div>
            <AppFooter />
        </React.Fragment>
    );
};
