import { FieldProps } from 'formik';
import * as React from 'react';
import './RadioButton.scss';

export interface IRadioButtonProps {
    field: FieldProps['field'];
    label: string;
    disabled: boolean;
    id: string;
}

export const RadioButton: React.FunctionComponent<
    IRadioButtonProps
> = props => {
    const { label, disabled, id, field } = props;
    const { value, name, onChange, onBlur } = field;
    return (
        <label
            className={'radio-container' + (disabled ? ' disabled' : '')}
            id={id}
        >
            {label}
            <input
                name={name}
                type='radio'
                checked={id === value}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                value={id}
            />
            <span className={'radio-checkmark'} />
        </label>
    );
};
