import React, { useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { Formik, FormikErrors } from 'formik';
import { Button } from '../Button';
import { Comment, Reason } from '../../repository/OrderModel';
import {
    validateRequiredComment,
    validateComment,
    required
} from '../../utils/validators';

import './ConfirmModal.scss';

export interface IConfirmModalProps {
    isOpen: boolean;
    type: 'primary' | 'danger';
    header: string;
    content: string;
    confirmText: string;
    reasonAndComment?: boolean;
    onClose: () => void;
    onConfirm: (comment: string, reason?: string) => void;
}

export const ConfirmModal: React.FunctionComponent<IConfirmModalProps> = props => {
    const {
        isOpen,
        onClose,
        type,
        header,
        content,
        confirmText,
        onConfirm,
        reasonAndComment,
        children
    } = props;

    const onSubmit = useCallback(
        (values: Comment) => onConfirm(values.text, values.reason),
        [onConfirm]
    );

    const initialValues: Comment = useMemo(
        () => (reasonAndComment ? { reason: '', text: '' } : { text: '' }),
        [reasonAndComment]
    );

    const validate = useCallback(
        ({ reason, text }: Comment) => {
            if (!children) {
                return {};
            }

            const errors: FormikErrors<Comment> = {};
            const commentError =
                !reason || reason === Reason.OTHER
                    ? validateRequiredComment(text)
                    : validateComment(text);
            const reasonError = reasonAndComment ? required(reason) : undefined;

            if (commentError) {
                errors.text = commentError;
            }

            if (reasonError) {
                errors.reason = reasonError;
            }

            return errors;
        },
        [reasonAndComment, children]
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={'modal ' + type}
            overlayClassName='modal-background'
            ariaHideApp={false}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={false}
                validate={validate}
            >
                {formikProps => {
                    return (
                        <form onSubmit={formikProps.handleSubmit}>
                            <div className='modal-content'>
                                <span
                                    className='close-button'
                                    onClick={onClose}
                                >
                                    &#10005;
                                </span>
                                <h2>{header}</h2>
                                <span>{content}</span>
                                {children}
                            </div>
                            <div className='buttons'>
                                <Button
                                    id='cancel'
                                    buttonType='secondary'
                                    onClick={onClose}
                                    type='button'
                                >
                                    Exit
                                </Button>
                                <Button
                                    id='confirm'
                                    buttonType={type}
                                    type='submit'
                                >
                                    {confirmText}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};
