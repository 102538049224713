import * as React from 'react';
import { Router } from 'react-router-dom';
import authService, {
    AuthService
} from '../../../../utils/authenticationService';
import history from '../../../../utils/history';
import './App.scss';
import { PrivatePageWrapper } from './Components/PrivatePageWrapper';
import { SessionPage } from './Components/SessionPage/SessionPage';
import { Toastr } from './Components/Toastr';
import 'reset-css';
import 'normalize.css';

class App extends React.Component {
    constructor(props: any) {
        super(props);
        // tslint:disable-next-line:no-unused-expression
        new AuthService(this.loginSucceed);
    }

    public componentDidMount() {
        if (!authService.getUser() && !authService.loginInProgress()) {
            authService.login();
        }
    }

    public render() {
        return (
            <div className='App'>
                {authService.getUser() ? (
                    <Router history={history}>
                        <PrivatePageWrapper />
                    </Router>
                ) : (
                    <SessionPage />
                )}
                <Toastr />
            </div>
        );
    }

    private loginSucceed = () => {
        this.forceUpdate();
    };
}

export default App;
