import { combineEpics, Epic, ofType } from 'redux-observable';
import { debounceTime, mapTo } from 'rxjs/operators';
import Types from 'Types';
import { ordersListActions } from '../OrdersList';
import {
    CLEAR_ALL,
    SEARCH,
    SET_IS_PAUSED,
    SET_STATUS,
    SET_SUBMITTED_FROM,
    SET_SUBMITTED_TO,
    SET_DELIVERY_METHOD,
    SET_SKUS,
    SET_HAS_ROUTE
} from './constants';

export const setFiltersEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ =>
    action$.pipe(
        ofType(
            SET_STATUS,
            SET_SUBMITTED_FROM,
            SET_SUBMITTED_TO,
            SET_IS_PAUSED,
            SET_HAS_ROUTE,
            SET_DELIVERY_METHOD,
            SET_SKUS
        ),
        debounceTime(750),
        mapTo(ordersListActions.getList())
    );

export const clearEpic: Epic<Types.RootAction, Types.RootAction> = action$ =>
    action$.pipe(ofType(CLEAR_ALL), mapTo(ordersListActions.getList()));

export const setSearchEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ => action$.pipe(ofType(SEARCH), mapTo(ordersListActions.getList()));

export default combineEpics(setFiltersEpic, setSearchEpic, clearEpic);
