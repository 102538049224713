import Types from 'Types';

export const getLoadingOrder = (state: Types.RootState) =>
    state.order.loadingOrder;

export const getOrder = (state: Types.RootState) => state.order.order;

export const getOrderStatus = (state: Types.RootState) =>
    state.order.order.status;

export const getOrderRefNumber = (state: Types.RootState) =>
    state.order.order.referenceNumber;

export const getCovers = (state: Types.RootState) => state.order.covers;
