import * as React from 'react';
import './PageInfo.scss';

export interface IPageInfoProps {
    pageNumber: number;
    pagesConut: number;
    pageStart: number;
    pageEnd: number;
    totalItems: number;
    showingButtons: boolean;
}

export const PageInfo: React.FunctionComponent<IPageInfoProps> = props => {
    const {
        pageNumber,
        pagesConut,
        pageStart,
        pageEnd,
        totalItems,
        showingButtons
    } = props;
    return (
        <div className={'page-info' + (!showingButtons ? ' first-row' : '')}>
            <span>{`Page ${pageNumber + 1} of ${pagesConut}`}</span>
            <br />
            <span>{`Showing ${pageStart} to ${pageEnd} orders of ${totalItems}`}</span>
        </div>
    );
};
