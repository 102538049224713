import { ActionType } from 'typesafe-actions';
import { IOrderBasicInfo } from '../../repository/OrderModel';
import * as ordersActions from './actions';
import { CHANGE_PAGE_SIZE, GET_LIST, GET_LIST_DONE } from './constants';
import { IPageInfo } from './model';

export type OrdersListState = {
    readonly loadingOrders: boolean;
    readonly orders: IOrderBasicInfo[];
    readonly pageInfo: IPageInfo;
    readonly selectedPageSize: number;
};

export type OrdersListActions = ActionType<typeof ordersActions>;

const initalState: OrdersListState = {
    loadingOrders: false,
    orders: [],
    pageInfo: {
        hasNext: false,
        hasPrevious: false,
        pageEnd: 0,
        pageNumber: 0,
        pageStart: 0,
        pagesConut: 0,
        totalItems: 0
    },
    selectedPageSize: 30
};

const ordersListReducer = (
    state: OrdersListState = initalState,
    action: OrdersListActions
) => {
    switch (action.type) {
        case GET_LIST:
            return {
                ...initalState,
                loadingOrders: true,
                selectedPageSize: state.selectedPageSize
            };
        case GET_LIST_DONE:
            const {
                content,
                first,
                last,
                pageable,
                totalPages,
                totalElements
            } = action.payload;
            return {
                ...state,
                orders: content ? content : [],
                pageInfo: {
                    pageNumber: pageable.pageNumber,
                    pagesConut: totalPages,
                    pageStart: pageable.offset + 1,
                    pageEnd:
                        pageable.offset + pageable.pageSize > totalElements
                            ? totalElements
                            : pageable.offset + pageable.pageSize,
                    totalItems: totalElements,
                    hasPrevious: !first,
                    hasNext: !last
                },
                loadingOrders: false
            };
        case CHANGE_PAGE_SIZE:
            return { ...state, selectedPageSize: action.payload };
        default:
            return state;
    }
};

export default ordersListReducer;
