import * as React from 'react';
import { Spinner } from './Components/Spinner';
import './FullPageSpinner.scss';

export const FullPageSpinner: React.FunctionComponent = () => {
    return (
        <div className='full-page-spinner'>
            <Spinner />
        </div>
    );
};
