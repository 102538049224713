import moment from 'moment';
import {
    DeliveryMethod,
    GeometryType,
    IOrder,
    Language,
    ProductType,
    Scale,
    Status
} from '../repository/OrderModel';

export const normalizeOrder: (order: IOrder) => IOrder = (order: IOrder) => {
    return {
        referenceNumber: order.referenceNumber || '',
        reason: '',
        billingAddress: {
            firstName: order.billingAddress.firstName || '',
            lastName: order.billingAddress.lastName || '',
            city: order.billingAddress.city || '',
            country: order.billingAddress.country || '',
            county: order.billingAddress.county || '',
            email: order.billingAddress.email || '',
            line1: order.billingAddress.line1 || '',
            line2: order.billingAddress.line2 || '',
            phoneNumber: order.billingAddress.phoneNumber || '',
            postCode: order.billingAddress.postCode || ''
        },
        deliveryAddress: {
            firstName: order.deliveryAddress.firstName || '',
            lastName: order.deliveryAddress.lastName || '',
            city: order.deliveryAddress.city || '',
            country: order.deliveryAddress.country || '',
            county: order.deliveryAddress.county || '',
            email: order.deliveryAddress.email || '',
            line1: order.deliveryAddress.line1 || '',
            line2: order.deliveryAddress.line2 || '',
            phoneNumber: order.deliveryAddress.phoneNumber || '',
            postCode: order.deliveryAddress.postCode || ''
        },
        deliveryAddressSameAsBilling: order.deliveryAddressSameAsBilling,
        deliveryMethod: order.deliveryMethod || DeliveryMethod.ROYAL_MAIL,
        status: {
            status: order.status.status || Status.ERROR,
            isPaused: order.status.isPaused || false,
            statusDescription: order.status.statusDescription || '',
            possibleActions: order.status.possibleActions || []
        },
        submittedAt: order.submittedAt || moment().toISOString(),
        orderLines: order.orderLines
            ? order.orderLines.map(orderLine => {
                  return {
                      product: {
                          coverUrl: orderLine.product.coverUrl,
                          geometry: {
                              type:
                                  orderLine.product.geometry.type ||
                                  GeometryType.Point,
                              coordinates: {
                                  x:
                                      orderLine.product.geometry.coordinates
                                          .x || 0,
                                  y:
                                      orderLine.product.geometry.coordinates
                                          .y || 0
                              }
                          },
                          language: orderLine.product.language || Language.EN,
                          productType:
                              orderLine.product.productType ||
                              ProductType.FOLDED_MAP,
                          scale: orderLine.product.scale || Scale.OS_LANDRANGER,
                          subtitle: orderLine.product.subtitle
                              ? orderLine.product.subtitle
                              : [],
                          title: orderLine.product.title
                              ? orderLine.product.title
                              : [],
                          spineText: orderLine.product.spineText || '',
                          material: orderLine.product.material,
                          framing: orderLine.product.framing,
                          size: orderLine.product.size,
                          routeSpecification:
                              orderLine.product.routeSpecification
                      },

                      quantity: orderLine.quantity || 1,
                      referenceNumber: orderLine.referenceNumber || '',
                      productOutputDownloadLink:
                          orderLine.productOutputDownloadLink,
                      status: {
                          status: orderLine.status.status || Status.ERROR,
                          statusDescription:
                              orderLine.status.statusDescription || '',
                          possibleActions:
                              orderLine.status.possibleActions || [],
                          errorMessage: orderLine.status.errorMessage || '',
                          supplierStatus: orderLine.status.supplierStatus || '',
                          validationWarnings:
                              orderLine.status.validationWarnings || []
                      }
                  };
              })
            : [],
        comments: order.comments
            ? order.comments.map(comment => ({
                  time: comment.time || new Date().toISOString(),
                  action: comment.action || '',
                  author: comment.author || '',
                  text: comment.text || '',
                  reason: comment.reason || ''
              }))
            : []
    };
};
