import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import { filtersActions, filtersSelectors } from '../../../../features/Filters';
import { Status, DeliveryMethod } from '../../../../repository/OrderModel';
import { FiltersSection } from './Components/FiltersSection';
import { DateFilters } from './Components/FiltersSection/Components/DateFilters';
import { PauseFilters } from './Components/FiltersSection/Components/PauseFilters/PauseFilters';
import { StatusFilters } from './Components/FiltersSection/Components/StatusFilters';
import './Filters.scss';
import { DeliveryFilters } from './Components/FiltersSection/Components/DeliveryFilters';
import { ProductFilters } from './Components/FiltersSection/Components/ProductFilters';
import { RouteFilters } from './Components/FiltersSection/Components/RouteFilters/RouteFilters';

export interface IFiltersProps {
    statuses: Status[];
    keywords: string[];
    isPaused: boolean;
    hasRoute: boolean;
    deliveryMethods: DeliveryMethod[];
    skus: number[];
    clearAll: typeof filtersActions.clearAll;
    setStatus: typeof filtersActions.setStatus;
    setSubmittedFrom: typeof filtersActions.setSubmittedFrom;
    setSubmittedTo: typeof filtersActions.setSubmittedTo;
    setIsPaused: typeof filtersActions.setIsPaused;
    setHasRoute: typeof filtersActions.setHasRoute;
    submittedFrom?: moment.Moment;
    submittedTo?: moment.Moment;
}

export class Filters extends React.PureComponent<IFiltersProps> {
    public render() {
        const {
            statuses,
            submittedFrom,
            submittedTo,
            keywords,
            isPaused,
            hasRoute,
            deliveryMethods,
            clearAll,
            setStatus,
            setSubmittedFrom,
            skus
        } = this.props;
        return (
            <div className='filters'>
                <div className='filters-header'>
                    <h3>Filters</h3>
                    {(statuses.length > 0 ||
                        submittedFrom ||
                        submittedTo ||
                        keywords.length > 0 ||
                        deliveryMethods.length > 0 ||
                        skus.length > 0 ||
                        isPaused ||
                        hasRoute) && (
                        <span
                            className='clear-all'
                            onClick={clearAll}
                            id='clear-all-filters'
                        >
                            Clear all
                        </span>
                    )}
                </div>
                <FiltersSection
                    name='Submitted date'
                    id='status-submitted-date'
                >
                    <DateFilters
                        submittedFrom={submittedFrom}
                        submittedTo={submittedTo}
                        submittedFromChanged={setSubmittedFrom}
                        submittedToChanged={this.setToDate}
                        placeholder='dd/mm/yyyy'
                    />
                </FiltersSection>
                <FiltersSection name='Status' id='status-filters'>
                    <StatusFilters
                        selectedStatuses={statuses}
                        setStatus={setStatus}
                    />
                </FiltersSection>
                <FiltersSection name='On hold' id='on-hold-filters'>
                    <PauseFilters
                        isPaused={isPaused}
                        onChange={this.isPausedOnChange}
                    />
                </FiltersSection>
                <FiltersSection name='Delivery' id='delivery-filters'>
                    <DeliveryFilters />
                </FiltersSection>
                <FiltersSection name='Product' id='product-filters'>
                    <ProductFilters />
                </FiltersSection>
                <FiltersSection name='Route' id='route-filters'>
                    <RouteFilters
                        hasRoute={hasRoute}
                        onChange={this.hasRouteOnChange}
                    />
                </FiltersSection>
            </div>
        );
    }

    private setToDate = (date: moment.Moment) => {
        const { setSubmittedTo } = this.props;
        if (date) {
            setSubmittedTo(
                date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            );
        } else {
            setSubmittedTo(date);
        }
    };

    private isPausedOnChange = () => {
        const { isPaused, setIsPaused } = this.props;

        setIsPaused(!isPaused);
    };

    private hasRouteOnChange = () => {
        const { hasRoute, setHasRoute } = this.props;

        setHasRoute(!hasRoute);
    };
}

const mapStateToProps = (state: Types.RootState) => {
    const statuses = filtersSelectors.getStatuses(state);
    const submittedFrom = filtersSelectors.getSubmittedFrom(state);
    const submittedTo = filtersSelectors.getSubmittedTo(state);
    const keywords = filtersSelectors.getKeywords(state);
    const isPaused = filtersSelectors.getIsPaused(state);
    const hasRoute = filtersSelectors.getHasRoute(state);
    const deliveryMethods = filtersSelectors.getDeliveryMethods(state);
    const skus = filtersSelectors.getSKUs(state);

    return {
        statuses,
        submittedFrom,
        submittedTo,
        keywords,
        isPaused,
        hasRoute,
        deliveryMethods,
        skus
    };
};

const mapDispatchtoProps = (dispatch: Dispatch<Types.RootAction>) => {
    return bindActionCreators(
        {
            clearAll: filtersActions.clearAll,
            setStatus: filtersActions.setStatus,
            setSubmittedFrom: filtersActions.setSubmittedFrom,
            setSubmittedTo: filtersActions.setSubmittedTo,
            setIsPaused: filtersActions.setIsPaused,
            setHasRoute: filtersActions.setHasRoute
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchtoProps)(Filters);
