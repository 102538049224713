import * as React from 'react';
import './Spinner.scss';

export const Spinner: React.FunctionComponent = () => {
    return (
        <div className='spinner'>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};
