import * as Msal from 'msal';
import { toastr } from 'react-redux-toastr';
import { CLIENT_ID, REDIRECT_URL } from './constants';

interface IAppConfig {
    clientId: string;
    scopes: string[];
}

export class AuthService {
    public static instance: AuthService;
    private appConfig: IAppConfig;
    private app: Msal.UserAgentApplication;

    constructor(loginDone: () => void) {
        this.appConfig = {
            clientId: CLIENT_ID || '',
            scopes: ['user.read']
        };

        this.app = new Msal.UserAgentApplication(
            this.appConfig.clientId,
            null,
            loginDone,
            {
                cacheLocation: 'localStorage',
                redirectUri: REDIRECT_URL,
                postLogoutRedirectUri: REDIRECT_URL
            }
        );
    }

    public login = () => this.app.loginRedirect(this.appConfig.scopes);

    public getUser = () => this.app.getUser();

    public logout = () => {
        localStorage.clear();
        this.app.logout();
    };

    public loginInProgress = () => this.app.loginInProgress();

    public getToken = async () => {
        const tokenValid = await this.app
            .acquireTokenSilent([this.appConfig.clientId])
            .then(() => {
                return true;
            })
            .catch(async () => {
                toastr.error(
                    '',
                    'Your session has expired, please login again!'
                );
                setTimeout(() => {
                    this.logout();
                }, 2500);
                return false;
            });
        return tokenValid ? localStorage.getItem('msal.idtoken') : '';
    };
}

// tslint:disable-next-line:no-empty
export default (AuthService.instance
    ? AuthService.instance
    : // tslint:disable-next-line:no-empty
      new AuthService(() => {}));
