import { action } from 'typesafe-actions';
import { IOrdersListPage } from '../../repository/OrderModel';
import {
    CHANGE_PAGE,
    CHANGE_PAGE_SIZE,
    GET_LIST,
    GET_LIST_DONE
} from './constants';

export const getList = (pageNumber?: number) => action(GET_LIST, pageNumber);

export const getListDone = (page: IOrdersListPage) =>
    action(GET_LIST_DONE, page);

export const changePage = (page: number) => action(CHANGE_PAGE, page);

export const changePageSize = (pageSize: number) =>
    action(CHANGE_PAGE_SIZE, pageSize);
