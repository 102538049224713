import { isNil, map, sum, trim } from 'lodash';
import { ProductType, SheetSize, SizeType } from '../repository/OrderModel';
import { CHAR_WIDTHS_MAP } from './charMaps';
import {
    MAX_FIELD_WIDTH,
    MAX_X_COORDINATE_VALUE,
    MAX_Y_COORDINATE_VALUE,
    MIN_COORDINATES_VALUE,
    WIDTH_FIELD_NAMES,
    ALLOWED_FILE_FORMATS,
    MIN_IMAGE_DIMENSION,
    MAX_IMAGE_ASPECT_RATIO,
    MAX_IMAGE_DIMENSION,
    MIN_IMAGE_ASPECT_RATIO,
    MAX_FILE_SIZE,
    ROUNDING_FACTOR
} from './validaotrsConst';

const validateXMax = (value: string) =>
    validateMax(value, MAX_X_COORDINATE_VALUE);

const validateYMax = (value: string) =>
    validateMax(value, MAX_Y_COORDINATE_VALUE);

const validateMax = (value: string, max: number) => {
    const numberValue = Number.parseFloat(value);
    return numberValue <= max ? undefined : `Maximum value is ${max}`;
};

const validateMin = (value: string) => {
    const numberValue = Number.parseFloat(value);
    return numberValue >= 0
        ? undefined
        : `Minimum value is ${MIN_COORDINATES_VALUE}`;
};

const validateXValueType = (value: string) =>
    validateInteger(value, MAX_X_COORDINATE_VALUE);

const validateYValueType = (value: string) =>
    validateInteger(value, MAX_Y_COORDINATE_VALUE);

const validateInteger = (value: string, max: number) => {
    const numberValue = Number.parseFloat(value);

    return Number.isInteger(numberValue)
        ? undefined
        : `Value has to be integer from range ${MIN_COORDINATES_VALUE} to ${max}`;
};

export const validateCharacter = (char: string, maxFieldWidth: number, size: SizeType, fieldName: WIDTH_FIELD_NAMES) => {
    return !!CHAR_WIDTHS_MAP?.[size]?.['value']?.[char]?.[fieldName];
};

const validateLength = (
    value: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: WIDTH_FIELD_NAMES,
) => {
    const length = map(value, (char) => {
        const charWidths = CHAR_WIDTHS_MAP[size].value[char];
        return charWidths ? charWidths[fieldName] : maxFieldWidth;
    });

    const result = sum(length);
    return maxFieldWidth >= Math.round(result * ROUNDING_FACTOR) / ROUNDING_FACTOR
};

const validateFileSize = (file: File) =>
    file.size < MAX_FILE_SIZE ? undefined : 'Too big file';

const validateFileFormat = (file: File) =>
    ALLOWED_FILE_FORMATS.some(fileFormat => file.type.indexOf(fileFormat) > 0)
        ? undefined
        : 'Incorrect file format';

const validateImageWidth = (image: HTMLImageElement) =>
    MIN_IMAGE_DIMENSION <= image.naturalWidth &&
    image.naturalWidth <= MAX_IMAGE_DIMENSION
        ? undefined
        : `Image width has to be in range of ${MIN_IMAGE_DIMENSION}px to ${MAX_IMAGE_DIMENSION}px`;

const validateImageHeight = (image: HTMLImageElement) =>
    MIN_IMAGE_DIMENSION <= image.naturalHeight &&
    image.naturalHeight <= MAX_IMAGE_DIMENSION
        ? undefined
        : `Image height has to be in range of ${MIN_IMAGE_DIMENSION}px to ${MAX_IMAGE_DIMENSION}px`;

const validateAspectRatio = (image: HTMLImageElement) => {
    const { naturalWidth, naturalHeight } = image;
    const aspectRatio = naturalWidth / naturalHeight;
    return MIN_IMAGE_ASPECT_RATIO <= aspectRatio &&
        aspectRatio <= MAX_IMAGE_ASPECT_RATIO
        ? undefined
        : `Image aspect ratio has to be in range of ${MIN_IMAGE_ASPECT_RATIO} to ${MAX_IMAGE_ASPECT_RATIO}`;
};

export const validateXCoordinate = (value: string) =>
    validateAll(value, [validateXValueType, validateXMax, validateMin], true);

export const validateYCoordinate = (value: string) =>
    validateAll(value, [validateYValueType, validateYMax, validateMin], true);

export const required = (value: any) => {
    return isEmpty(value) ? 'Required' : undefined;
};

export const validateLastCharacter = (value: string, maxFieldWidth: number, size: SizeType, fieldName: WIDTH_FIELD_NAMES) => {
    const lastChar = value.charAt(value.length - 1);
    return validateCharacter(lastChar.charAt(0), maxFieldWidth, size, fieldName)
        ? undefined
        : `Character ${lastChar} is not supported`;
};

export const validateTitleLength = (
    title: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: WIDTH_FIELD_NAMES,
    isForFormCheck?: boolean
) => {
    if (!title) {
        return '';
    }

    return validateLength(title.toUpperCase(), maxFieldWidth, size, fieldName)
        ? undefined
        : 'Max print width of title reached';
}

export const validateSubtitleLength = (
    subtitle: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: WIDTH_FIELD_NAMES,
    isForFormCheck?: boolean
) => {
    if (!subtitle) {
        return '';
    }

    return validateLength(subtitle, maxFieldWidth, size, fieldName)
        ? undefined
        : 'Max print width of subtitle reached';
}

export const validateSpineLength = (
    value: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: WIDTH_FIELD_NAMES,
    isForFormCheck?: boolean
) => {
    if (!value) {
        return '';
    }

    return validateLength(value.toUpperCase(), maxFieldWidth, size, fieldName)
        ? undefined
        : 'Max print width of spine reached';
}

export const validateEmail = (email: string) =>
    // eslint-disable-next-line
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || email === ''
        ? undefined
        : 'Provide valid email';

export const validateAll = <T>(
    value: T,
    validators: ((
        value: T,
        maxFieldWidth: number,
        size: SizeType,
        fieldName: WIDTH_FIELD_NAMES,
    ) => string | undefined)[],
    isRequired?: boolean,
    maxFieldWidth?: number,
    size?: SizeType,
    fieldName?: WIDTH_FIELD_NAMES,
) => {
    let error: string | undefined;
    if (isRequired) {
        error = required(value);
    }
    for (let i = 0; value && !error && i < validators.length; i++) {
        error = validators[i](value, maxFieldWidth as number, size as SizeType, fieldName as WIDTH_FIELD_NAMES);
    }

    return error;
};

export const validateCoverFile = (file: File) =>
    validateAll(file, [validateFileFormat, validateFileSize]);

export const validateCoverImageDimension = (image: HTMLImageElement) =>
    validateAll(image, [
        validateImageWidth,
        validateImageHeight,
        validateAspectRatio
    ]);

const isEmpty = (value: any) => isNil(value) || value === '';

export const validateEmailAddress = (email: string) =>
    isEmpty(email) ? 'Email is required' : validateEmail(email);

export const validateRequiredComment = (comment: string) =>
    isEmpty(trim(comment)) ? 'Comment is required' : validateComment(comment);

export const validateComment = (comment: string) =>
    comment.length > 500 ? 'Comment can contain max 500 characters' : undefined;

export const validateTitleLargeFlat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateTitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        WIDTH_FIELD_NAMES.TITLE
    );
};

export const validateTitleLargeFolded = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateTitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        WIDTH_FIELD_NAMES.TITLE
    );
};

export const validateTitleA3Folded = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateTitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        WIDTH_FIELD_NAMES.TITLE
    );
};

export const validateTitleA3Flat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateTitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        WIDTH_FIELD_NAMES.TITLE
    );
};

export const validateTitleA2Flat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateTitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        WIDTH_FIELD_NAMES.TITLE
    );
};

export const validateSubTitleLargeFlat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateSubtitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        WIDTH_FIELD_NAMES.SUBTITLE
    );
};

export const validateSubTitleLargeFolded = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateSubtitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        WIDTH_FIELD_NAMES.SUBTITLE
    );
};

export const validateSubTitleA3Folded = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateSubtitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        WIDTH_FIELD_NAMES.SUBTITLE
    );
};

export const validateSubTitleA3Flat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateSubtitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        WIDTH_FIELD_NAMES.SUBTITLE
    );
};

export const validateSubTitleA2Flat = (value: string) => {
    return validateAll(
        value,
        [validateLastCharacter, validateSubtitleLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        WIDTH_FIELD_NAMES.SUBTITLE
    );
};

export const validateSpineLargeFlat = (value: string) => {
    return validateAll(
        value,
        [required, validateLastCharacter, validateSpineLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        WIDTH_FIELD_NAMES.SPINE
    );
};

export const validateSpineLargeFolded = (value: string) => {
    return validateAll(
        value,
        [required, validateLastCharacter, validateSpineLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        WIDTH_FIELD_NAMES.SPINE
    );
};

export const validateSpineA3Folded = (value: string) => {
    return validateAll(
        value,
        [required, validateLastCharacter, validateSpineLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        WIDTH_FIELD_NAMES.SPINE
    );
};

export const validateSpineA3Flat = (value: string) => {
    return validateAll(
        value,
        [required, validateLastCharacter, validateSpineLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        WIDTH_FIELD_NAMES.SPINE
    );
};

export const validateSpineA2Flat = (value: string) => {
    return validateAll(
        value,
        [required, validateLastCharacter, validateSpineLength],
        false,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        WIDTH_FIELD_NAMES.SPINE
    );
};

const selectValidatorBasedOnProductType = (sizeType: SizeType, textType: WIDTH_FIELD_NAMES) => {
    switch (sizeType) {
        case SizeType.A2_FLAT: {
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineA2Flat;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleA2Flat;
            } else {
                return validateTitleA2Flat;
            }
        }

        case SizeType.A3_FLAT: {
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineA3Flat;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleA3Flat;
            } else {
                return validateTitleA3Flat;
            }
        }

        case SizeType.A3_FOLDED: {
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineA3Folded;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleA3Folded;
            } else {
                return validateTitleA3Folded;
            }
        }

        case SizeType.LARGE_FLAT: {
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineLargeFlat;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleLargeFlat;
            } else {
                return validateTitleLargeFlat;
            }
        }

        case SizeType.LARGE_FOLDED: {
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineLargeFolded;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleLargeFolded;
            } else {
                return validateTitleLargeFolded;
            }
        }

        default:
            if (textType === WIDTH_FIELD_NAMES.SPINE) {
                return validateSpineA3Flat;
            } else if (textType === WIDTH_FIELD_NAMES.SUBTITLE) {
                return validateSubTitleA3Flat;
            } else {
                return validateTitleA3Flat;
            }
    }
};

export const getValidator = (size: SheetSize | undefined, productType: ProductType, textType: WIDTH_FIELD_NAMES) => {
    switch (size) {
        case SheetSize.LARGE: {
            if (productType === ProductType.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.LARGE_FLAT, textType);
            } else if (productType === ProductType.FOLDED_MAP) {
                return selectValidatorBasedOnProductType(SizeType.LARGE_FOLDED, textType);
            }
        }

        case SheetSize.A2: {
            if (productType === ProductType.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A2_FLAT, textType);
            }
        }

        case SheetSize.A3: {
            if (productType === ProductType.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A3_FLAT, textType);
            } else if (productType === ProductType.FOLDED_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A3_FOLDED, textType);
            }
        }

        default:
            return selectValidatorBasedOnProductType(SizeType.A3_FLAT, textType);
    }
};