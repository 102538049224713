import React from 'react';

import { FormInput } from '../FormInput';

import styles from './ReasonAndCommentInputs.module.scss';
import { ReasonSelect } from './components/ReasonSelect';

export const ReasonAndCommentInputs = () => {
    const baseClassName = 'inputs';

    return (
        <div className={styles[baseClassName]}>
            <div className={styles[`${baseClassName}__reason`]}>
                <ReasonSelect />
            </div>
            <div className={styles[`${baseClassName}__comment`]}>
                <FormInput
                    label='Comment'
                    name='text'
                    placeholder='Put your comment'
                    type='text'
                    disabled={false}
                />
            </div>
        </div>
    );
};
