import { IOrdersListPage } from '../../../repository/OrderModel';

export const EmptyOrdersPage: IOrdersListPage = {
    content: [],
    first: true,
    last: true,
    totalElements: 0,
    totalPages: 0,
    pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0
    }
};
