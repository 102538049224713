import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import './CoverSelect.scss';
import { connect } from 'react-redux';
import { orderActions } from '../../../../../../../../../../../../features/Order';
import {
    validateCoverFile,
    validateCoverImageDimension
} from '../../../../../../../../../../../../utils/validators';

export interface ICoverSelectProps {
    coverUrl: string;
    orderLineIndex: number;
    disabled: boolean;
    setCover: typeof orderActions.setCover;
}

export class CoverSelect extends React.PureComponent<ICoverSelectProps> {
    public render() {
        const { coverUrl, orderLineIndex, disabled } = this.props;
        return (
            <div className='cover-select'>
                <img
                    src={coverUrl}
                    alt='cover'
                    className='cover-image'
                    width='180'
                />
                <div className='file-select'>
                    <span className='select-heading'>Cover image</span>
                    <span className='file-description'>
                        min 400x500px
                        <br />
                        max 4000x4000px <br />
                        max 4mb <br />
                        .jpg .png .bmp
                    </span>
                    <label
                        htmlFor={`${orderLineIndex}-cover-select`}
                        className={
                            'button secondary' + (disabled ? ' disabled' : '')
                        }
                    >
                        Change photo
                    </label>
                    <input
                        type='file'
                        id={`${orderLineIndex}-cover-select`}
                        accept='.jpg,.jpeg,.png,.bmp'
                        className='input'
                        onChange={this.onChange}
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length) {
            const file = event.target.files[0];
            let error = validateCoverFile(file);
            if (!error) {
                const image = new Image();
                image.src = URL.createObjectURL(file);

                image.onload = () => {
                    error = validateCoverImageDimension(image);
                    if (!error) {
                        const { setCover, orderLineIndex } = this.props;
                        setCover(orderLineIndex, image.src, file);
                    } else {
                        toastr.error('', error.toString());
                    }
                };
            } else {
                toastr.error('', error.toString());
            }
        } else {
            toastr.info('', 'File was not changed');
        }
    };
}

export default connect(
    null,
    { setCover: orderActions.setCover }
)(CoverSelect);
