import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Types from 'Types';
import { Checkbox } from '../../../../../../../../Components/Checkbox';
import {
    filtersActions,
    filtersSelectors
} from '../../../../../../../../features/Filters';
import { Dispatch, bindActionCreators } from 'redux';
import './ProductFilters.scss';
import {
    getSKUs,
    ICategoryUI,
    IProductUI,
    productsData,
    IProductsData
} from './utils';

export interface IProductFiltersProps {
    setFilterProductTypes: typeof filtersActions.setSKUs;
    skus: number[];
}

export const ProductFilters: React.FC<IProductFiltersProps> = ({
    setFilterProductTypes: setFilterProductType,
    skus
}) => {
    const [products, setProducts] = useState<IProductsData[]>(productsData);
    const [previousSKUs, setPreviousSKUs] = useState<number[]>([]);

    useEffect(() => {
        let changed = false;
        const updatedProductsData = products.map(category => {
            return {
                ...category,
                products: category.products.map(product => {
                    changed =
                        changed ||
                        product.checked !== skus.includes(product.sku[0]);

                    return {
                        ...product,
                        checked: skus.includes(product.sku[0])
                    };
                })
            };
        });
        if (changed) setProducts(updatedProductsData);
        // eslint-disable-next-line
    }, [skus]);

    const onChange = useCallback(
        (productName: string) => {
            setProducts(
                products.map(category => {
                    return {
                        ...category,
                        products: category.products.map(product => {
                            return {
                                ...product,
                                checked:
                                    product.name === productName
                                        ? !product.checked
                                        : product.checked
                            };
                        })
                    };
                })
            );
        },
        [products]
    );

    useEffect(() => {
        const newSKUs = getSKUs(products);
        if (previousSKUs.sort().toString() !== newSKUs.sort().toString()) {
            setPreviousSKUs(newSKUs);
            setFilterProductType(newSKUs);
        }
    }, [setFilterProductType, products, previousSKUs]);

    const renderProduct = (product: IProductUI) => {
        return (
            <Checkbox
                label={product.name}
                checked={product.checked}
                value={product.name}
                onChange={onChange}
                key={product.name}
            />
        );
    };

    const renderCategory = (category: ICategoryUI) => {
        return (
            <div className='product-filters'>
                <h4 className='product-filters-header'>{category.name}</h4>
                {category.products.map(product => renderProduct(product))}
            </div>
        );
    };

    return <>{products.map(category => renderCategory(category))}</>;
};

export default connect(
    (state: Types.RootState) => ({
        skus: filtersSelectors.getSKUs(state)
    }),
    (dispatch: Dispatch<Types.RootAction>) =>
        bindActionCreators(
            { setFilterProductTypes: filtersActions.setSKUs },
            dispatch
        )
)(ProductFilters);
