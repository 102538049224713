import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { filter, map, switchMap } from 'rxjs/operators';
import Types from 'Types';
import { isOfType } from 'typesafe-actions';
import {
    IOrdersListPage,
    IOrdersListRequestParams
} from '../../repository/OrderModel';
import ordersRepository from '../../repository/ordersRepostitory';
import { ordersListActions, ordersListConstants } from './';
import { CHANGE_PAGE_SIZE } from './constants';
import { EmptyOrdersPage } from './fixtures/emptyOrdersPage';

const getListEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(ordersListConstants.GET_LIST)),
        switchMap(action =>
            ordersRepository
                .getOrders({
                    sort: 'createdAt,desc',
                    page: action.payload,
                    size: state$.value.ordersList.selectedPageSize,
                    ...state$.value.filters
                } as IOrdersListRequestParams)
                .then((response: IOrdersListPage) =>
                    ordersListActions.getListDone(response)
                )
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            'Something went wrong while getting list of orders'
                        );
                    }

                    return ordersListActions.getListDone(EmptyOrdersPage);
                })
        )
    );

const changePageEpic: Epic<Types.RootAction, Types.RootAction> = action$ =>
    action$.pipe(
        filter(isOfType(ordersListConstants.CHANGE_PAGE)),
        map(action => ordersListActions.getList(action.payload))
    );

const changePageSizeEpic: Epic<Types.RootAction, Types.RootAction> = action$ =>
    action$.pipe(
        ofType(CHANGE_PAGE_SIZE),
        map(action => ordersListActions.getList())
    );

export default combineEpics(getListEpic, changePageEpic, changePageSizeEpic);
