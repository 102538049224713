import axios from 'axios';
import { paramsSerializer } from '../utils';
import { SERVICE_URL } from '../utils/constants';
import {
    IOrder,
    IOrdersListPage,
    IOrdersListRequestParams,
    IOrderActionRequestData,
    IOrderUpdateRequest
} from './OrderModel';

class OrdersRepository {
    private endpoint: string = `${SERVICE_URL}/order`;
    public getOrders: (
        params: IOrdersListRequestParams
    ) => Promise<IOrdersListPage> = async params => {
        const { data }: { data: IOrdersListPage } = await axios.get<
            IOrdersListPage
        >(this.endpoint, {
            params,
            paramsSerializer
        });
        return data;
    };

    public getOrder = async (orderRef: string) => {
        const { data } = await axios.get<IOrder>(
            `${this.endpoint}/${encodeURIComponent(orderRef)}`
        );

        return data;
    };

    public performOrderAction: (
        orderRef: string,
        action: string,
        reason?: string,
        comment?: string
    ) => Promise<any> = async (
        orderRef: string,
        action: string,
        reason?: string,
        comment?: string
    ) => {
        const params: IOrderActionRequestData = { action };

        if (reason) {
            params.reason = reason;
        }

        if (comment) {
            params.comment = comment;
        }

        return await this.performActionRequest(
            `${this.endpoint}/${encodeURIComponent(orderRef)}`,
            action,
            reason,
            comment
        );
    };

    public performOrderLineAction: (
        orderRef: string,
        orderLineRef: string,
        action: string,
        reason?: string,
        comment?: string
    ) => Promise<any> = async (
        orderRef,
        orderLineRef,
        action,
        reason,
        comment
    ) => {
        const params = new URLSearchParams();
        params.append('action', action);

        if (reason) {
            params.append('reason', reason);
        }

        if (comment) {
            params.append('comment', comment);
        }

        return await this.performActionRequest(
            `${this.endpoint}/${encodeURIComponent(
                orderRef
            )}/orderLine/${encodeURIComponent(orderLineRef)}`,
            action,
            reason,
            comment
        );
    };

    performActionRequest = (
        url: string,
        action: string,
        reason?: string,
        comment?: string
    ) => {
        const params: IOrderActionRequestData = { action };

        if (reason) {
            params.reason = reason;
        }

        if (comment) {
            params.comment = comment;
        }

        return axios.post(url, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    public updateOrder: (
        order: IOrderUpdateRequest
    ) => Promise<any> = async order => {
        return await axios.put(
            `${this.endpoint}/${encodeURIComponent(order.referenceNumber)}`,
            order
        );
    };

    public saveImage: (image: File) => Promise<string> = async image => {
        const formData = new FormData();
        formData.append('image', image);
        const { data } = await axios.post<string>(
            `${SERVICE_URL}/image`,
            formData
        );
        return data;
    };
}

const ordersRepository = new OrdersRepository();

export default ordersRepository;
