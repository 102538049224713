import Types from 'Types';

export const getStatuses = (state: Types.RootState) => state.filters.statuses;

export const getSubmittedFrom = (state: Types.RootState) =>
    state.filters.submittedFrom;

export const getSubmittedTo = (state: Types.RootState) =>
    state.filters.submittedTo;

export const getKeywords = (state: Types.RootState) => state.filters.keywords;

export const getSearchValue = (state: Types.RootState) =>
    state.filters.keywords.join(' ');

export const getIsPaused = (state: Types.RootState) => state.filters.isPaused;

export const getHasRoute = (state: Types.RootState) => state.filters.hasRoute;

export const getDeliveryMethods = (state: Types.RootState) =>
    state.filters.deliveryMethods;

export const getSKUs = (state: Types.RootState) => state.filters.productSkus;
