import moment from 'moment';

export enum Status {
    REVIEW = 'For Review',
    COOL_OFF = 'Cool Off',
    IMG_GEN_READY = 'Ready For Image Generation',
    IMAGE_GENERATION = 'In Image Generation',
    FULFILLMENT_READY = 'Ready For Fulfillment',
    FULFILLMENT = 'Fulfillment',
    ARCHIVED = 'Archived',
    ERROR = 'Error',
    CANCELLED = 'Cancelled',
    ANONYMISED = 'Anonymised'
}

export enum Reason {
    DAMAGED_IN_POST = 'DAMAGED_IN_POST',
    WRONG_ADDRESS = 'WRONG_ADDRESS',
    SUPPLIER_ISSUE = 'SUPPLIER_ISSUE',
    POST_OFFICE_ISSUE = 'POST_OFFICE_ISSUE',
    CUSTOMER_ISSUE = 'CUSTOMER_ISSUE',
    INCORRECT_ORDER = 'INCORRECT_ORDER',
    WRONG_MAP_COVER = 'WRONG_MAP_COVER',
    PRINT_FOLD_ISSUE = 'PRINT_FOLD_ISSUE',
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    ON_HOLD = 'ON_HOLD',
    CANCELLED = 'CANCELLED',
    OTHER = 'OTHER'
}

export interface IAddressBasicInfo {
    firstName: string;
    lastName: string;
    email: string;
    postCode: string;
}

export interface IOrderBasicInfo {
    id: number;
    referenceNumber: string;
    billingAddress: IAddressBasicInfo;
    deliveryAddress: IAddressBasicInfo;
    deliveryMethod: DeliveryMethod;
    status: Status;
    submittedAt: string;
    paused: boolean;
}

export interface IPageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
}

export interface IOrdersListPage {
    content: IOrderBasicInfo[];
    first: boolean;
    last: boolean;
    pageable: IPageable;
    totalElements: number;
    totalPages: number;
}

export interface IOrdersListRequestParams {
    statuses?: Status[];
    submittedFrom?: moment.Moment;
    submittedTo?: moment.Moment;
    sort?: string;
    isPaused: boolean;
}

export enum Actions {
    EDIT = 'EDIT',
    ACCEPT = 'ACCEPT',
    RESUPPLY = 'RESUPPLY',
    RESUBMIT = 'RESUBMIT',
    CANCEL = 'CANCEL',
    PAUSE = 'PAUSE',
    UNPAUSE = 'UNPAUSE'
}

export interface IStatusDetails {
    status: Status;
    statusDescription: string;
    possibleActions: Actions[];
}

export interface IOrderStatusDetails extends IStatusDetails {
    isPaused: boolean;
}

export interface IOrderLineStatusDetails extends IStatusDetails {
    errorMessage: string;
    supplierStatus: string;
    validationWarnings: string[];
}

export interface IAddress extends IAddressBasicInfo {
    city: string;
    country: string;
    county: string;
    line1: string;
    line2: string;
    phoneNumber: string;
}

export enum DeliveryMethod {
    ROYAL_MAIL = 'Royal mail',
    INTERNATIONAL = 'International',
    COURIER = 'Courier'
}

export interface ICoordinates {
    x: number;
    y: number;
}

export enum GeometryType {
    Point,
    Polygon
}

export interface IGeometry {
    coordinates: ICoordinates;
    type: GeometryType;
}

export enum Framing {
    NONE = 'No',
    LIGHT_OAK = 'Light oak',
    BLACK = 'Black'
}

export enum Material {
    PAPER = 'Paper',
    CANVAS = 'Canvas'
}

interface IRouteSetting {
    lineColourHex: string;
    lineThickness: number;
    lineOpacity: number;
}

export interface IRouteSpecification {
    routeId: string;
    settings: IRouteSetting;
}

export interface IProduct {
    language: Language;
    productType: ProductType;
    scale: Scale;
    subtitle: string[];
    title: string[];
    spineText: string;
    routeSpecification?: IRouteSpecification;
    coverUrl?: string;
    geometry: IGeometry;
    framing?: Framing;
    material?: Material;
    size?: SheetSize;
}

export enum Language {
    EN = 'English',
    CY = 'Welsh'
}

export enum ProductType {
    NONE = 'NONE',
    FOLDED_MAP = 'FOLDED_MAP',
    FLAT_MAP = 'FLAT_MAP',
}

export enum Scale {
    OS_LANDRANGER = 'Landranger',
    OS_EXPLORER = 'Explorer',
    OS_EXPLORER_ENLARGED = 'Explorer Enlarged'
}
export enum SheetSize {
    LARGE = 'LARGE',
    A2 = 'A2',
    A3 = 'A3'
}

export enum SizeType {
    LARGE_FLAT = 'LARGE_FLAT',
    LARGE_FOLDED = 'LARGE_FOLDED',
    A3_FOLDED = 'A3_FOLDED',
    A3_FLAT = 'A3_FLAT',
    A2_FLAT = 'A2_FLAT',
}

export interface IOrderLine {
    product: IProduct;
    quantity: number;
    productOutputDownloadLink: string;
    referenceNumber: string;
    status: IOrderLineStatusDetails;
}

export interface IOrderBase {
    status: IOrderStatusDetails;
    referenceNumber: string;
    billingAddress: IAddress;
    deliveryAddress: IAddress;
    deliveryAddressSameAsBilling: boolean;
    deliveryMethod: DeliveryMethod;
    submittedAt: string;
    orderLines: IOrderLine[];
}

export interface Comment {
    text: string;
    reason?: string;
}

export interface IHistoricalComment extends Comment {
    time: string;
    author: string;
    action: string;
}

export interface IOrder extends IOrderBase {
    comments: IHistoricalComment[];
}

export interface IOrderUpdateRequest extends IOrderBase {
    comment: Comment;
}

export interface ICover {
    url: string;
    image?: File;
}

export interface IOrderActionRequestData {
    action: string;
    comment?: string;
    reason?: string;
}
