import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import './DropdownArrow.scss';

export interface IDropdownArrowProps {
    expanded: boolean;
    initialArrowDown?: boolean;
}

export interface IDefaultProps {
    initialArrowDown: boolean;
}

export class DropdownArrow extends React.Component<IDropdownArrowProps> {
    public static defaultProps: IDefaultProps = {
        initialArrowDown: false
    };

    public render() {
        return (
            <CSSTransition
                classNames={
                    this.props.initialArrowDown ? 'arrow-down' : 'arrow'
                }
                timeout={500}
                in={this.props.expanded}
            >
                <label className='arrow'>
                    <i
                        className={
                            'mdi ' +
                            (this.props.initialArrowDown
                                ? 'mdi-chevron-down'
                                : 'mdi-chevron-up')
                        }
                    />
                </label>
            </CSSTransition>
        );
    }
}
