import moment from 'moment';

const addParam = (options: string, key: string, value: any) => {
    if (value) {
        return `${options}${key}=${encodeURIComponent(value)}&`;
    }

    return options;
};

const addDateParam = (options: string, key: string, value: any) => {
    if (value) {
        return `${options}${key}=${value}&`;
    }

    return options;
};

export const paramsSerializer = (params: any) => {
    let options = '';
    for (const key of Object.keys(params)) {
        const param = params[key];
        if (param instanceof Array) {
            param.forEach(
                // eslint-disable-next-line no-loop-func
                element => (options = addParam(options, key, element))
            );
        } else {
            if (moment.isMoment(param)) {
                options = addDateParam(options, key, param.toISOString());
            } else {
                options = addParam(options, key, param);
            }
        }
    }

    return options ? options.slice(0, -1) : options;
};
