import * as React from 'react';
import { Button } from '../../../../../../Components/Button';
import history from '../../../../../../utils/history';
import './FormButtons.scss';

export interface IFormButtonsProps {
    disabled: boolean;
}

export class FormButtons extends React.PureComponent<IFormButtonsProps> {
    public render() {
        const { disabled } = this.props;
        return (
            <div className='form-buttons'>
                <Button
                    buttonType='secondary'
                    onClick={this.cancelEdit}
                    id='cancel-changes'
                >
                    Cancel changes
                </Button>
                <Button
                    buttonType='primary'
                    id='update-changes'
                    type='submit'
                    disabled={disabled}
                >
                    Update
                </Button>
            </div>
        );
    }

    private cancelEdit = () => {
        history.push('/orders');
    };
}
