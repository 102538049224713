import React, { useCallback, useState } from 'react';
import { SectionHeader } from '../../../../../../Components/SectionHeader';
import { DeliveryMethod } from '../../../../../../repository/OrderModel';
import { CustomerSectionContent } from './Components/CustomerSectionContent';

export interface ICustomerSectionProps {
    deliveryMethod: DeliveryMethod;
    disabled: boolean;
}

export const CustomerSection: React.FC<ICustomerSectionProps> = props => {
    const { deliveryMethod, disabled } = props;

    const [billingExpanded, setBillingExpanded] = useState(false);
    const [deliveryExpanded, setDeliveryExpanded] = useState(true);

    const toggleBilling = useCallback(
        () => setBillingExpanded(!billingExpanded),
        [billingExpanded, setBillingExpanded]
    );
    const toggleDelivery = useCallback(
        () => setDeliveryExpanded(!deliveryExpanded),
        [deliveryExpanded]
    );
    return (
        <React.Fragment>
            <SectionHeader
                sectionTitle='Billing information'
                expanded={billingExpanded}
                toggleSection={toggleBilling}
                initialArrowDown={true}
            />
            <CustomerSectionContent
                expanded={billingExpanded}
                deliveryMethod={deliveryMethod}
                disabled={disabled}
                addressType='billingAddress'
            />
            <SectionHeader
                sectionTitle='Delivery information'
                expanded={deliveryExpanded}
                toggleSection={toggleDelivery}
                initialArrowDown={false}
            />
            <CustomerSectionContent
                expanded={deliveryExpanded}
                deliveryMethod={deliveryMethod}
                disabled={disabled}
                addressType='deliveryAddress'
            />
        </React.Fragment>
    );
};
