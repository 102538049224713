import moment from 'moment';
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateFilter.scss';

export interface IDateFiltersProps {
    placeholder: string;
    submittedFrom?: moment.Moment;
    submittedTo?: moment.Moment;
    submittedFromChanged: (date: moment.Moment) => void;
    submittedToChanged: (date: moment.Moment) => void;
}

export const DateFilters: React.FunctionComponent<
    IDateFiltersProps
> = props => {
    const {
        placeholder,
        submittedFrom,
        submittedTo,
        submittedFromChanged,
        submittedToChanged
    } = props;
    return (
        <React.Fragment>
            <div className='date-filter' id='date-filter-from'>
                <span>From</span>
                <div className='date-picker-wrapper'>
                    <ReactDatePicker
                        onChange={submittedFromChanged}
                        isClearable={true}
                        selected={submittedFrom}
                        utcOffset={0}
                        dateFormat='DD/MM/YYYY'
                        placeholderText={placeholder}
                        maxDate={submittedTo || moment()}
                    />
                    <i className='mdi mdi-calendar-today' />
                </div>
            </div>
            <div className='date-filter' id='date-filter-to'>
                <span>To</span>
                <div className='date-picker-wrapper'>
                    <ReactDatePicker
                        onChange={submittedToChanged}
                        isClearable={true}
                        selected={submittedTo}
                        utcOffset={0}
                        dateFormat={'DD/MM/YYYY'}
                        placeholderText={placeholder}
                        minDate={submittedFrom}
                        maxDate={moment()}
                    />
                    <i className='mdi mdi-calendar' />
                </div>
            </div>
        </React.Fragment>
    );
};
