export const CLEAR_ALL = 'filters/CLEAR_ALL';
export const SET_STATUS = 'filters/SET_STATUS';
export const SET_SUBMITTED_FROM = 'filters/SET_SUBMITTED_FROM';
export const SET_SUBMITTED_TO = 'filters/SET_SUBMITTED_TO';
export const SET_SEARCH = 'filters/SET_SEARCH';
export const SET_IS_PAUSED = 'filters/SET_IS_PAUSED';
export const SET_HAS_ROUTE = 'filters/SET-HAS_ROUTE';
export const SEARCH = 'filters/SEARCH';
export const SET_DELIVERY_METHOD = 'filters/SET_DELIVERY_METHOD';
export const SET_SKUS = 'filters/SET_SKUS';
