import React, { useState, useCallback } from 'react';
import { SectionHeader } from '../../../../../../Components/SectionHeader';
import { CommentsSectionContent } from './components/CommentsSectionContent';
import { IHistoricalComment } from '../../../../../../repository/OrderModel';

export interface ICommentsSectionProps {
    comments: IHistoricalComment[];
}

export const CommentsSection: React.FC<ICommentsSectionProps> = ({
    comments
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const toggleSection = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    if (!comments.length) {
        return null;
    }

    return (
        <>
            <SectionHeader
                expanded={expanded}
                toggleSection={toggleSection}
                sectionTitle={`Comments (${comments.length})`}
                initialArrowDown={true}
            />
            <CommentsSectionContent expanded={expanded} comments={comments} />
        </>
    );
};
