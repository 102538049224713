import React, { useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactTable from 'react-table';
import { capitalize, replace } from 'lodash';

import './CommentsSectionContent.scss';
import { IHistoricalComment } from '../../../../../../../../repository/OrderModel';
import moment from 'moment';

export interface ICommentsSectionContentProps {
    expanded: boolean;
    comments: IHistoricalComment[];
}

export const CommentsSectionContent: React.FC<ICommentsSectionContentProps> = ({
    expanded,
    comments
}) => {
    const baseClassName = 'comments-section-content';

    const dateCell = useCallback(
        ({ value }: { value: string }) => (
            <React.Fragment>
                <span
                    className='time'
                    title={moment(value).format('DD/MM/YYYY hh:mm A')}
                >
                    {moment(value).format('hh:mm A')}
                </span>
                <span
                    className='date'
                    title={moment(value).format('DD/MM/YYYY hh:mm A')}
                >
                    {moment(value).format('DD/MM/YYYY')}
                </span>
            </React.Fragment>
        ),
        []
    );

    const actionCell = useCallback(
        ({ value }: { value: string }) => capitalize(value),
        []
    );

    const reasonCell = useCallback(
        ({ value }: { value: string }) => replace(capitalize(value), /_/g, ' '),
        []
    );

    return (
        <CSSTransition
            timeout={500}
            classNames={baseClassName}
            in={expanded}
            unmountOnExit={true}
        >
            <div className={baseClassName}>
                <ReactTable
                    columns={[
                        {
                            Header: 'Date',
                            accessor: 'time',
                            Cell: dateCell,
                            width: 85
                        },
                        { Header: 'Author', accessor: 'author', width: 150 },
                        {
                            Header: 'Action',
                            accessor: 'action',
                            width: 85,
                            Cell: actionCell
                        },
                        {
                            Header: 'Reason',
                            accessor: 'reason',
                            width: 150,
                            Cell: reasonCell
                        },
                        {
                            Header: 'Comment',
                            accessor: 'text',
                            style: {
                                whiteSpace: 'unset',
                                wordBreak: 'break-word'
                            }
                        }
                    ]}
                    resizable={false}
                    showPagination={false}
                    sortable={false}
                    className='-striped -highlight'
                    data={comments}
                    pageSize={comments.length}
                />
            </div>
        </CSSTransition>
    );
};
