import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import { FullPageSpinner } from '../../Components/FullPageSpinner';
import { TitleSection } from '../../Components/TitleSection';
import {
    orderListSelectors,
    ordersListActions
} from '../../features/OrdersList';
import { IOrderBasicInfo } from '../../repository/OrderModel';
import { Filters } from './Components/Filters';
import { OrdersList } from './Components/OrdersList';
import { Pagination } from './Components/Pagination';
import { SearchInput } from './Components/SearchInput';
import './OrdersPage.scss';

export interface IOrdersPageProps {
    loadingOrders: boolean;
    orders: IOrderBasicInfo[];
    getList: typeof ordersListActions.getList;
}

export class OrdersPage extends React.Component<IOrdersPageProps> {
    public componentDidMount() {
        this.props.getList();
    }

    public render() {
        return (
            <React.Fragment>
                <TitleSection />
                <div className='container orders-page'>
                    {this.props.loadingOrders && <FullPageSpinner />}

                    <Filters />
                    <div className='orders-list'>
                        <SearchInput />
                        <OrdersList orders={this.props.orders} />
                        <Pagination />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: Types.RootState) => ({
    loadingOrders: orderListSelectors.getLoadingOrders(state),
    orders: orderListSelectors.getOrders(state)
});

const mapDispatchToProps = (dispatch: Dispatch<Types.RootAction>) =>
    bindActionCreators({ getList: ordersListActions.getList }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersPage);
