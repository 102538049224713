import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Types from 'Types';
import { DeliveryMethod } from '../../../../../../../../repository/OrderModel';
import { Checkbox } from '../../../../../../../../Components/Checkbox';
import {
    filtersSelectors,
    filtersActions
} from '../../../../../../../../features/Filters';
import { Dispatch, bindActionCreators } from 'redux';

export interface IDeliveryFiltersProps {
    deliveryMethods: DeliveryMethod[];
    setDeliveryMethod: typeof filtersActions.setDeliveryMethod;
}

export const DeliveryFilters: React.FC<IDeliveryFiltersProps> = ({
    deliveryMethods,
    setDeliveryMethod
}) => {
    const onChange = useCallback(
        (value: string) => setDeliveryMethod(value as DeliveryMethod),
        [setDeliveryMethod]
    );

    return (
        <React.Fragment>
            {Object.keys(DeliveryMethod).map(deliveryMethod => {
                if (
                    DeliveryMethod[
                        deliveryMethod as keyof typeof DeliveryMethod
                    ] !== DeliveryMethod.INTERNATIONAL
                )
                    return (
                        <Checkbox
                            label={
                                DeliveryMethod[
                                    deliveryMethod as keyof typeof DeliveryMethod
                                ]
                            }
                            checked={
                                deliveryMethods.indexOf(
                                    deliveryMethod as DeliveryMethod
                                ) >= 0
                            }
                            value={deliveryMethod}
                            onChange={onChange}
                            key={deliveryMethod}
                        />
                    );
                return null;
            })}
        </React.Fragment>
    );
};

export default connect(
    (state: Types.RootState) => ({
        deliveryMethods: filtersSelectors.getDeliveryMethods(state)
    }),
    (dispatch: Dispatch<Types.RootAction>) =>
        bindActionCreators(
            { setDeliveryMethod: filtersActions.setDeliveryMethod },
            dispatch
        )
)(DeliveryFilters);
