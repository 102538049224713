import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import { ButtonSection } from '../../../../Components/ButtonsSection';
import { orderActions, orderSelectors } from '../../../../features/Order';
import { IOrderStatusDetails } from '../../../../repository/OrderModel';
import { OrderNoSection } from './Components/OrderNoSection';
import './OrderSummary.scss';

export interface IOrderSummaryProps {
    status: IOrderStatusDetails;
    referenceNumber: string;
    acceptOrder: typeof orderActions.acceptOrder;
    cancelOrder: typeof orderActions.cancelOrder;
    resupplyOrder: typeof orderActions.resupplyOrder;
    resubmitOrder: typeof orderActions.resubmitOrder;
    pauseOrder: typeof orderActions.pauseOrder;
    unpauseOrder: typeof orderActions.unpauseOrder;
}

export class OrderSummary extends React.PureComponent<IOrderSummaryProps> {
    public render() {
        const { status, referenceNumber } = this.props;
        return (
            <div className='container'>
                <div className='order-summary'>
                    <OrderNoSection
                        paused={status.isPaused}
                        referenceNumber={referenceNumber}
                    />
                    <ButtonSection
                        accept={this.accept}
                        cancel={this.cancel}
                        resubmit={this.resubmit}
                        resupply={this.resupply}
                        pause={this.pause}
                        unpause={this.unpause}
                        possibleActions={status.possibleActions}
                        status={status.status}
                        subject='Order'
                    />
                </div>
            </div>
        );
    }

    private accept = () => {
        this.props.acceptOrder(this.props.referenceNumber);
    };

    private cancel = (reason: string, comment: string) => {
        this.props.cancelOrder(this.props.referenceNumber, reason, comment);
    };

    private resupply = (reason: string, comment: string) => {
        this.props.resupplyOrder(this.props.referenceNumber, reason, comment);
    };

    private resubmit = (reason: string, comment: string) => {
        this.props.resubmitOrder(this.props.referenceNumber, reason, comment);
    };

    private pause = (reason: string, comment: string) => {
        this.props.pauseOrder(this.props.referenceNumber, reason, comment);
    };

    private unpause = () => {
        this.props.unpauseOrder(this.props.referenceNumber);
    };
}

const mapStateToProps = (state: Types.RootState) => ({
    status: orderSelectors.getOrderStatus(state),
    referenceNumber: orderSelectors.getOrderRefNumber(state)
});

const mapDispatchToProp = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            cancelOrder: orderActions.cancelOrder,
            resupplyOrder: orderActions.resupplyOrder,
            resubmitOrder: orderActions.resubmitOrder,
            pauseOrder: orderActions.pauseOrder,
            unpauseOrder: orderActions.unpauseOrder,
            acceptOrder: orderActions.acceptOrder
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProp)(OrderSummary);
