import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import Types from 'Types';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';
import { composeEnhancers } from './utils';

const epicMiddleware = createEpicMiddleware<
    Types.RootAction,
    Types.RootAction,
    Types.RootState
>();

const configureStore = (initialState: object = {}) => {
    // configure middlewares

    const middlewares = [epicMiddleware];

    // compose enhancers
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));

    // create store
    const newStore = createStore(rootReducer, initialState!, enhancer);

    epicMiddleware.run(rootEpic);

    return newStore;
};

const store = configureStore();

export default store;
