import { WIDTH_FIELD_NAMES } from "./validaotrsConst";

export interface LetterModel {
    [WIDTH_FIELD_NAMES.TITLE]: number;
    [WIDTH_FIELD_NAMES.SUBTITLE]: number;
    [WIDTH_FIELD_NAMES.SPINE]: number;
}

type LetterType = {
    value: {
        [key: string]: Partial<LetterModel>;
    };
};

export interface ILetterWidthModel {
    LARGE_FLAT: LetterType;
    LARGE_FOLDED: LetterType;
    A3_FOLDED: LetterType;
    A3_FLAT: LetterType;
    A2_FLAT: LetterType;
}

export const CHAR_WIDTHS_MAP: ILetterWidthModel = {
    LARGE_FLAT: {
        value: {
            ' ': {
                title: 0.02560907,
                subtitle: 0.011105974,
                spine: 0.0705556,
            },
            '!': {
                title: 0.016462972,
                subtitle: 0.017491909,
                spine: 0.11994451,
            },
            '"': {
                title: 0.03411494,
                subtitle: 0.026765399,
                spine: 0.18944177,
            },
            '#': {
                title: 0.05944962,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            $: {
                title: 0.04810847,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '%': {
                title: 0.070882246,
                subtitle: 0.04670062,
                spine: 0.30233073,
            },
            '&': {
                title: 0.06859572,
                subtitle: 0.035483588,
                spine: 0.23530291,
            },
            "'": {
                title: 0.014633754,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            '(': {
                title: 0.027163906,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            ')': {
                title: 0.027163906,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            '*': {
                title: 0.05039499,
                subtitle: 0.024322083,
                spine: 0.16121954,
            },
            '+': {
                title: 0.056157034,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            ',': {
                title: 0.0171032,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            '-': {
                title: 0.03384056,
                subtitle: 0.017880619,
                spine: 0.117122285,
            },
            '.': {
                title: 0.016462972,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            '/': {
                title: 0.020578716,
                subtitle: 0.019102275,
                spine: 0.11959173,
            },
            '0': {
                title: 0.05194983,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '1': {
                title: 0.029084584,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '2': {
                title: 0.04819993,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '3': {
                title: 0.04472441,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '4': {
                title: 0.046004865,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '5': {
                title: 0.04655363,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '6': {
                title: 0.049205996,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '7': {
                title: 0.04344396,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '8': {
                title: 0.05121814,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '9': {
                title: 0.049297456,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            ':': {
                title: 0.016462972,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            ';': {
                title: 0.0171032,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            '<': {
                title: 0.056065567,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '=': {
                title: 0.056065567,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '>': {
                title: 0.056065567,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '?': {
                title: 0.03438932,
                subtitle: 0.024655264,
                spine: 0.1633362,
            },
            '@': {
                title: 0.08844275,
                subtitle: 0.048588637,
                spine: 0.3185585,
            },
            A: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            B: {
                title: 0.05405343,
                subtitle: 0.033151332,
                spine: 0.21343069,
            },
            C: {
                title: 0.06429706,
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            D: {
                title: 0.066857964,
                subtitle: 0.034706168,
                spine: 0.22401401,
            },
            E: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            F: {
                title: 0.045273177,
                subtitle: 0.028320234,
                spine: 0.18485565,
            },
            G: {
                title: 0.06649212,
                subtitle: 0.03487276,
                spine: 0.22507235,
            },
            H: {
                title: 0.063839756,
                subtitle: 0.036816303,
                spine: 0.23777236,
            },
            I: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            J: {
                title: 0.026615143,
                subtitle: 0.027431754,
                spine: 0.17956398,
            },
            K: {
                title: 0.060181312,
                subtitle: 0.033151332,
                spine: 0.21660568,
            },
            L: {
                title: 0.046187785,
                subtitle: 0.027875993,
                spine: 0.18273899,
            },
            M: {
                title: 0.069510326,
                subtitle: 0.041369755,
                spine: 0.26881683,
            },
            N: {
                title: 0.069510326,
                subtitle: 0.036427595,
                spine: 0.23459736,
            },
            O: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            P: {
                title: 0.048931617,
                subtitle: 0.032318383,
                spine: 0.21025568,
            },
            Q: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            R: {
                title: 0.055791188,
                subtitle: 0.032873683,
                spine: 0.2162529,
            },
            S: {
                title: 0.045364637,
                subtitle: 0.03026378,
                spine: 0.19614455,
            },
            T: {
                title: 0.058535017,
                subtitle: 0.030319307,
                spine: 0.19614455,
            },
            U: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            V: {
                title: 0.059083786,
                subtitle: 0.029764012,
                spine: 0.19614455,
            },
            W: {
                title: 0.09402187,
                subtitle: 0.044423897,
                spine: 0.2868085,
            },
            X: {
                title: 0.06941887,
                subtitle: 0.030041661,
                spine: 0.2000251,
            },
            Y: {
                title: 0.058535017,
                subtitle: 0.027820466,
                spine: 0.18520842,
            },
            Z: {
                title: 0.06539458,
                subtitle: 0.02998613,
                spine: 0.19085288,
            },
            '[': {
                title: 0.03411494,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            '\\': {
                title: 0.020578716,
                subtitle: 0.019102275,
                spine: 0.11959173,
            },
            ']': {
                title: 0.03411494,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            '^': {
                title: 0.047102395,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            _: {
                title: 0.05304736,
                subtitle: 0.027764935,
                spine: 0.176389,
            },
            '`': {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            a: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            b: {
                title: 0.046187785,
                subtitle: 0.031263318,
                spine: 0.20214179,
            },
            c: {
                title: 0.039511137,
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            d: {
                title: 0.04664509,
                subtitle: 0.031318847,
                spine: 0.20214179,
            },
            e: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            f: {
                title: 0.028627282,
                subtitle: 0.017602969,
                spine: 0.12029728,
            },
            g: {
                title: 0.043992724,
                subtitle: 0.028875533,
                spine: 0.18838343,
            },
            h: {
                title: 0.045730483,
                subtitle: 0.030985668,
                spine: 0.20143622,
            },
            i: {
                title: 0.020761639,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            j: {
                title: 0.020487256,
                subtitle: 0.014604356,
                spine: 0.098072276,
            },
            k: {
                title: 0.047285322,
                subtitle: 0.028986594,
                spine: 0.19332232,
            },
            l: {
                title: 0.019115342,
                subtitle: 0.015048594,
                spine: 0.1008945,
            },
            m: {
                title: 0.07188831,
                subtitle: 0.04681168,
                spine: 0.30233073,
            },
            n: {
                title: 0.045730483,
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            o: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            p: {
                title: 0.04664509,
                subtitle: 0.031318847,
                spine: 0.20214179,
            },
            q: {
                title: 0.04664509,
                subtitle: 0.031318847,
                spine: 0.20214179,
            },
            r: {
                title: 0.037590455,
                subtitle: 0.020712642,
                spine: 0.14040564,
            },
            s: {
                title: 0.035486855,
                subtitle: 0.023933373,
                spine: 0.15628064,
            },
            t: {
                title: 0.03411494,
                subtitle: 0.020046283,
                spine: 0.13511395,
            },
            u: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            v: {
                title: 0.042254966,
                subtitle: 0.027487285,
                spine: 0.18450288,
            },
            w: {
                title: 0.068687186,
                subtitle: 0.041536346,
                spine: 0.27375573,
            },
            x: {
                title: 0.0496633,
                subtitle: 0.026709868,
                spine: 0.18132788,
            },
            y: {
                title: 0.042254966,
                subtitle: 0.027487285,
                spine: 0.18379733,
            },
            z: {
                title: 0.04344396,
                subtitle: 0.024599731,
                spine: 0.16227788,
            },
            '{': {
                title: 0.030913804,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            '|': {
                title: 0.01692028,
                subtitle: 0.014160117,
                spine: 0.0945445,
            },
            '}': {
                title: 0.030913804,
                subtitle: 0.017991677,
                spine: 0.12135563,
            },
            '~': {
                title: 0.05121814,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            ' ': {
                subtitle: 0.011105974,
                spine: 0.0705556,
            },
            '¡': {
                title: 0.016462972,
                subtitle: 0.017491909,
                spine: 0.11994451,
            },
            '¢': {
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '£': {
                title: 0.056248497,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '¤': {
                title: 0.05487658,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '¥': {
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '¦': {
                subtitle: 0.014160117,
                spine: 0.0945445,
            },
            '§': {
                title: 0.040334288,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '¨': {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            '©': {
                title: 0.07024202,
                subtitle: 0.04148081,
                spine: 0.26458347,
            },
            ª: {
                subtitle: 0.019546514,
                spine: 0.1266473,
            },
            '«': {
                title: 0.047468238,
                subtitle: 0.025266092,
                spine: 0.16933343,
            },
            '¬': {
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '­': {
                subtitle: 0.017880619,
                spine: 0.117122285,
            },
            '®': {
                title: 0.07024202,
                subtitle: 0.024599731,
                spine: 0.16298343,
            },
            '¯': {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            '°': {
                title: 0.030913804,
                subtitle: 0.019324394,
                spine: 0.12876396,
            },
            '±': {
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            '²': {
                subtitle: 0.020657113,
                spine: 0.13264452,
            },
            '³': {
                subtitle: 0.020657113,
                spine: 0.13264452,
            },
            '´': {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            µ: {
                subtitle: 0.032096267,
                spine: 0.20955011,
            },
            '¶': {
                subtitle: 0.03326239,
                spine: 0.22436678,
            },
            '·': {
                title: 0.016462972,
                subtitle: 0.015270715,
                spine: 0.1058334,
            },
            '¸': {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            '¹': {
                subtitle: 0.020657113,
                spine: 0.13264452,
            },
            º: {
                subtitle: 0.020490522,
                spine: 0.13158618,
            },
            '»': {
                title: 0.047468238,
                subtitle: 0.025266092,
                spine: 0.16933343,
            },
            '¼': {
                title: 0.06329098,
                subtitle: 0.044146247,
                spine: 0.28539738,
            },
            '½': {
                title: 0.07097371,
                subtitle: 0.045923203,
                spine: 0.29845017,
            },
            '¾': {
                title: 0.06941887,
                subtitle: 0.044812605,
                spine: 0.2885724,
            },
            '¿': {
                title: 0.03438932,
                subtitle: 0.024655264,
                spine: 0.1633362,
            },
            À: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Á: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Â: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Ã: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Ä: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Å: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            Æ: {
                title: 0.08771106,
                subtitle: 0.04631191,
                spine: 0.2980974,
            },
            Ç: {
                title: 0.06429706,
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            È: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            É: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            Ê: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            Ë: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            Ì: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            Í: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            Î: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            Ï: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            Ð: {
                title: 0.066857964,
                subtitle: 0.036038887,
                spine: 0.23283347,
            },
            Ñ: {
                title: 0.069510326,
                subtitle: 0.036427595,
                spine: 0.23459736,
            },
            Ò: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            Ó: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            Ô: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            Õ: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            Ö: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            '×': {
                title: 0.04874869,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            Ø: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            Ù: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            Ú: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            Û: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            Ü: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            Ý: {
                title: 0.058535017,
                subtitle: 0.027820466,
                spine: 0.18520842,
            },
            Þ: {
                subtitle: 0.033317924,
                spine: 0.217664,
            },
            ß: {
                title: 0.046919476,
                subtitle: 0.03354004,
                spine: 0.22295569,
            },
            à: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            á: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            â: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            ã: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            ä: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            å: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            æ: {
                title: 0.066583574,
                subtitle: 0.043590948,
                spine: 0.2772835,
            },
            ç: {
                title: 0.039511137,
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            è: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            é: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            ê: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            ë: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            ì: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            í: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            î: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            ï: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            ð: {
                title: 0.047925543,
                subtitle: 0.030652488,
                spine: 0.19755566,
            },
            ñ: {
                title: 0.045730483,
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            ò: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            ó: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            ô: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            õ: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            ö: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            '÷': {
                title: 0.056065567,
                subtitle: 0.028486826,
                spine: 0.18626676,
            },
            ø: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            ù: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            ú: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            û: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            ü: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            ý: {
                title: 0.042254966,
                subtitle: 0.027487285,
                spine: 0.18379733,
            },
            þ: {
                subtitle: 0.031318847,
                spine: 0.20214179,
            },
            ÿ: {
                title: 0.042254966,
                subtitle: 0.027487285,
                spine: 0.18379733,
            },
            Ā: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            ā: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            Ă: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            ă: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            Ą: {
                title: 0.06530313,
                subtitle: 0.030985668,
                spine: 0.20214179,
            },
            ą: {
                title: 0.040974513,
                subtitle: 0.028653411,
                spine: 0.185914,
            },
            Ć: {
                title: 0.06429706,
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            ć: {
                title: 0.039511137,
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            Ĉ: {
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            ĉ: {
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            Ċ: {
                title: 0.06429706,
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            ċ: {
                title: 0.039511137,
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            Č: {
                title: 0.06429706,
                subtitle: 0.031985205,
                spine: 0.20531678,
            },
            č: {
                title: 0.039511137,
                subtitle: 0.0256548,
                spine: 0.16474731,
            },
            Ď: {
                title: 0.066857964,
                subtitle: 0.034706168,
                spine: 0.22401401,
            },
            ď: {
                title: 0.0588094,
                subtitle: 0.03298474,
                spine: 0.21660568,
            },
            Đ: {
                subtitle: 0.036038887,
                spine: 0.23283347,
            },
            đ: {
                title: 0.047925543,
                subtitle: 0.031318847,
                spine: 0.20214179,
            },
            Ē: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            ē: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            Ĕ: {
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            ĕ: {
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            Ė: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            ė: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            Ę: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            ę: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            Ě: {
                title: 0.048017003,
                subtitle: 0.02987507,
                spine: 0.19332232,
            },
            ě: {
                title: 0.04344396,
                subtitle: 0.028153645,
                spine: 0.18273899,
            },
            Ĝ: {
                subtitle: 0.03487276,
                spine: 0.22507235,
            },
            ĝ: {
                subtitle: 0.028875533,
                spine: 0.18838343,
            },
            Ğ: {
                title: 0.06649212,
                subtitle: 0.03487276,
                spine: 0.22507235,
            },
            ğ: {
                title: 0.043992724,
                subtitle: 0.028875533,
                spine: 0.18838343,
            },
            Ġ: {
                title: 0.06649212,
                subtitle: 0.03487276,
                spine: 0.22507235,
            },
            ġ: {
                title: 0.043992724,
                subtitle: 0.028875533,
                spine: 0.18838343,
            },
            Ģ: {
                subtitle: 0.03487276,
                spine: 0.22507235,
            },
            ģ: {
                subtitle: 0.028875533,
                spine: 0.18838343,
            },
            Ĥ: {
                subtitle: 0.036816303,
                spine: 0.23777236,
            },
            ĥ: {
                subtitle: 0.030985668,
                spine: 0.20143622,
            },
            Ħ: {
                subtitle: 0.03887091,
                spine: 0.25117794,
            },
            ħ: {
                subtitle: 0.030985668,
                spine: 0.20143622,
            },
            Ĩ: {
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            ĩ: {
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            Ī: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            ī: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            Ĭ: {
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            ĭ: {
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            Į: {
                title: 0.023596928,
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            į: {
                title: 0.020761639,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            İ: {
                subtitle: 0.015659424,
                spine: 0.106186174,
            },
            ı: {
                title: 0.019115342,
                subtitle: 0.014548826,
                spine: 0.09736672,
            },
            Ĳ: {
                subtitle: 0.031374376,
                spine: 0.21237235,
            },
            ĳ: {
                subtitle: 0.029097652,
                spine: 0.19543901,
            },
            Ĵ: {
                subtitle: 0.027431754,
                spine: 0.17956398,
            },
            ĵ: {
                subtitle: 0.014604356,
                spine: 0.098072276,
            },
            Ķ: {
                subtitle: 0.033151332,
                spine: 0.21660568,
            },
            ķ: {
                subtitle: 0.028986594,
                spine: 0.19332232,
            },
            ĸ: {
                subtitle: 0.028986594,
                spine: 0.19332232,
            },
            Ĺ: {
                title: 0.046187785,
                subtitle: 0.027875993,
                spine: 0.18273899,
            },
            ĺ: {
                title: 0.019115342,
                subtitle: 0.015048594,
                spine: 0.1008945,
            },
            Ļ: {
                subtitle: 0.027875993,
                spine: 0.18273899,
            },
            ļ: {
                subtitle: 0.015048594,
                spine: 0.1008945,
            },
            Ľ: {
                title: 0.046187785,
                subtitle: 0.027875993,
                spine: 0.18273899,
            },
            ľ: {
                title: 0.031188186,
                subtitle: 0.01621472,
                spine: 0.11218339,
            },
            Ŀ: {
                title: 0.046187785,
                subtitle: 0.027875993,
                spine: 0.18273899,
            },
            ŀ: {
                title: 0.034663707,
                subtitle: 0.022656187,
                spine: 0.15980843,
            },
            Ł: {
                title: 0.046187785,
                subtitle: 0.028153645,
                spine: 0.18485565,
            },
            ł: {
                title: 0.024237154,
                subtitle: 0.01715873,
                spine: 0.11959173,
            },
            Ń: {
                title: 0.069510326,
                subtitle: 0.036427595,
                spine: 0.23459736,
            },
            ń: {
                title: 0.045730483,
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            Ņ: {
                subtitle: 0.036427595,
                spine: 0.23459736,
            },
            ņ: {
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            Ň: {
                title: 0.069510326,
                subtitle: 0.036427595,
                spine: 0.23459736,
            },
            ň: {
                title: 0.045730483,
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            ŉ: {
                subtitle: 0.044979196,
                spine: 0.29986128,
            },
            Ŋ: {
                subtitle: 0.036149945,
                spine: 0.23495014,
            },
            ŋ: {
                subtitle: 0.031096729,
                spine: 0.201789,
            },
            Ō: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            ō: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            Ŏ: {
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            ŏ: {
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            Ő: {
                title: 0.074997984,
                subtitle: 0.037427135,
                spine: 0.24130014,
            },
            ő: {
                title: 0.050669376,
                subtitle: 0.0304859,
                spine: 0.19579178,
            },
            Œ: {
                title: 0.084144086,
                subtitle: 0.04770016,
                spine: 0.3072696,
            },
            œ: {
                title: 0.078107655,
                subtitle: 0.04608979,
                spine: 0.2899835,
            },
            Ŕ: {
                title: 0.055791188,
                subtitle: 0.032873683,
                spine: 0.2162529,
            },
            ŕ: {
                title: 0.037590455,
                subtitle: 0.020712642,
                spine: 0.14040564,
            },
            Ŗ: {
                subtitle: 0.032873683,
                spine: 0.2162529,
            },
            ŗ: {
                subtitle: 0.020712642,
                spine: 0.14040564,
            },
            Ř: {
                title: 0.055791188,
                subtitle: 0.032873683,
                spine: 0.2162529,
            },
            ř: {
                title: 0.037590455,
                subtitle: 0.020712642,
                spine: 0.14040564,
            },
            Ś: {
                title: 0.045364637,
                subtitle: 0.03026378,
                spine: 0.19614455,
            },
            ś: {
                title: 0.035486855,
                subtitle: 0.023933373,
                spine: 0.15628064,
            },
            Ŝ: {
                subtitle: 0.03026378,
                spine: 0.19614455,
            },
            ŝ: {
                subtitle: 0.023933373,
                spine: 0.15628064,
            },
            Ş: {
                title: 0.045364637,
                subtitle: 0.03026378,
                spine: 0.19614455,
            },
            ş: {
                title: 0.035486855,
                subtitle: 0.023933373,
                spine: 0.15628064,
            },
            Š: {
                title: 0.045364637,
                subtitle: 0.03026378,
                spine: 0.19614455,
            },
            š: {
                title: 0.035486855,
                subtitle: 0.023933373,
                spine: 0.15628064,
            },
            Ţ: {
                title: 0.058535017,
                subtitle: 0.030319307,
                spine: 0.19614455,
            },
            ţ: {
                title: 0.034846626,
                subtitle: 0.020046283,
                spine: 0.13511395,
            },
            Ť: {
                title: 0.058535017,
                subtitle: 0.030319307,
                spine: 0.19614455,
            },
            ť: {
                title: 0.045913402,
                subtitle: 0.020046283,
                spine: 0.13511395,
            },
            Ŧ: {
                title: 0.058535017,
                subtitle: 0.030319307,
                spine: 0.19614455,
            },
            ŧ: {
                title: 0.03411494,
                subtitle: 0.020046283,
                spine: 0.13511395,
            },
            Ũ: {
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ũ: {
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ū: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ū: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ŭ: {
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ŭ: {
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ů: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ů: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ű: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ű: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ų: {
                title: 0.064022675,
                subtitle: 0.03637206,
                spine: 0.23459736,
            },
            ų: {
                title: 0.045730483,
                subtitle: 0.030874606,
                spine: 0.2003779,
            },
            Ŵ: {
                title: 0.09402187,
                subtitle: 0.044423897,
                spine: 0.2868085,
            },
            ŵ: {
                title: 0.068687186,
                subtitle: 0.041536346,
                spine: 0.27375573,
            },
            Ŷ: {
                title: 0.058535017,
                subtitle: 0.027820466,
                spine: 0.18520842,
            },
            ŷ: {
                title: 0.042254966,
                subtitle: 0.027487285,
                spine: 0.18379733,
            },
            Ÿ: {
                title: 0.058535017,
                subtitle: 0.027820466,
                spine: 0.18520842,
            },
            Ź: {
                title: 0.06539458,
                subtitle: 0.02998613,
                spine: 0.19085288,
            },
            ź: {
                title: 0.04344396,
                subtitle: 0.024599731,
                spine: 0.16227788,
            },
            Ż: {
                title: 0.06539458,
                subtitle: 0.02998613,
                spine: 0.19085288,
            },
            ż: {
                title: 0.04344396,
                subtitle: 0.024599731,
                spine: 0.16227788,
            },
            Ž: {
                title: 0.06539458,
                subtitle: 0.02998613,
                spine: 0.19085288,
            },
            ž: {
                title: 0.04344396,
                subtitle: 0.024599731,
                spine: 0.16227788,
            },
            ſ: {},
        },
    },
    LARGE_FOLDED: {
        value: {
            ' ': {
                title: 0.02911347,
                subtitle: 0.011883048,
                spine: 0.010779328,
            },
            '!': {
                title: 0.0187158,
                subtitle: 0.0187158,
                spine: 0.018324858,
            },
            '"': {
                title: 0.0387833,
                subtitle: 0.028638147,
                spine: 0.028942496,
            },
            '#': {
                title: 0.067584835,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            $: {
                title: 0.054691732,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '%': {
                title: 0.08058192,
                subtitle: 0.04996822,
                spine: 0.04618942,
            },
            '&': {
                title: 0.07798251,
                subtitle: 0.03796634,
                spine: 0.03594906,
            },
            "'": {
                title: 0.016636267,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            '(': {
                title: 0.030881071,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            ')': {
                title: 0.030881071,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            '*': {
                title: 0.057291146,
                subtitle: 0.026023876,
                spine: 0.024630764,
            },
            '+': {
                title: 0.063841686,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            ',': {
                title: 0.019443639,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            '-': {
                title: 0.03847137,
                subtitle: 0.019131707,
                spine: 0.017893683,
            },
            '.': {
                title: 0.0187158,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            '/': {
                title: 0.02339475,
                subtitle: 0.020438844,
                spine: 0.01827096,
            },
            '0': {
                title: 0.059058752,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '1': {
                title: 0.03306458,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '2': {
                title: 0.05479571,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '3': {
                title: 0.05084459,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '4': {
                title: 0.05230027,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '5': {
                title: 0.052924126,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '6': {
                title: 0.055939447,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '7': {
                title: 0.04938892,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '8': {
                title: 0.05822694,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '9': {
                title: 0.056043424,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            ':': {
                title: 0.0187158,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            ';': {
                title: 0.019443639,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            '<': {
                title: 0.0637377,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '=': {
                title: 0.0637377,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '>': {
                title: 0.0637377,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '?': {
                title: 0.03909523,
                subtitle: 0.026380368,
                spine: 0.024954144,
            },
            '@': {
                title: 0.10054544,
                subtitle: 0.051988337,
                spine: 0.048668664,
            },
            A: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            B: {
                title: 0.061450217,
                subtitle: 0.0354709,
                spine: 0.032607466,
            },
            C: {
                title: 0.073095605,
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            D: {
                title: 0.07600695,
                subtitle: 0.03713453,
                spine: 0.034224365,
            },
            E: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            F: {
                title: 0.051468454,
                subtitle: 0.030301774,
                spine: 0.02824184,
            },
            G: {
                title: 0.075591035,
                subtitle: 0.037312772,
                spine: 0.034386057,
            },
            H: {
                title: 0.07257572,
                subtitle: 0.039392304,
                spine: 0.036326338,
            },
            I: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            J: {
                title: 0.030257214,
                subtitle: 0.029351128,
                spine: 0.02743339,
            },
            K: {
                title: 0.06841665,
                subtitle: 0.0354709,
                spine: 0.033092536,
            },
            L: {
                title: 0.052508216,
                subtitle: 0.02982645,
                spine: 0.027918458,
            },
            M: {
                title: 0.079022266,
                subtitle: 0.044264358,
                spine: 0.04106924,
            },
            N: {
                title: 0.079022266,
                subtitle: 0.0389764,
                spine: 0.035841264,
            },
            O: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            P: {
                title: 0.05562752,
                subtitle: 0.034579672,
                spine: 0.0321224,
            },
            Q: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            R: {
                title: 0.06342577,
                subtitle: 0.035173822,
                spine: 0.03303864,
            },
            S: {
                title: 0.051572427,
                subtitle: 0.032381307,
                spine: 0.029966531,
            },
            T: {
                title: 0.06654507,
                subtitle: 0.032440722,
                spine: 0.029966531,
            },
            U: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            V: {
                title: 0.067168936,
                subtitle: 0.03184657,
                spine: 0.029966531,
            },
            W: {
                title: 0.106888026,
                subtitle: 0.047532193,
                spine: 0.043817967,
            },
            X: {
                title: 0.0789183,
                subtitle: 0.032143645,
                spine: 0.030559393,
            },
            Y: {
                title: 0.06654507,
                subtitle: 0.029767036,
                spine: 0.028295733,
            },
            Z: {
                title: 0.074343316,
                subtitle: 0.03208423,
                spine: 0.029158082,
            },
            '[': {
                title: 0.0387833,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            '\\': {
                title: 0.02339475,
                subtitle: 0.020438844,
                spine: 0.01827096,
            },
            ']': {
                title: 0.0387833,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            '^': {
                title: 0.053547986,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            _: {
                title: 0.06030647,
                subtitle: 0.029707622,
                spine: 0.02694832,
            },
            '`': {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            a: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            b: {
                title: 0.052508216,
                subtitle: 0.033450782,
                spine: 0.030882774,
            },
            c: {
                title: 0.044917926,
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            d: {
                title: 0.053028103,
                subtitle: 0.033510197,
                spine: 0.030882774,
            },
            e: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            f: {
                title: 0.0325447,
                subtitle: 0.018834632,
                spine: 0.018378753,
            },
            g: {
                title: 0.050012782,
                subtitle: 0.030895924,
                spine: 0.028780803,
            },
            h: {
                title: 0.051988337,
                subtitle: 0.033153705,
                spine: 0.030774979,
            },
            i: {
                title: 0.023602705,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            j: {
                title: 0.023290776,
                subtitle: 0.015626209,
                spine: 0.014983266,
            },
            k: {
                title: 0.053755943,
                subtitle: 0.031014757,
                spine: 0.029535357,
            },
            l: {
                title: 0.021731125,
                subtitle: 0.01610153,
                spine: 0.015414439,
            },
            m: {
                title: 0.081725664,
                subtitle: 0.05008705,
                spine: 0.04618942,
            },
            n: {
                title: 0.051988337,
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            o: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            p: {
                title: 0.053028103,
                subtitle: 0.033510197,
                spine: 0.030882774,
            },
            q: {
                title: 0.053028103,
                subtitle: 0.033510197,
                spine: 0.030882774,
            },
            r: {
                title: 0.042734414,
                subtitle: 0.022161884,
                spine: 0.021450864,
            },
            s: {
                title: 0.04034295,
                subtitle: 0.02560797,
                spine: 0.023876209,
            },
            t: {
                title: 0.0387833,
                subtitle: 0.021448903,
                spine: 0.020642411,
            },
            u: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            v: {
                title: 0.048037227,
                subtitle: 0.029410545,
                spine: 0.028187944,
            },
            w: {
                title: 0.07808648,
                subtitle: 0.0444426,
                spine: 0.041823793,
            },
            x: {
                title: 0.056459334,
                subtitle: 0.028578732,
                spine: 0.027702872,
            },
            y: {
                title: 0.048037227,
                subtitle: 0.029410545,
                spine: 0.02808015,
            },
            z: {
                title: 0.04938892,
                subtitle: 0.026320951,
                spine: 0.024792453,
            },
            '{': {
                title: 0.035144113,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            '|': {
                title: 0.019235685,
                subtitle: 0.015150887,
                spine: 0.014444299,
            },
            '}': {
                title: 0.035144113,
                subtitle: 0.019250538,
                spine: 0.018540444,
            },
            '~': {
                title: 0.05822694,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            ' ': {
                subtitle: 0.011883048,
                spine: 0.010779328,
            },
            '¡': {
                title: 0.0187158,
                subtitle: 0.0187158,
                spine: 0.018324858,
            },
            '¢': {
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '£': {
                title: 0.06394566,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '¤': {
                title: 0.06238601,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '¥': {
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '¦': {
                subtitle: 0.015150887,
                spine: 0.014444299,
            },
            '§': {
                title: 0.045853715,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '¨': {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            '©': {
                title: 0.079854086,
                subtitle: 0.044383183,
                spine: 0.04042248,
            },
            ª: {
                subtitle: 0.020914165,
                spine: 0.019348893,
            },
            '«': {
                title: 0.053963892,
                subtitle: 0.027033936,
                spine: 0.025870385,
            },
            '¬': {
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '­': {
                subtitle: 0.019131707,
                spine: 0.017893683,
            },
            '®': {
                title: 0.079854086,
                subtitle: 0.026320951,
                spine: 0.024900248,
            },
            '¯': {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            '°': {
                title: 0.035144113,
                subtitle: 0.020676503,
                spine: 0.019672275,
            },
            '±': {
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            '²': {
                subtitle: 0.022102471,
                spine: 0.020265134,
            },
            '³': {
                subtitle: 0.022102471,
                spine: 0.020265134,
            },
            '´': {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            µ: {
                subtitle: 0.03434201,
                spine: 0.032014605,
            },
            '¶': {
                subtitle: 0.03558973,
                spine: 0.034278262,
            },
            '·': {
                title: 0.0187158,
                subtitle: 0.016339192,
                spine: 0.016168993,
            },
            '¸': {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            '¹': {
                subtitle: 0.022102471,
                spine: 0.020265134,
            },
            º: {
                subtitle: 0.021924224,
                spine: 0.020103447,
            },
            '»': {
                title: 0.053963892,
                subtitle: 0.027033936,
                spine: 0.025870385,
            },
            '¼': {
                title: 0.07195186,
                subtitle: 0.04723512,
                spine: 0.04360238,
            },
            '½': {
                title: 0.0806859,
                subtitle: 0.049136408,
                spine: 0.045596555,
            },
            '¾': {
                title: 0.0789183,
                subtitle: 0.0479481,
                spine: 0.04408745,
            },
            '¿': {
                title: 0.03909523,
                subtitle: 0.026380368,
                spine: 0.024954144,
            },
            À: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Á: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Â: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Ã: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Ä: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Å: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            Æ: {
                title: 0.09971363,
                subtitle: 0.04955231,
                spine: 0.04554266,
            },
            Ç: {
                title: 0.073095605,
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            È: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            É: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            Ê: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            Ë: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            Ì: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            Í: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            Î: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            Ï: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            Ð: {
                title: 0.07600695,
                subtitle: 0.03856049,
                spine: 0.035571784,
            },
            Ñ: {
                title: 0.079022266,
                subtitle: 0.0389764,
                spine: 0.035841264,
            },
            Ò: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            Ó: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            Ô: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            Õ: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            Ö: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            '×': {
                title: 0.055419568,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            Ø: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            Ù: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            Ú: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            Û: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            Ü: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            Ý: {
                title: 0.06654507,
                subtitle: 0.029767036,
                spine: 0.028295733,
            },
            Þ: {
                subtitle: 0.035649147,
                spine: 0.033254225,
            },
            ß: {
                title: 0.053340036,
                subtitle: 0.035886806,
                spine: 0.034062676,
            },
            à: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            á: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            â: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            ã: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            ä: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            å: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            æ: {
                title: 0.075695015,
                subtitle: 0.046640966,
                spine: 0.042362757,
            },
            ç: {
                title: 0.044917926,
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            è: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            é: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            ê: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            ë: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            ì: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            í: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            î: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            ï: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            ð: {
                title: 0.054483775,
                subtitle: 0.032797214,
                spine: 0.030182118,
            },
            ñ: {
                title: 0.051988337,
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            ò: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            ó: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            ô: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            õ: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            ö: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            '÷': {
                title: 0.0637377,
                subtitle: 0.03048002,
                spine: 0.028457426,
            },
            ø: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            ù: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            ú: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            û: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            ü: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            ý: {
                title: 0.048037227,
                subtitle: 0.029410545,
                spine: 0.02808015,
            },
            þ: {
                subtitle: 0.033510197,
                spine: 0.030882774,
            },
            ÿ: {
                title: 0.048037227,
                subtitle: 0.029410545,
                spine: 0.02808015,
            },
            Ā: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            ā: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            Ă: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            ă: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            Ą: {
                title: 0.07423935,
                subtitle: 0.033153705,
                spine: 0.030882774,
            },
            ą: {
                title: 0.04658155,
                subtitle: 0.030658264,
                spine: 0.028403528,
            },
            Ć: {
                title: 0.073095605,
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            ć: {
                title: 0.044917926,
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            Ĉ: {
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            ĉ: {
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            Ċ: {
                title: 0.073095605,
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            ċ: {
                title: 0.044917926,
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            Č: {
                title: 0.073095605,
                subtitle: 0.034223177,
                spine: 0.031367846,
            },
            č: {
                title: 0.044917926,
                subtitle: 0.027449843,
                spine: 0.02516973,
            },
            Ď: {
                title: 0.07600695,
                subtitle: 0.03713453,
                spine: 0.034224365,
            },
            ď: {
                title: 0.066857,
                subtitle: 0.03529265,
                spine: 0.033092536,
            },
            Đ: {
                subtitle: 0.03856049,
                spine: 0.035571784,
            },
            đ: {
                title: 0.054483775,
                subtitle: 0.033510197,
                spine: 0.030882774,
            },
            Ē: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            ē: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            Ĕ: {
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            ĕ: {
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            Ė: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            ė: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            Ę: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            ę: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            Ě: {
                title: 0.054587748,
                subtitle: 0.0319654,
                spine: 0.029535357,
            },
            ě: {
                title: 0.04938892,
                subtitle: 0.03012353,
                spine: 0.027918458,
            },
            Ĝ: {
                subtitle: 0.037312772,
                spine: 0.034386057,
            },
            ĝ: {
                subtitle: 0.030895924,
                spine: 0.028780803,
            },
            Ğ: {
                title: 0.075591035,
                subtitle: 0.037312772,
                spine: 0.034386057,
            },
            ğ: {
                title: 0.050012782,
                subtitle: 0.030895924,
                spine: 0.028780803,
            },
            Ġ: {
                title: 0.075591035,
                subtitle: 0.037312772,
                spine: 0.034386057,
            },
            ġ: {
                title: 0.050012782,
                subtitle: 0.030895924,
                spine: 0.028780803,
            },
            Ģ: {
                subtitle: 0.037312772,
                spine: 0.034386057,
            },
            ģ: {
                subtitle: 0.030895924,
                spine: 0.028780803,
            },
            Ĥ: {
                subtitle: 0.039392304,
                spine: 0.036326338,
            },
            ĥ: {
                subtitle: 0.033153705,
                spine: 0.030774979,
            },
            Ħ: {
                subtitle: 0.04159067,
                spine: 0.03837441,
            },
            ħ: {
                subtitle: 0.033153705,
                spine: 0.030774979,
            },
            Ĩ: {
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            ĩ: {
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            Ī: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            ī: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            Ĭ: {
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            ĭ: {
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            Į: {
                title: 0.02682598,
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            į: {
                title: 0.023602705,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            İ: {
                subtitle: 0.016755098,
                spine: 0.016222887,
            },
            ı: {
                title: 0.021731125,
                subtitle: 0.015566793,
                spine: 0.0148754725,
            },
            Ĳ: {
                subtitle: 0.03356961,
                spine: 0.032445773,
            },
            ĳ: {
                subtitle: 0.031133587,
                spine: 0.02985874,
            },
            Ĵ: {
                subtitle: 0.029351128,
                spine: 0.02743339,
            },
            ĵ: {
                subtitle: 0.015626209,
                spine: 0.014983266,
            },
            Ķ: {
                subtitle: 0.0354709,
                spine: 0.033092536,
            },
            ķ: {
                subtitle: 0.031014757,
                spine: 0.029535357,
            },
            ĸ: {
                subtitle: 0.031014757,
                spine: 0.029535357,
            },
            Ĺ: {
                title: 0.052508216,
                subtitle: 0.02982645,
                spine: 0.027918458,
            },
            ĺ: {
                title: 0.021731125,
                subtitle: 0.01610153,
                spine: 0.015414439,
            },
            Ļ: {
                subtitle: 0.02982645,
                spine: 0.027918458,
            },
            ļ: {
                subtitle: 0.01610153,
                spine: 0.015414439,
            },
            Ľ: {
                title: 0.052508216,
                subtitle: 0.02982645,
                spine: 0.027918458,
            },
            ľ: {
                title: 0.035456043,
                subtitle: 0.01734925,
                spine: 0.017139131,
            },
            Ŀ: {
                title: 0.052508216,
                subtitle: 0.02982645,
                spine: 0.027918458,
            },
            ŀ: {
                title: 0.03940716,
                subtitle: 0.024241418,
                spine: 0.024415178,
            },
            Ł: {
                title: 0.052508216,
                subtitle: 0.03012353,
                spine: 0.02824184,
            },
            ł: {
                title: 0.027553817,
                subtitle: 0.018359309,
                spine: 0.01827096,
            },
            Ń: {
                title: 0.079022266,
                subtitle: 0.0389764,
                spine: 0.035841264,
            },
            ń: {
                title: 0.051988337,
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            Ņ: {
                subtitle: 0.0389764,
                spine: 0.035841264,
            },
            ņ: {
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            Ň: {
                title: 0.079022266,
                subtitle: 0.0389764,
                spine: 0.035841264,
            },
            ň: {
                title: 0.051988337,
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            ŉ: {
                subtitle: 0.048126347,
                spine: 0.045812145,
            },
            Ŋ: {
                subtitle: 0.038679324,
                spine: 0.03589516,
            },
            ŋ: {
                subtitle: 0.033272535,
                spine: 0.030828878,
            },
            Ō: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            ō: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            Ŏ: {
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            ŏ: {
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            Ő: {
                title: 0.08526087,
                subtitle: 0.040045876,
                spine: 0.0368653,
            },
            ő: {
                title: 0.05760308,
                subtitle: 0.03261897,
                spine: 0.029912634,
            },
            Œ: {
                title: 0.09565854,
                subtitle: 0.051037695,
                spine: 0.046943974,
            },
            œ: {
                title: 0.08879607,
                subtitle: 0.04931465,
                spine: 0.044303037,
            },
            Ŕ: {
                title: 0.06342577,
                subtitle: 0.035173822,
                spine: 0.03303864,
            },
            ŕ: {
                title: 0.042734414,
                subtitle: 0.022161884,
                spine: 0.021450864,
            },
            Ŗ: {
                subtitle: 0.035173822,
                spine: 0.03303864,
            },
            ŗ: {
                subtitle: 0.022161884,
                spine: 0.021450864,
            },
            Ř: {
                title: 0.06342577,
                subtitle: 0.035173822,
                spine: 0.03303864,
            },
            ř: {
                title: 0.042734414,
                subtitle: 0.022161884,
                spine: 0.021450864,
            },
            Ś: {
                title: 0.051572427,
                subtitle: 0.032381307,
                spine: 0.029966531,
            },
            ś: {
                title: 0.04034295,
                subtitle: 0.02560797,
                spine: 0.023876209,
            },
            Ŝ: {
                subtitle: 0.032381307,
                spine: 0.029966531,
            },
            ŝ: {
                subtitle: 0.02560797,
                spine: 0.023876209,
            },
            Ş: {
                title: 0.051572427,
                subtitle: 0.032381307,
                spine: 0.029966531,
            },
            ş: {
                title: 0.04034295,
                subtitle: 0.02560797,
                spine: 0.023876209,
            },
            Š: {
                title: 0.051572427,
                subtitle: 0.032381307,
                spine: 0.029966531,
            },
            š: {
                title: 0.04034295,
                subtitle: 0.02560797,
                spine: 0.023876209,
            },
            Ţ: {
                title: 0.06654507,
                subtitle: 0.032440722,
                spine: 0.029966531,
            },
            ţ: {
                title: 0.039615113,
                subtitle: 0.021448903,
                spine: 0.020642411,
            },
            Ť: {
                title: 0.06654507,
                subtitle: 0.032440722,
                spine: 0.029966531,
            },
            ť: {
                title: 0.05219629,
                subtitle: 0.021448903,
                spine: 0.020642411,
            },
            Ŧ: {
                title: 0.06654507,
                subtitle: 0.032440722,
                spine: 0.029966531,
            },
            ŧ: {
                title: 0.0387833,
                subtitle: 0.021448903,
                spine: 0.020642411,
            },
            Ũ: {
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ũ: {
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ū: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ū: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ŭ: {
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ŭ: {
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ů: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ů: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ű: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ű: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ų: {
                title: 0.07278367,
                subtitle: 0.038916983,
                spine: 0.035841264,
            },
            ų: {
                title: 0.051988337,
                subtitle: 0.033034876,
                spine: 0.030613292,
            },
            Ŵ: {
                title: 0.106888026,
                subtitle: 0.047532193,
                spine: 0.043817967,
            },
            ŵ: {
                title: 0.07808648,
                subtitle: 0.0444426,
                spine: 0.041823793,
            },
            Ŷ: {
                title: 0.06654507,
                subtitle: 0.029767036,
                spine: 0.028295733,
            },
            ŷ: {
                title: 0.048037227,
                subtitle: 0.029410545,
                spine: 0.02808015,
            },
            Ÿ: {
                title: 0.06654507,
                subtitle: 0.029767036,
                spine: 0.028295733,
            },
            Ź: {
                title: 0.074343316,
                subtitle: 0.03208423,
                spine: 0.029158082,
            },
            ź: {
                title: 0.04938892,
                subtitle: 0.026320951,
                spine: 0.024792453,
            },
            Ż: {
                title: 0.074343316,
                subtitle: 0.03208423,
                spine: 0.029158082,
            },
            ż: {
                title: 0.04938892,
                subtitle: 0.026320951,
                spine: 0.024792453,
            },
            Ž: {
                title: 0.074343316,
                subtitle: 0.03208423,
                spine: 0.029158082,
            },
            ž: {
                title: 0.04938892,
                subtitle: 0.026320951,
                spine: 0.024792453,
            },
            ſ: {},
        },
    },
    A3_FOLDED: {
        value: {
            ' ': {
                title: 0.028222239,
                subtitle: 0.0120952455,
                spine: 0.0705556,
            },
            '!': {
                title: 0.018142868,
                subtitle: 0.019050011,
                spine: 0.11994451,
            },
            '"': {
                title: 0.037596054,
                subtitle: 0.02914954,
                spine: 0.18944177,
            },
            '#': {
                title: 0.06551591,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            $: {
                title: 0.053017493,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '%': {
                title: 0.07811513,
                subtitle: 0.050860506,
                spine: 0.30233073,
            },
            '&': {
                title: 0.07559528,
                subtitle: 0.03864431,
                spine: 0.23530291,
            },
            "'": {
                title: 0.016126992,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            '(': {
                title: 0.02993573,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            ')': {
                title: 0.02993573,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            '*': {
                title: 0.055537336,
                subtitle: 0.026488587,
                spine: 0.16121954,
            },
            '+': {
                title: 0.061887342,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            ',': {
                title: 0.018848425,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            '-': {
                title: 0.037293673,
                subtitle: 0.019473344,
                spine: 0.117122285,
            },
            '.': {
                title: 0.018142868,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            '/': {
                title: 0.022678586,
                subtitle: 0.020803824,
                spine: 0.11959173,
            },
            '0': {
                title: 0.05725083,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '1': {
                title: 0.032052398,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '2': {
                title: 0.053118285,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '3': {
                title: 0.049288124,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '4': {
                title: 0.050699238,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '5': {
                title: 0.051304,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '6': {
                title: 0.054227017,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '7': {
                title: 0.047877014,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '8': {
                title: 0.056444477,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '9': {
                title: 0.05432781,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            ':': {
                title: 0.018142868,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            ';': {
                title: 0.018848425,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            '<': {
                title: 0.06178654,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '=': {
                title: 0.06178654,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '>': {
                title: 0.06178654,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '?': {
                title: 0.037898432,
                subtitle: 0.026851445,
                spine: 0.1633362,
            },
            '@': {
                title: 0.09746752,
                subtitle: 0.0529167,
                spine: 0.3185585,
            },
            A: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            B: {
                title: 0.059569087,
                subtitle: 0.036104307,
                spine: 0.21343069,
            },
            C: {
                title: 0.07085798,
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            D: {
                title: 0.0736802,
                subtitle: 0.03779764,
                spine: 0.22401401,
            },
            E: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            F: {
                title: 0.049892884,
                subtitle: 0.030842874,
                spine: 0.18485565,
            },
            G: {
                title: 0.07327703,
                subtitle: 0.03797907,
                spine: 0.22507235,
            },
            H: {
                title: 0.07035401,
                subtitle: 0.04009574,
                spine: 0.23777236,
            },
            I: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            J: {
                title: 0.02933097,
                subtitle: 0.029875256,
                spine: 0.17956398,
            },
            K: {
                title: 0.06632226,
                subtitle: 0.036104307,
                spine: 0.21660568,
            },
            L: {
                title: 0.050900824,
                subtitle: 0.030359063,
                spine: 0.18273899,
            },
            M: {
                title: 0.07660322,
                subtitle: 0.04505479,
                spine: 0.26881683,
            },
            N: {
                title: 0.07660322,
                subtitle: 0.039672405,
                spine: 0.23459736,
            },
            O: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            P: {
                title: 0.05392464,
                subtitle: 0.035197165,
                spine: 0.21025568,
            },
            Q: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            R: {
                title: 0.061484165,
                subtitle: 0.035801925,
                spine: 0.2162529,
            },
            S: {
                title: 0.04999368,
                subtitle: 0.032959543,
                spine: 0.19614455,
            },
            T: {
                title: 0.06450797,
                subtitle: 0.03302002,
                spine: 0.19614455,
            },
            U: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            V: {
                title: 0.06511274,
                subtitle: 0.03241526,
                spine: 0.19614455,
            },
            W: {
                title: 0.10361593,
                subtitle: 0.048380982,
                spine: 0.2868085,
            },
            X: {
                title: 0.07650243,
                subtitle: 0.032717638,
                spine: 0.2000251,
            },
            Y: {
                title: 0.06450797,
                subtitle: 0.03029859,
                spine: 0.18520842,
            },
            Z: {
                title: 0.0720675,
                subtitle: 0.032657165,
                spine: 0.19085288,
            },
            '[': {
                title: 0.037596054,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            '\\': {
                title: 0.022678586,
                subtitle: 0.020803824,
                spine: 0.11959173,
            },
            ']': {
                title: 0.037596054,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            '^': {
                title: 0.051908758,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            _: {
                title: 0.058460347,
                subtitle: 0.030238112,
                spine: 0.176389,
            },
            '`': {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            a: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            b: {
                title: 0.050900824,
                subtitle: 0.034048118,
                spine: 0.20214179,
            },
            c: {
                title: 0.043542884,
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            d: {
                title: 0.051404793,
                subtitle: 0.034108594,
                spine: 0.20214179,
            },
            e: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            f: {
                title: 0.03154843,
                subtitle: 0.019170964,
                spine: 0.12029728,
            },
            g: {
                title: 0.048481774,
                subtitle: 0.031447638,
                spine: 0.18838343,
            },
            h: {
                title: 0.050396856,
                subtitle: 0.033745736,
                spine: 0.20143622,
            },
            i: {
                title: 0.022880172,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            j: {
                title: 0.02257779,
                subtitle: 0.015905248,
                spine: 0.098072276,
            },
            k: {
                title: 0.052110348,
                subtitle: 0.03156859,
                spine: 0.19332232,
            },
            l: {
                title: 0.021065887,
                subtitle: 0.016389057,
                spine: 0.1008945,
            },
            m: {
                title: 0.07922386,
                subtitle: 0.05098146,
                spine: 0.30233073,
            },
            n: {
                title: 0.050396856,
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            o: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            p: {
                title: 0.051404793,
                subtitle: 0.034108594,
                spine: 0.20214179,
            },
            q: {
                title: 0.051404793,
                subtitle: 0.034108594,
                spine: 0.20214179,
            },
            r: {
                title: 0.041426215,
                subtitle: 0.022557631,
                spine: 0.14040564,
            },
            s: {
                title: 0.03910796,
                subtitle: 0.026065253,
                spine: 0.15628064,
            },
            t: {
                title: 0.037596054,
                subtitle: 0.021831917,
                spine: 0.13511395,
            },
            u: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            v: {
                title: 0.04656669,
                subtitle: 0.029935732,
                spine: 0.18450288,
            },
            w: {
                title: 0.07569607,
                subtitle: 0.045236215,
                spine: 0.27375573,
            },
            x: {
                title: 0.05473098,
                subtitle: 0.029089067,
                spine: 0.18132788,
            },
            y: {
                title: 0.04656669,
                subtitle: 0.029935732,
                spine: 0.18379733,
            },
            z: {
                title: 0.047877014,
                subtitle: 0.02679097,
                spine: 0.16227788,
            },
            '{': {
                title: 0.034068275,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            '|': {
                title: 0.018646836,
                subtitle: 0.015421437,
                spine: 0.0945445,
            },
            '}': {
                title: 0.034068275,
                subtitle: 0.019594297,
                spine: 0.12135563,
            },
            '~': {
                title: 0.056444477,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            ' ': {
                subtitle: 0.0120952455,
                spine: 0.0705556,
            },
            '¡': {
                title: 0.018142868,
                subtitle: 0.019050011,
                spine: 0.11994451,
            },
            '¢': {
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '£': {
                title: 0.061988134,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '¤': {
                title: 0.060476225,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '¥': {
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '¦': {
                subtitle: 0.015421437,
                spine: 0.0945445,
            },
            '§': {
                title: 0.04445003,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '¨': {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            '©': {
                title: 0.07740957,
                subtitle: 0.04517574,
                spine: 0.26458347,
            },
            ª: {
                subtitle: 0.021287631,
                spine: 0.1266473,
            },
            '«': {
                title: 0.052311935,
                subtitle: 0.027516684,
                spine: 0.16933343,
            },
            '¬': {
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '­': {
                subtitle: 0.019473344,
                spine: 0.117122285,
            },
            '®': {
                title: 0.07740957,
                subtitle: 0.02679097,
                spine: 0.16298343,
            },
            '¯': {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            '°': {
                title: 0.034068275,
                subtitle: 0.021045726,
                spine: 0.12876396,
            },
            '±': {
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            '²': {
                subtitle: 0.022497157,
                spine: 0.13264452,
            },
            '³': {
                subtitle: 0.022497157,
                spine: 0.13264452,
            },
            '´': {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            µ: {
                subtitle: 0.03495526,
                spine: 0.20955011,
            },
            '¶': {
                subtitle: 0.03622526,
                spine: 0.22436678,
            },
            '·': {
                title: 0.018142868,
                subtitle: 0.016630962,
                spine: 0.1058334,
            },
            '¸': {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            '¹': {
                subtitle: 0.022497157,
                spine: 0.13264452,
            },
            º: {
                subtitle: 0.022315728,
                spine: 0.13158618,
            },
            '»': {
                title: 0.052311935,
                subtitle: 0.027516684,
                spine: 0.16933343,
            },
            '¼': {
                title: 0.06974924,
                subtitle: 0.0480786,
                spine: 0.28539738,
            },
            '½': {
                title: 0.07821592,
                subtitle: 0.050013836,
                spine: 0.29845017,
            },
            '¾': {
                title: 0.07650243,
                subtitle: 0.048804313,
                spine: 0.2885724,
            },
            '¿': {
                title: 0.037898432,
                subtitle: 0.026851445,
                spine: 0.1633362,
            },
            À: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Á: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Â: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Ã: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Ä: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Å: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            Æ: {
                title: 0.09666117,
                subtitle: 0.05043717,
                spine: 0.2980974,
            },
            Ç: {
                title: 0.07085798,
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            È: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            É: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            Ê: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            Ë: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            Ì: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            Í: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            Î: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            Ï: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            Ð: {
                title: 0.0736802,
                subtitle: 0.03924907,
                spine: 0.23283347,
            },
            Ñ: {
                title: 0.07660322,
                subtitle: 0.039672405,
                spine: 0.23459736,
            },
            Ò: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            Ó: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            Ô: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            Õ: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            Ö: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            '×': {
                title: 0.05372305,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            Ø: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            Ù: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            Ú: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            Û: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            Ü: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            Ý: {
                title: 0.06450797,
                subtitle: 0.03029859,
                spine: 0.18520842,
            },
            Þ: {
                subtitle: 0.036285736,
                spine: 0.217664,
            },
            ß: {
                title: 0.051707175,
                subtitle: 0.036527637,
                spine: 0.22295569,
            },
            à: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            á: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            â: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            ã: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            ä: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            å: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            æ: {
                title: 0.07337782,
                subtitle: 0.047473837,
                spine: 0.2772835,
            },
            ç: {
                title: 0.043542884,
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            è: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            é: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            ê: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            ë: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            ì: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            í: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            î: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            ï: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            ð: {
                title: 0.052815903,
                subtitle: 0.033382874,
                spine: 0.19755566,
            },
            ñ: {
                title: 0.050396856,
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            ò: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            ó: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            ô: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            õ: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            ö: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            '÷': {
                title: 0.06178654,
                subtitle: 0.031024305,
                spine: 0.18626676,
            },
            ø: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            ù: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            ú: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            û: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            ü: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            ý: {
                title: 0.04656669,
                subtitle: 0.029935732,
                spine: 0.18379733,
            },
            þ: {
                subtitle: 0.034108594,
                spine: 0.20214179,
            },
            ÿ: {
                title: 0.04656669,
                subtitle: 0.029935732,
                spine: 0.18379733,
            },
            Ā: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            ā: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            Ă: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            ă: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            Ą: {
                title: 0.07196671,
                subtitle: 0.033745736,
                spine: 0.20214179,
            },
            ą: {
                title: 0.04515558,
                subtitle: 0.03120573,
                spine: 0.185914,
            },
            Ć: {
                title: 0.07085798,
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            ć: {
                title: 0.043542884,
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            Ĉ: {
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            ĉ: {
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            Ċ: {
                title: 0.07085798,
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            ċ: {
                title: 0.043542884,
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            Č: {
                title: 0.07085798,
                subtitle: 0.034834307,
                spine: 0.20531678,
            },
            č: {
                title: 0.043542884,
                subtitle: 0.027940018,
                spine: 0.16474731,
            },
            Ď: {
                title: 0.0736802,
                subtitle: 0.03779764,
                spine: 0.22401401,
            },
            ď: {
                title: 0.06481036,
                subtitle: 0.035922877,
                spine: 0.21660568,
            },
            Đ: {
                subtitle: 0.03924907,
                spine: 0.23283347,
            },
            đ: {
                title: 0.052815903,
                subtitle: 0.034108594,
                spine: 0.20214179,
            },
            Ē: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            ē: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            Ĕ: {
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            ĕ: {
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            Ė: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            ė: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            Ę: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            ę: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            Ě: {
                title: 0.0529167,
                subtitle: 0.03253621,
                spine: 0.19332232,
            },
            ě: {
                title: 0.047877014,
                subtitle: 0.030661449,
                spine: 0.18273899,
            },
            Ĝ: {
                subtitle: 0.03797907,
                spine: 0.22507235,
            },
            ĝ: {
                subtitle: 0.031447638,
                spine: 0.18838343,
            },
            Ğ: {
                title: 0.07327703,
                subtitle: 0.03797907,
                spine: 0.22507235,
            },
            ğ: {
                title: 0.048481774,
                subtitle: 0.031447638,
                spine: 0.18838343,
            },
            Ġ: {
                title: 0.07327703,
                subtitle: 0.03797907,
                spine: 0.22507235,
            },
            ġ: {
                title: 0.048481774,
                subtitle: 0.031447638,
                spine: 0.18838343,
            },
            Ģ: {
                subtitle: 0.03797907,
                spine: 0.22507235,
            },
            ģ: {
                subtitle: 0.031447638,
                spine: 0.18838343,
            },
            Ĥ: {
                subtitle: 0.04009574,
                spine: 0.23777236,
            },
            ĥ: {
                subtitle: 0.033745736,
                spine: 0.20143622,
            },
            Ħ: {
                subtitle: 0.042333357,
                spine: 0.25117794,
            },
            ħ: {
                subtitle: 0.033745736,
                spine: 0.20143622,
            },
            Ĩ: {
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            ĩ: {
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            Ī: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            ī: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            Ĭ: {
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            ĭ: {
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            Į: {
                title: 0.026004776,
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            į: {
                title: 0.022880172,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            İ: {
                subtitle: 0.017054297,
                spine: 0.106186174,
            },
            ı: {
                title: 0.021065887,
                subtitle: 0.015844772,
                spine: 0.09736672,
            },
            Ĳ: {
                subtitle: 0.034169067,
                spine: 0.21237235,
            },
            ĳ: {
                subtitle: 0.031689543,
                spine: 0.19543901,
            },
            Ĵ: {
                subtitle: 0.029875256,
                spine: 0.17956398,
            },
            ĵ: {
                subtitle: 0.015905248,
                spine: 0.098072276,
            },
            Ķ: {
                subtitle: 0.036104307,
                spine: 0.21660568,
            },
            ķ: {
                subtitle: 0.03156859,
                spine: 0.19332232,
            },
            ĸ: {
                subtitle: 0.03156859,
                spine: 0.19332232,
            },
            Ĺ: {
                title: 0.050900824,
                subtitle: 0.030359063,
                spine: 0.18273899,
            },
            ĺ: {
                title: 0.021065887,
                subtitle: 0.016389057,
                spine: 0.1008945,
            },
            Ļ: {
                subtitle: 0.030359063,
                spine: 0.18273899,
            },
            ļ: {
                subtitle: 0.016389057,
                spine: 0.1008945,
            },
            Ľ: {
                title: 0.050900824,
                subtitle: 0.030359063,
                spine: 0.18273899,
            },
            ľ: {
                title: 0.034370653,
                subtitle: 0.017659057,
                spine: 0.11218339,
            },
            Ŀ: {
                title: 0.050900824,
                subtitle: 0.030359063,
                spine: 0.18273899,
            },
            ŀ: {
                title: 0.038200814,
                subtitle: 0.0246743,
                spine: 0.15980843,
            },
            Ł: {
                title: 0.050900824,
                subtitle: 0.030661449,
                spine: 0.18485565,
            },
            ł: {
                title: 0.026710331,
                subtitle: 0.018687153,
                spine: 0.11959173,
            },
            Ń: {
                title: 0.07660322,
                subtitle: 0.039672405,
                spine: 0.23459736,
            },
            ń: {
                title: 0.050396856,
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            Ņ: {
                subtitle: 0.039672405,
                spine: 0.23459736,
            },
            ņ: {
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            Ň: {
                title: 0.07660322,
                subtitle: 0.039672405,
                spine: 0.23459736,
            },
            ň: {
                title: 0.050396856,
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            ŉ: {
                subtitle: 0.048985746,
                spine: 0.29986128,
            },
            Ŋ: {
                subtitle: 0.039370026,
                spine: 0.23495014,
            },
            ŋ: {
                subtitle: 0.03386669,
                spine: 0.201789,
            },
            Ō: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            ō: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            Ŏ: {
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            ŏ: {
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            Ő: {
                title: 0.08265084,
                subtitle: 0.04076098,
                spine: 0.24130014,
            },
            ő: {
                title: 0.055839714,
                subtitle: 0.03320145,
                spine: 0.19579178,
            },
            Œ: {
                title: 0.09273021,
                subtitle: 0.05194908,
                spine: 0.3072696,
            },
            œ: {
                title: 0.08607783,
                subtitle: 0.05019527,
                spine: 0.2899835,
            },
            Ŕ: {
                title: 0.061484165,
                subtitle: 0.035801925,
                spine: 0.2162529,
            },
            ŕ: {
                title: 0.041426215,
                subtitle: 0.022557631,
                spine: 0.14040564,
            },
            Ŗ: {
                subtitle: 0.035801925,
                spine: 0.2162529,
            },
            ŗ: {
                subtitle: 0.022557631,
                spine: 0.14040564,
            },
            Ř: {
                title: 0.061484165,
                subtitle: 0.035801925,
                spine: 0.2162529,
            },
            ř: {
                title: 0.041426215,
                subtitle: 0.022557631,
                spine: 0.14040564,
            },
            Ś: {
                title: 0.04999368,
                subtitle: 0.032959543,
                spine: 0.19614455,
            },
            ś: {
                title: 0.03910796,
                subtitle: 0.026065253,
                spine: 0.15628064,
            },
            Ŝ: {
                subtitle: 0.032959543,
                spine: 0.19614455,
            },
            ŝ: {
                subtitle: 0.026065253,
                spine: 0.15628064,
            },
            Ş: {
                title: 0.04999368,
                subtitle: 0.032959543,
                spine: 0.19614455,
            },
            ş: {
                title: 0.03910796,
                subtitle: 0.026065253,
                spine: 0.15628064,
            },
            Š: {
                title: 0.04999368,
                subtitle: 0.032959543,
                spine: 0.19614455,
            },
            š: {
                title: 0.03910796,
                subtitle: 0.026065253,
                spine: 0.15628064,
            },
            Ţ: {
                title: 0.06450797,
                subtitle: 0.03302002,
                spine: 0.19614455,
            },
            ţ: {
                title: 0.038402405,
                subtitle: 0.021831917,
                spine: 0.13511395,
            },
            Ť: {
                title: 0.06450797,
                subtitle: 0.03302002,
                spine: 0.19614455,
            },
            ť: {
                title: 0.05059844,
                subtitle: 0.021831917,
                spine: 0.13511395,
            },
            Ŧ: {
                title: 0.06450797,
                subtitle: 0.03302002,
                spine: 0.19614455,
            },
            ŧ: {
                title: 0.037596054,
                subtitle: 0.021831917,
                spine: 0.13511395,
            },
            Ũ: {
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ũ: {
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ū: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ū: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ŭ: {
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ŭ: {
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ů: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ů: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ű: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ű: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ų: {
                title: 0.0705556,
                subtitle: 0.03961193,
                spine: 0.23459736,
            },
            ų: {
                title: 0.050396856,
                subtitle: 0.033624783,
                spine: 0.2003779,
            },
            Ŵ: {
                title: 0.10361593,
                subtitle: 0.048380982,
                spine: 0.2868085,
            },
            ŵ: {
                title: 0.07569607,
                subtitle: 0.045236215,
                spine: 0.27375573,
            },
            Ŷ: {
                title: 0.06450797,
                subtitle: 0.03029859,
                spine: 0.18520842,
            },
            ŷ: {
                title: 0.04656669,
                subtitle: 0.029935732,
                spine: 0.18379733,
            },
            Ÿ: {
                title: 0.06450797,
                subtitle: 0.03029859,
                spine: 0.18520842,
            },
            Ź: {
                title: 0.0720675,
                subtitle: 0.032657165,
                spine: 0.19085288,
            },
            ź: {
                title: 0.047877014,
                subtitle: 0.02679097,
                spine: 0.16227788,
            },
            Ż: {
                title: 0.0720675,
                subtitle: 0.032657165,
                spine: 0.19085288,
            },
            ż: {
                title: 0.047877014,
                subtitle: 0.02679097,
                spine: 0.16227788,
            },
            Ž: {
                title: 0.0720675,
                subtitle: 0.032657165,
                spine: 0.19085288,
            },
            ž: {
                title: 0.047877014,
                subtitle: 0.02679097,
                spine: 0.16227788,
            },
            ſ: {},
        },
    },
    A3_FLAT: {
        value: {
            ' ': {
                title: 0.009877783,
                subtitle: 0.006350004,
                spine: 0.0705556,
            },
            '!': {
                title: 0.0063500036,
                subtitle: 0.010001256,
                spine: 0.11994451,
            },
            '"': {
                title: 0.0131586185,
                subtitle: 0.015303509,
                spine: 0.18944177,
            },
            '#': {
                title: 0.022930568,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            $: {
                title: 0.018556122,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '%': {
                title: 0.027340293,
                subtitle: 0.026701767,
                spine: 0.30233073,
            },
            '&': {
                title: 0.02645835,
                subtitle: 0.020288263,
                spine: 0.23530291,
            },
            "'": {
                title: 0.0056444476,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            '(': {
                title: 0.010477506,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            ')': {
                title: 0.010477506,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            '*': {
                title: 0.019438066,
                subtitle: 0.013906509,
                spine: 0.16121954,
            },
            '+': {
                title: 0.021660568,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            ',': {
                title: 0.0065969485,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            '-': {
                title: 0.013052785,
                subtitle: 0.010223507,
                spine: 0.117122285,
            },
            '.': {
                title: 0.0063500036,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            '/': {
                title: 0.007937505,
                subtitle: 0.010922007,
                spine: 0.11959173,
            },
            '0': {
                title: 0.02003779,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '1': {
                title: 0.011218339,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '2': {
                title: 0.0185914,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '3': {
                title: 0.017250843,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '4': {
                title: 0.017744733,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '5': {
                title: 0.0179564,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '6': {
                title: 0.018979454,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '7': {
                title: 0.016756954,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '8': {
                title: 0.019755566,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '9': {
                title: 0.019014733,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            ':': {
                title: 0.0063500036,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            ';': {
                title: 0.0065969485,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            '<': {
                title: 0.02162529,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '=': {
                title: 0.02162529,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '>': {
                title: 0.02162529,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '?': {
                title: 0.013264451,
                subtitle: 0.014097009,
                spine: 0.1633362,
            },
            '@': {
                title: 0.03411363,
                subtitle: 0.027781269,
                spine: 0.3185585,
            },
            A: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            B: {
                title: 0.02084918,
                subtitle: 0.018954763,
                spine: 0.21343069,
            },
            C: {
                title: 0.024800293,
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            D: {
                title: 0.02578807,
                subtitle: 0.019843763,
                spine: 0.22401401,
            },
            E: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            F: {
                title: 0.01746251,
                subtitle: 0.01619251,
                spine: 0.18485565,
            },
            G: {
                title: 0.025646959,
                subtitle: 0.019939015,
                spine: 0.22507235,
            },
            H: {
                title: 0.024623904,
                subtitle: 0.021050263,
                spine: 0.23777236,
            },
            I: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            J: {
                title: 0.010265839,
                subtitle: 0.01568451,
                spine: 0.17956398,
            },
            K: {
                title: 0.023212792,
                subtitle: 0.018954763,
                spine: 0.21660568,
            },
            L: {
                title: 0.017815288,
                subtitle: 0.01593851,
                spine: 0.18273899,
            },
            M: {
                title: 0.026811127,
                subtitle: 0.023653764,
                spine: 0.26881683,
            },
            N: {
                title: 0.026811127,
                subtitle: 0.020828014,
                spine: 0.23459736,
            },
            O: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            P: {
                title: 0.018873623,
                subtitle: 0.01847851,
                spine: 0.21025568,
            },
            Q: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            R: {
                title: 0.021519456,
                subtitle: 0.018796012,
                spine: 0.2162529,
            },
            S: {
                title: 0.017497787,
                subtitle: 0.017303761,
                spine: 0.19614455,
            },
            T: {
                title: 0.02257779,
                subtitle: 0.017335512,
                spine: 0.19614455,
            },
            U: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            V: {
                title: 0.02278946,
                subtitle: 0.017018013,
                spine: 0.19614455,
            },
            W: {
                title: 0.036265578,
                subtitle: 0.025400016,
                spine: 0.2868085,
            },
            X: {
                title: 0.02677585,
                subtitle: 0.01717676,
                spine: 0.2000251,
            },
            Y: {
                title: 0.02257779,
                subtitle: 0.015906759,
                spine: 0.18520842,
            },
            Z: {
                title: 0.025223626,
                subtitle: 0.017145012,
                spine: 0.19085288,
            },
            '[': {
                title: 0.0131586185,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            '\\': {
                title: 0.007937505,
                subtitle: 0.010922007,
                spine: 0.11959173,
            },
            ']': {
                title: 0.0131586185,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            '^': {
                title: 0.018168066,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            _: {
                title: 0.020461123,
                subtitle: 0.01587501,
                spine: 0.176389,
            },
            '`': {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            a: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            b: {
                title: 0.017815288,
                subtitle: 0.017875263,
                spine: 0.20214179,
            },
            c: {
                title: 0.015240009,
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            d: {
                title: 0.017991677,
                subtitle: 0.017907012,
                spine: 0.20214179,
            },
            e: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            f: {
                title: 0.01104195,
                subtitle: 0.0100647565,
                spine: 0.12029728,
            },
            g: {
                title: 0.016968621,
                subtitle: 0.01651001,
                spine: 0.18838343,
            },
            h: {
                title: 0.0176389,
                subtitle: 0.017716512,
                spine: 0.20143622,
            },
            i: {
                title: 0.00800806,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            j: {
                title: 0.007902227,
                subtitle: 0.008350256,
                spine: 0.098072276,
            },
            k: {
                title: 0.018238623,
                subtitle: 0.016573511,
                spine: 0.19332232,
            },
            l: {
                title: 0.00737306,
                subtitle: 0.0086042555,
                spine: 0.1008945,
            },
            m: {
                title: 0.027728349,
                subtitle: 0.026765266,
                spine: 0.30233073,
            },
            n: {
                title: 0.0176389,
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            o: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            p: {
                title: 0.017991677,
                subtitle: 0.017907012,
                spine: 0.20214179,
            },
            q: {
                title: 0.017991677,
                subtitle: 0.017907012,
                spine: 0.20214179,
            },
            r: {
                title: 0.014499175,
                subtitle: 0.011842757,
                spine: 0.14040564,
            },
            s: {
                title: 0.013687787,
                subtitle: 0.013684259,
                spine: 0.15628064,
            },
            t: {
                title: 0.0131586185,
                subtitle: 0.011461757,
                spine: 0.13511395,
            },
            u: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            v: {
                title: 0.016298342,
                subtitle: 0.01571626,
                spine: 0.18450288,
            },
            w: {
                title: 0.026493626,
                subtitle: 0.023749016,
                spine: 0.27375573,
            },
            x: {
                title: 0.019155843,
                subtitle: 0.01527176,
                spine: 0.18132788,
            },
            y: {
                title: 0.016298342,
                subtitle: 0.01571626,
                spine: 0.18379733,
            },
            z: {
                title: 0.016756954,
                subtitle: 0.014065259,
                spine: 0.16227788,
            },
            '{': {
                title: 0.011923895,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            '|': {
                title: 0.0065263924,
                subtitle: 0.008096255,
                spine: 0.0945445,
            },
            '}': {
                title: 0.011923895,
                subtitle: 0.010287006,
                spine: 0.12135563,
            },
            '~': {
                title: 0.019755566,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            ' ': {
                subtitle: 0.006350004,
                spine: 0.0705556,
            },
            '¡': {
                title: 0.0063500036,
                subtitle: 0.010001256,
                spine: 0.11994451,
            },
            '¢': {
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '£': {
                title: 0.021695847,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '¤': {
                title: 0.02116668,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '¥': {
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '¦': {
                subtitle: 0.008096255,
                spine: 0.0945445,
            },
            '§': {
                title: 0.01555751,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '¨': {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            '©': {
                title: 0.027093349,
                subtitle: 0.023717264,
                spine: 0.26458347,
            },
            ª: {
                subtitle: 0.011176007,
                spine: 0.1266473,
            },
            '«': {
                title: 0.018309176,
                subtitle: 0.01444626,
                spine: 0.16933343,
            },
            '¬': {
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '­': {
                subtitle: 0.010223507,
                spine: 0.117122285,
            },
            '®': {
                title: 0.027093349,
                subtitle: 0.014065259,
                spine: 0.16298343,
            },
            '¯': {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            '°': {
                title: 0.011923895,
                subtitle: 0.011049007,
                spine: 0.12876396,
            },
            '±': {
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            '²': {
                subtitle: 0.011811008,
                spine: 0.13264452,
            },
            '³': {
                subtitle: 0.011811008,
                spine: 0.13264452,
            },
            '´': {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            µ: {
                subtitle: 0.018351512,
                spine: 0.20955011,
            },
            '¶': {
                subtitle: 0.01901826,
                spine: 0.22436678,
            },
            '·': {
                title: 0.0063500036,
                subtitle: 0.008731256,
                spine: 0.1058334,
            },
            '¸': {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            '¹': {
                subtitle: 0.011811008,
                spine: 0.13264452,
            },
            º: {
                subtitle: 0.011715757,
                spine: 0.13158618,
            },
            '»': {
                title: 0.018309176,
                subtitle: 0.01444626,
                spine: 0.16933343,
            },
            '¼': {
                title: 0.024412235,
                subtitle: 0.025241267,
                spine: 0.28539738,
            },
            '½': {
                title: 0.027375573,
                subtitle: 0.026257267,
                spine: 0.29845017,
            },
            '¾': {
                title: 0.02677585,
                subtitle: 0.025622267,
                spine: 0.2885724,
            },
            '¿': {
                title: 0.013264451,
                subtitle: 0.014097009,
                spine: 0.1633362,
            },
            À: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Á: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Â: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Ã: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Ä: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Å: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            Æ: {
                title: 0.03383141,
                subtitle: 0.026479516,
                spine: 0.2980974,
            },
            Ç: {
                title: 0.024800293,
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            È: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            É: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            Ê: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            Ë: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            Ì: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            Í: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            Î: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            Ï: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            Ð: {
                title: 0.02578807,
                subtitle: 0.020605763,
                spine: 0.23283347,
            },
            Ñ: {
                title: 0.026811127,
                subtitle: 0.020828014,
                spine: 0.23459736,
            },
            Ò: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            Ó: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            Ô: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            Õ: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            Ö: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            '×': {
                title: 0.018803066,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            Ø: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            Ù: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            Ú: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            Û: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            Ü: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            Ý: {
                title: 0.02257779,
                subtitle: 0.015906759,
                spine: 0.18520842,
            },
            Þ: {
                subtitle: 0.019050013,
                spine: 0.217664,
            },
            ß: {
                title: 0.01809751,
                subtitle: 0.019177012,
                spine: 0.22295569,
            },
            à: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            á: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            â: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            ã: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            ä: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            å: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            æ: {
                title: 0.025682235,
                subtitle: 0.024923766,
                spine: 0.2772835,
            },
            ç: {
                title: 0.015240009,
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            è: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            é: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            ê: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            ë: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            ì: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            í: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            î: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            ï: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            ð: {
                title: 0.018485565,
                subtitle: 0.01752601,
                spine: 0.19755566,
            },
            ñ: {
                title: 0.0176389,
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            ò: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            ó: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            ô: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            õ: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            ö: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            '÷': {
                title: 0.02162529,
                subtitle: 0.01628776,
                spine: 0.18626676,
            },
            ø: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            ù: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            ú: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            û: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            ü: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            ý: {
                title: 0.016298342,
                subtitle: 0.01571626,
                spine: 0.18379733,
            },
            þ: {
                subtitle: 0.017907012,
                spine: 0.20214179,
            },
            ÿ: {
                title: 0.016298342,
                subtitle: 0.01571626,
                spine: 0.18379733,
            },
            Ā: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            ā: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            Ă: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            ă: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            Ą: {
                title: 0.02518835,
                subtitle: 0.017716512,
                spine: 0.20214179,
            },
            ą: {
                title: 0.015804455,
                subtitle: 0.016383009,
                spine: 0.185914,
            },
            Ć: {
                title: 0.024800293,
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            ć: {
                title: 0.015240009,
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            Ĉ: {
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            ĉ: {
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            Ċ: {
                title: 0.024800293,
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            ċ: {
                title: 0.015240009,
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            Č: {
                title: 0.024800293,
                subtitle: 0.018288013,
                spine: 0.20531678,
            },
            č: {
                title: 0.015240009,
                subtitle: 0.014668509,
                spine: 0.16474731,
            },
            Ď: {
                title: 0.02578807,
                subtitle: 0.019843763,
                spine: 0.22401401,
            },
            ď: {
                title: 0.022683626,
                subtitle: 0.018859511,
                spine: 0.21660568,
            },
            Đ: {
                subtitle: 0.020605763,
                spine: 0.23283347,
            },
            đ: {
                title: 0.018485565,
                subtitle: 0.017907012,
                spine: 0.20214179,
            },
            Ē: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            ē: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            Ĕ: {
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            ĕ: {
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            Ė: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            ė: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            Ę: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            ę: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            Ě: {
                title: 0.018520843,
                subtitle: 0.01708151,
                spine: 0.19332232,
            },
            ě: {
                title: 0.016756954,
                subtitle: 0.01609726,
                spine: 0.18273899,
            },
            Ĝ: {
                subtitle: 0.019939015,
                spine: 0.22507235,
            },
            ĝ: {
                subtitle: 0.01651001,
                spine: 0.18838343,
            },
            Ğ: {
                title: 0.025646959,
                subtitle: 0.019939015,
                spine: 0.22507235,
            },
            ğ: {
                title: 0.016968621,
                subtitle: 0.01651001,
                spine: 0.18838343,
            },
            Ġ: {
                title: 0.025646959,
                subtitle: 0.019939015,
                spine: 0.22507235,
            },
            ġ: {
                title: 0.016968621,
                subtitle: 0.01651001,
                spine: 0.18838343,
            },
            Ģ: {
                subtitle: 0.019939015,
                spine: 0.22507235,
            },
            ģ: {
                subtitle: 0.01651001,
                spine: 0.18838343,
            },
            Ĥ: {
                subtitle: 0.021050263,
                spine: 0.23777236,
            },
            ĥ: {
                subtitle: 0.017716512,
                spine: 0.20143622,
            },
            Ħ: {
                subtitle: 0.022225013,
                spine: 0.25117794,
            },
            ħ: {
                subtitle: 0.017716512,
                spine: 0.20143622,
            },
            Ĩ: {
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            ĩ: {
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            Ī: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            ī: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            Ĭ: {
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            ĭ: {
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            Į: {
                title: 0.009101671,
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            į: {
                title: 0.00800806,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            İ: {
                subtitle: 0.008953506,
                spine: 0.106186174,
            },
            ı: {
                title: 0.00737306,
                subtitle: 0.008318505,
                spine: 0.09736672,
            },
            Ĳ: {
                subtitle: 0.017938761,
                spine: 0.21237235,
            },
            ĳ: {
                subtitle: 0.01663701,
                spine: 0.19543901,
            },
            Ĵ: {
                subtitle: 0.01568451,
                spine: 0.17956398,
            },
            ĵ: {
                subtitle: 0.008350256,
                spine: 0.098072276,
            },
            Ķ: {
                subtitle: 0.018954763,
                spine: 0.21660568,
            },
            ķ: {
                subtitle: 0.016573511,
                spine: 0.19332232,
            },
            ĸ: {
                subtitle: 0.016573511,
                spine: 0.19332232,
            },
            Ĺ: {
                title: 0.017815288,
                subtitle: 0.01593851,
                spine: 0.18273899,
            },
            ĺ: {
                title: 0.00737306,
                subtitle: 0.0086042555,
                spine: 0.1008945,
            },
            Ļ: {
                subtitle: 0.01593851,
                spine: 0.18273899,
            },
            ļ: {
                subtitle: 0.0086042555,
                spine: 0.1008945,
            },
            Ľ: {
                title: 0.017815288,
                subtitle: 0.01593851,
                spine: 0.18273899,
            },
            ľ: {
                title: 0.012029729,
                subtitle: 0.009271006,
                spine: 0.11218339,
            },
            Ŀ: {
                title: 0.017815288,
                subtitle: 0.01593851,
                spine: 0.18273899,
            },
            ŀ: {
                title: 0.013370286,
                subtitle: 0.012954008,
                spine: 0.15980843,
            },
            Ł: {
                title: 0.017815288,
                subtitle: 0.01609726,
                spine: 0.18485565,
            },
            ł: {
                title: 0.009348616,
                subtitle: 0.009810756,
                spine: 0.11959173,
            },
            Ń: {
                title: 0.026811127,
                subtitle: 0.020828014,
                spine: 0.23459736,
            },
            ń: {
                title: 0.0176389,
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            Ņ: {
                subtitle: 0.020828014,
                spine: 0.23459736,
            },
            ņ: {
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            Ň: {
                title: 0.026811127,
                subtitle: 0.020828014,
                spine: 0.23459736,
            },
            ň: {
                title: 0.0176389,
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            ŉ: {
                subtitle: 0.025717515,
                spine: 0.29986128,
            },
            Ŋ: {
                subtitle: 0.020669265,
                spine: 0.23495014,
            },
            ŋ: {
                subtitle: 0.017780012,
                spine: 0.201789,
            },
            Ō: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            ō: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            Ŏ: {
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            ŏ: {
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            Ő: {
                title: 0.028927794,
                subtitle: 0.021399513,
                spine: 0.24130014,
            },
            ő: {
                title: 0.019543901,
                subtitle: 0.017430762,
                spine: 0.19579178,
            },
            Œ: {
                title: 0.032455575,
                subtitle: 0.02727327,
                spine: 0.3072696,
            },
            œ: {
                title: 0.030127237,
                subtitle: 0.026352515,
                spine: 0.2899835,
            },
            Ŕ: {
                title: 0.021519456,
                subtitle: 0.018796012,
                spine: 0.2162529,
            },
            ŕ: {
                title: 0.014499175,
                subtitle: 0.011842757,
                spine: 0.14040564,
            },
            Ŗ: {
                subtitle: 0.018796012,
                spine: 0.2162529,
            },
            ŗ: {
                subtitle: 0.011842757,
                spine: 0.14040564,
            },
            Ř: {
                title: 0.021519456,
                subtitle: 0.018796012,
                spine: 0.2162529,
            },
            ř: {
                title: 0.014499175,
                subtitle: 0.011842757,
                spine: 0.14040564,
            },
            Ś: {
                title: 0.017497787,
                subtitle: 0.017303761,
                spine: 0.19614455,
            },
            ś: {
                title: 0.013687787,
                subtitle: 0.013684259,
                spine: 0.15628064,
            },
            Ŝ: {
                subtitle: 0.017303761,
                spine: 0.19614455,
            },
            ŝ: {
                subtitle: 0.013684259,
                spine: 0.15628064,
            },
            Ş: {
                title: 0.017497787,
                subtitle: 0.017303761,
                spine: 0.19614455,
            },
            ş: {
                title: 0.013687787,
                subtitle: 0.013684259,
                spine: 0.15628064,
            },
            Š: {
                title: 0.017497787,
                subtitle: 0.017303761,
                spine: 0.19614455,
            },
            š: {
                title: 0.013687787,
                subtitle: 0.013684259,
                spine: 0.15628064,
            },
            Ţ: {
                title: 0.02257779,
                subtitle: 0.017335512,
                spine: 0.19614455,
            },
            ţ: {
                title: 0.013440842,
                subtitle: 0.011461757,
                spine: 0.13511395,
            },
            Ť: {
                title: 0.02257779,
                subtitle: 0.017335512,
                spine: 0.19614455,
            },
            ť: {
                title: 0.017709453,
                subtitle: 0.011461757,
                spine: 0.13511395,
            },
            Ŧ: {
                title: 0.02257779,
                subtitle: 0.017335512,
                spine: 0.19614455,
            },
            ŧ: {
                title: 0.0131586185,
                subtitle: 0.011461757,
                spine: 0.13511395,
            },
            Ũ: {
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ũ: {
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ū: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ū: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ŭ: {
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ŭ: {
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ů: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ů: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ű: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ű: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ų: {
                title: 0.02469446,
                subtitle: 0.020796262,
                spine: 0.23459736,
            },
            ų: {
                title: 0.0176389,
                subtitle: 0.01765301,
                spine: 0.2003779,
            },
            Ŵ: {
                title: 0.036265578,
                subtitle: 0.025400016,
                spine: 0.2868085,
            },
            ŵ: {
                title: 0.026493626,
                subtitle: 0.023749016,
                spine: 0.27375573,
            },
            Ŷ: {
                title: 0.02257779,
                subtitle: 0.015906759,
                spine: 0.18520842,
            },
            ŷ: {
                title: 0.016298342,
                subtitle: 0.01571626,
                spine: 0.18379733,
            },
            Ÿ: {
                title: 0.02257779,
                subtitle: 0.015906759,
                spine: 0.18520842,
            },
            Ź: {
                title: 0.025223626,
                subtitle: 0.017145012,
                spine: 0.19085288,
            },
            ź: {
                title: 0.016756954,
                subtitle: 0.014065259,
                spine: 0.16227788,
            },
            Ż: {
                title: 0.025223626,
                subtitle: 0.017145012,
                spine: 0.19085288,
            },
            ż: {
                title: 0.016756954,
                subtitle: 0.014065259,
                spine: 0.16227788,
            },
            Ž: {
                title: 0.025223626,
                subtitle: 0.017145012,
                spine: 0.19085288,
            },
            ž: {
                title: 0.016756954,
                subtitle: 0.014065259,
                spine: 0.16227788,
            },
            ſ: {},
        },
    },
    A2_FLAT: {
        value: {
            ' ': {
                title: 0.011524081,
                subtitle: 0.0067733373,
                spine: 0.0705556,
            },
            '!': {
                title: 0.007408338,
                subtitle: 0.010668006,
                spine: 0.11994451,
            },
            '"': {
                title: 0.015351723,
                subtitle: 0.016323743,
                spine: 0.18944177,
            },
            '#': {
                title: 0.02675233,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            $: {
                title: 0.02164881,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '%': {
                title: 0.03189701,
                subtitle: 0.028481884,
                spine: 0.30233073,
            },
            '&': {
                title: 0.030868074,
                subtitle: 0.021640813,
                spine: 0.23530291,
            },
            "'": {
                title: 0.006585189,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            '(': {
                title: 0.012223757,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            ')': {
                title: 0.012223757,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            '*': {
                title: 0.022677746,
                subtitle: 0.014833609,
                spine: 0.16121954,
            },
            '+': {
                title: 0.025270665,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            ',': {
                title: 0.00769644,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            '-': {
                title: 0.01522825,
                subtitle: 0.010905072,
                spine: 0.117122285,
            },
            '.': {
                title: 0.007408338,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            '/': {
                title: 0.009260423,
                subtitle: 0.01165014,
                spine: 0.11959173,
            },
            '0': {
                title: 0.023377422,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '1': {
                title: 0.013088062,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '2': {
                title: 0.021689966,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '3': {
                title: 0.020125983,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '4': {
                title: 0.02070219,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '5': {
                title: 0.020949133,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '6': {
                title: 0.022142699,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '7': {
                title: 0.01954978,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '8': {
                title: 0.023048162,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '9': {
                title: 0.022183856,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            ':': {
                title: 0.007408338,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            ';': {
                title: 0.00769644,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            '<': {
                title: 0.025229506,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '=': {
                title: 0.025229506,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '>': {
                title: 0.025229506,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '?': {
                title: 0.015475194,
                subtitle: 0.015036809,
                spine: 0.1633362,
            },
            '@': {
                title: 0.03979924,
                subtitle: 0.02963335,
                spine: 0.3185585,
            },
            A: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            B: {
                title: 0.024324045,
                subtitle: 0.020218411,
                spine: 0.21343069,
            },
            C: {
                title: 0.028933674,
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            D: {
                title: 0.030086083,
                subtitle: 0.021166679,
                spine: 0.22401401,
            },
            E: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            F: {
                title: 0.020372929,
                subtitle: 0.01727201,
                spine: 0.18485565,
            },
            G: {
                title: 0.029921453,
                subtitle: 0.02126828,
                spine: 0.22507235,
            },
            H: {
                title: 0.028727887,
                subtitle: 0.022453612,
                spine: 0.23777236,
            },
            I: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            J: {
                title: 0.011976813,
                subtitle: 0.016730143,
                spine: 0.17956398,
            },
            K: {
                title: 0.027081588,
                subtitle: 0.020218411,
                spine: 0.21660568,
            },
            L: {
                title: 0.020784503,
                subtitle: 0.017001076,
                spine: 0.18273899,
            },
            M: {
                title: 0.03127965,
                subtitle: 0.025230683,
                spine: 0.26881683,
            },
            N: {
                title: 0.03127965,
                subtitle: 0.022216547,
                spine: 0.23459736,
            },
            O: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            P: {
                title: 0.022019226,
                subtitle: 0.019710412,
                spine: 0.21025568,
            },
            Q: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            R: {
                title: 0.025106033,
                subtitle: 0.020049078,
                spine: 0.2162529,
            },
            S: {
                title: 0.020414088,
                subtitle: 0.018457344,
                spine: 0.19614455,
            },
            T: {
                title: 0.026340757,
                subtitle: 0.01849121,
                spine: 0.19614455,
            },
            U: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            V: {
                title: 0.026587702,
                subtitle: 0.018152544,
                spine: 0.19614455,
            },
            W: {
                title: 0.04230984,
                subtitle: 0.027093349,
                spine: 0.2868085,
            },
            X: {
                title: 0.031238493,
                subtitle: 0.018321877,
                spine: 0.2000251,
            },
            Y: {
                title: 0.026340757,
                subtitle: 0.016967209,
                spine: 0.18520842,
            },
            Z: {
                title: 0.029427564,
                subtitle: 0.018288013,
                spine: 0.19085288,
            },
            '[': {
                title: 0.015351723,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            '\\': {
                title: 0.009260423,
                subtitle: 0.01165014,
                spine: 0.11959173,
            },
            ']': {
                title: 0.015351723,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            '^': {
                title: 0.021196077,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            _: {
                title: 0.02387131,
                subtitle: 0.016933342,
                spine: 0.176389,
            },
            '`': {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            a: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            b: {
                title: 0.020784503,
                subtitle: 0.019066947,
                spine: 0.20214179,
            },
            c: {
                title: 0.017780012,
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            d: {
                title: 0.020990292,
                subtitle: 0.019100811,
                spine: 0.20214179,
            },
            e: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            f: {
                title: 0.012882276,
                subtitle: 0.010735739,
                spine: 0.12029728,
            },
            g: {
                title: 0.019796725,
                subtitle: 0.017610677,
                spine: 0.18838343,
            },
            h: {
                title: 0.020578716,
                subtitle: 0.018897612,
                spine: 0.20143622,
            },
            i: {
                title: 0.009342737,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            j: {
                title: 0.009219265,
                subtitle: 0.008906939,
                spine: 0.098072276,
            },
            k: {
                title: 0.021278394,
                subtitle: 0.01767841,
                spine: 0.19332232,
            },
            l: {
                title: 0.008601903,
                subtitle: 0.009177872,
                spine: 0.1008945,
            },
            m: {
                title: 0.032349743,
                subtitle: 0.028549617,
                spine: 0.30233073,
            },
            n: {
                title: 0.020578716,
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            o: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            p: {
                title: 0.020990292,
                subtitle: 0.019100811,
                spine: 0.20214179,
            },
            q: {
                title: 0.020990292,
                subtitle: 0.019100811,
                spine: 0.20214179,
            },
            r: {
                title: 0.016915705,
                subtitle: 0.012632273,
                spine: 0.14040564,
            },
            s: {
                title: 0.015969083,
                subtitle: 0.014596541,
                spine: 0.15628064,
            },
            t: {
                title: 0.015351723,
                subtitle: 0.012225873,
                spine: 0.13511395,
            },
            u: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            v: {
                title: 0.019014733,
                subtitle: 0.01676401,
                spine: 0.18450288,
            },
            w: {
                title: 0.030909231,
                subtitle: 0.025332281,
                spine: 0.27375573,
            },
            x: {
                title: 0.022348484,
                subtitle: 0.016289877,
                spine: 0.18132788,
            },
            y: {
                title: 0.019014733,
                subtitle: 0.01676401,
                spine: 0.18379733,
            },
            z: {
                title: 0.01954978,
                subtitle: 0.015002942,
                spine: 0.16227788,
            },
            '{': {
                title: 0.013911212,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            '|': {
                title: 0.007614125,
                subtitle: 0.008636005,
                spine: 0.0945445,
            },
            '}': {
                title: 0.013911212,
                subtitle: 0.010972806,
                spine: 0.12135563,
            },
            '~': {
                title: 0.023048162,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            '': {},
            ' ': {
                subtitle: 0.0067733373,
                spine: 0.0705556,
            },
            '¡': {
                title: 0.007408338,
                subtitle: 0.010668006,
                spine: 0.11994451,
            },
            '¢': {
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '£': {
                title: 0.02531182,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '¤': {
                title: 0.02469446,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '¥': {
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '¦': {
                subtitle: 0.008636005,
                spine: 0.0945445,
            },
            '§': {
                title: 0.018150428,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '¨': {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            '©': {
                title: 0.03160891,
                subtitle: 0.025298413,
                spine: 0.26458347,
            },
            ª: {
                subtitle: 0.011921073,
                spine: 0.1266473,
            },
            '«': {
                title: 0.021360707,
                subtitle: 0.015409342,
                spine: 0.16933343,
            },
            '¬': {
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '­': {
                subtitle: 0.010905072,
                spine: 0.117122285,
            },
            '®': {
                title: 0.03160891,
                subtitle: 0.015002942,
                spine: 0.16298343,
            },
            '¯': {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            '°': {
                title: 0.013911212,
                subtitle: 0.011785606,
                spine: 0.12876396,
            },
            '±': {
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            '²': {
                subtitle: 0.012598407,
                spine: 0.13264452,
            },
            '³': {
                subtitle: 0.012598407,
                spine: 0.13264452,
            },
            '´': {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            µ: {
                subtitle: 0.019574944,
                spine: 0.20955011,
            },
            '¶': {
                subtitle: 0.020286145,
                spine: 0.22436678,
            },
            '·': {
                title: 0.007408338,
                subtitle: 0.009313339,
                spine: 0.1058334,
            },
            '¸': {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            '¹': {
                subtitle: 0.012598407,
                spine: 0.13264452,
            },
            º: {
                subtitle: 0.012496807,
                spine: 0.13158618,
            },
            '»': {
                title: 0.021360707,
                subtitle: 0.015409342,
                spine: 0.16933343,
            },
            '¼': {
                title: 0.028480941,
                subtitle: 0.026924016,
                spine: 0.28539738,
            },
            '½': {
                title: 0.031938165,
                subtitle: 0.02800775,
                spine: 0.29845017,
            },
            '¾': {
                title: 0.031238493,
                subtitle: 0.027330415,
                spine: 0.2885724,
            },
            '¿': {
                title: 0.015475194,
                subtitle: 0.015036809,
                spine: 0.1633362,
            },
            À: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Á: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Â: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Ã: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Ä: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Å: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            Æ: {
                title: 0.039469976,
                subtitle: 0.028244814,
                spine: 0.2980974,
            },
            Ç: {
                title: 0.028933674,
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            È: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            É: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            Ê: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            Ë: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            Ì: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            Í: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            Î: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            Ï: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            Ð: {
                title: 0.030086083,
                subtitle: 0.02197948,
                spine: 0.23283347,
            },
            Ñ: {
                title: 0.03127965,
                subtitle: 0.022216547,
                spine: 0.23459736,
            },
            Ò: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            Ó: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            Ô: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            Õ: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            Ö: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            '×': {
                title: 0.02193691,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            Ø: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            Ù: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            Ú: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            Û: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            Ü: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            Ý: {
                title: 0.026340757,
                subtitle: 0.016967209,
                spine: 0.18520842,
            },
            Þ: {
                subtitle: 0.020320013,
                spine: 0.217664,
            },
            ß: {
                title: 0.021113763,
                subtitle: 0.020455478,
                spine: 0.22295569,
            },
            à: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            á: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            â: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            ã: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            ä: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            å: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            æ: {
                title: 0.02996261,
                subtitle: 0.026585348,
                spine: 0.2772835,
            },
            ç: {
                title: 0.017780012,
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            è: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            é: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            ê: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            ë: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            ì: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            í: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            î: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            ï: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            ð: {
                title: 0.021566495,
                subtitle: 0.01869441,
                spine: 0.19755566,
            },
            ñ: {
                title: 0.020578716,
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            ò: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            ó: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            ô: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            õ: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            ö: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            '÷': {
                title: 0.025229506,
                subtitle: 0.01737361,
                spine: 0.18626676,
            },
            ø: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            ù: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            ú: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            û: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            ü: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            ý: {
                title: 0.019014733,
                subtitle: 0.01676401,
                spine: 0.18379733,
            },
            þ: {
                subtitle: 0.019100811,
                spine: 0.20214179,
            },
            ÿ: {
                title: 0.019014733,
                subtitle: 0.01676401,
                spine: 0.18379733,
            },
            Ā: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            ā: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            Ă: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            ă: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            Ą: {
                title: 0.029386407,
                subtitle: 0.018897612,
                spine: 0.20214179,
            },
            ą: {
                title: 0.01843853,
                subtitle: 0.017475208,
                spine: 0.185914,
            },
            Ć: {
                title: 0.028933674,
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            ć: {
                title: 0.017780012,
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            Ĉ: {
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            ĉ: {
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            Ċ: {
                title: 0.028933674,
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            ċ: {
                title: 0.017780012,
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            Č: {
                title: 0.028933674,
                subtitle: 0.01950721,
                spine: 0.20531678,
            },
            č: {
                title: 0.017780012,
                subtitle: 0.01564641,
                spine: 0.16474731,
            },
            Ď: {
                title: 0.030086083,
                subtitle: 0.021166679,
                spine: 0.22401401,
            },
            ď: {
                title: 0.02646423,
                subtitle: 0.020116812,
                spine: 0.21660568,
            },
            Đ: {
                subtitle: 0.02197948,
                spine: 0.23283347,
            },
            đ: {
                title: 0.021566495,
                subtitle: 0.019100811,
                spine: 0.20214179,
            },
            Ē: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            ē: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            Ĕ: {
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            ĕ: {
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            Ė: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            ė: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            Ę: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            ę: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            Ě: {
                title: 0.021607652,
                subtitle: 0.018220278,
                spine: 0.19332232,
            },
            ě: {
                title: 0.01954978,
                subtitle: 0.01717041,
                spine: 0.18273899,
            },
            Ĝ: {
                subtitle: 0.02126828,
                spine: 0.22507235,
            },
            ĝ: {
                subtitle: 0.017610677,
                spine: 0.18838343,
            },
            Ğ: {
                title: 0.029921453,
                subtitle: 0.02126828,
                spine: 0.22507235,
            },
            ğ: {
                title: 0.019796725,
                subtitle: 0.017610677,
                spine: 0.18838343,
            },
            Ġ: {
                title: 0.029921453,
                subtitle: 0.02126828,
                spine: 0.22507235,
            },
            ġ: {
                title: 0.019796725,
                subtitle: 0.017610677,
                spine: 0.18838343,
            },
            Ģ: {
                subtitle: 0.02126828,
                spine: 0.22507235,
            },
            ģ: {
                subtitle: 0.017610677,
                spine: 0.18838343,
            },
            Ĥ: {
                subtitle: 0.022453612,
                spine: 0.23777236,
            },
            ĥ: {
                subtitle: 0.018897612,
                spine: 0.20143622,
            },
            Ħ: {
                subtitle: 0.023706678,
                spine: 0.25117794,
            },
            ħ: {
                subtitle: 0.018897612,
                spine: 0.20143622,
            },
            Ĩ: {
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            ĩ: {
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            Ī: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            ī: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            Ĭ: {
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            ĭ: {
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            Į: {
                title: 0.010618617,
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            į: {
                title: 0.009342737,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            İ: {
                subtitle: 0.009550406,
                spine: 0.106186174,
            },
            ı: {
                title: 0.008601903,
                subtitle: 0.0088730715,
                spine: 0.09736672,
            },
            Ĳ: {
                subtitle: 0.019134676,
                spine: 0.21237235,
            },
            ĳ: {
                subtitle: 0.017746143,
                spine: 0.19543901,
            },
            Ĵ: {
                subtitle: 0.016730143,
                spine: 0.17956398,
            },
            ĵ: {
                subtitle: 0.008906939,
                spine: 0.098072276,
            },
            Ķ: {
                subtitle: 0.020218411,
                spine: 0.21660568,
            },
            ķ: {
                subtitle: 0.01767841,
                spine: 0.19332232,
            },
            ĸ: {
                subtitle: 0.01767841,
                spine: 0.19332232,
            },
            Ĺ: {
                title: 0.020784503,
                subtitle: 0.017001076,
                spine: 0.18273899,
            },
            ĺ: {
                title: 0.008601903,
                subtitle: 0.009177872,
                spine: 0.1008945,
            },
            Ļ: {
                subtitle: 0.017001076,
                spine: 0.18273899,
            },
            ļ: {
                subtitle: 0.009177872,
                spine: 0.1008945,
            },
            Ľ: {
                title: 0.020784503,
                subtitle: 0.017001076,
                spine: 0.18273899,
            },
            ľ: {
                title: 0.014034684,
                subtitle: 0.009889072,
                spine: 0.11218339,
            },
            Ŀ: {
                title: 0.020784503,
                subtitle: 0.017001076,
                spine: 0.18273899,
            },
            ŀ: {
                title: 0.015598667,
                subtitle: 0.013817607,
                spine: 0.15980843,
            },
            Ł: {
                title: 0.020784503,
                subtitle: 0.01717041,
                spine: 0.18485565,
            },
            ł: {
                title: 0.010906719,
                subtitle: 0.010464805,
                spine: 0.11959173,
            },
            Ń: {
                title: 0.03127965,
                subtitle: 0.022216547,
                spine: 0.23459736,
            },
            ń: {
                title: 0.020578716,
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            Ņ: {
                subtitle: 0.022216547,
                spine: 0.23459736,
            },
            ņ: {
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            Ň: {
                title: 0.03127965,
                subtitle: 0.022216547,
                spine: 0.23459736,
            },
            ň: {
                title: 0.020578716,
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            ŉ: {
                subtitle: 0.027432017,
                spine: 0.29986128,
            },
            Ŋ: {
                subtitle: 0.022047214,
                spine: 0.23495014,
            },
            ŋ: {
                subtitle: 0.018965345,
                spine: 0.201789,
            },
            Ō: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            ō: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            Ŏ: {
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            ŏ: {
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            Ő: {
                title: 0.033749092,
                subtitle: 0.022826148,
                spine: 0.24130014,
            },
            ő: {
                title: 0.022801219,
                subtitle: 0.018592812,
                spine: 0.19579178,
            },
            Œ: {
                title: 0.037864838,
                subtitle: 0.029091485,
                spine: 0.3072696,
            },
            œ: {
                title: 0.035148446,
                subtitle: 0.02810935,
                spine: 0.2899835,
            },
            Ŕ: {
                title: 0.025106033,
                subtitle: 0.020049078,
                spine: 0.2162529,
            },
            ŕ: {
                title: 0.016915705,
                subtitle: 0.012632273,
                spine: 0.14040564,
            },
            Ŗ: {
                subtitle: 0.020049078,
                spine: 0.2162529,
            },
            ŗ: {
                subtitle: 0.012632273,
                spine: 0.14040564,
            },
            Ř: {
                title: 0.025106033,
                subtitle: 0.020049078,
                spine: 0.2162529,
            },
            ř: {
                title: 0.016915705,
                subtitle: 0.012632273,
                spine: 0.14040564,
            },
            Ś: {
                title: 0.020414088,
                subtitle: 0.018457344,
                spine: 0.19614455,
            },
            ś: {
                title: 0.015969083,
                subtitle: 0.014596541,
                spine: 0.15628064,
            },
            Ŝ: {
                subtitle: 0.018457344,
                spine: 0.19614455,
            },
            ŝ: {
                subtitle: 0.014596541,
                spine: 0.15628064,
            },
            Ş: {
                title: 0.020414088,
                subtitle: 0.018457344,
                spine: 0.19614455,
            },
            ş: {
                title: 0.015969083,
                subtitle: 0.014596541,
                spine: 0.15628064,
            },
            Š: {
                title: 0.020414088,
                subtitle: 0.018457344,
                spine: 0.19614455,
            },
            š: {
                title: 0.015969083,
                subtitle: 0.014596541,
                spine: 0.15628064,
            },
            Ţ: {
                title: 0.026340757,
                subtitle: 0.01849121,
                spine: 0.19614455,
            },
            ţ: {
                title: 0.015680982,
                subtitle: 0.012225873,
                spine: 0.13511395,
            },
            Ť: {
                title: 0.026340757,
                subtitle: 0.01849121,
                spine: 0.19614455,
            },
            ť: {
                title: 0.02066103,
                subtitle: 0.012225873,
                spine: 0.13511395,
            },
            Ŧ: {
                title: 0.026340757,
                subtitle: 0.01849121,
                spine: 0.19614455,
            },
            ŧ: {
                title: 0.015351723,
                subtitle: 0.012225873,
                spine: 0.13511395,
            },
            Ũ: {
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ũ: {
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ū: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ū: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ŭ: {
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ŭ: {
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ů: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ů: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ű: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ű: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ų: {
                title: 0.028810203,
                subtitle: 0.022182679,
                spine: 0.23459736,
            },
            ų: {
                title: 0.020578716,
                subtitle: 0.018829877,
                spine: 0.2003779,
            },
            Ŵ: {
                title: 0.04230984,
                subtitle: 0.027093349,
                spine: 0.2868085,
            },
            ŵ: {
                title: 0.030909231,
                subtitle: 0.025332281,
                spine: 0.27375573,
            },
            Ŷ: {
                title: 0.026340757,
                subtitle: 0.016967209,
                spine: 0.18520842,
            },
            ŷ: {
                title: 0.019014733,
                subtitle: 0.01676401,
                spine: 0.18379733,
            },
            Ÿ: {
                title: 0.026340757,
                subtitle: 0.016967209,
                spine: 0.18520842,
            },
            Ź: {
                title: 0.029427564,
                subtitle: 0.018288013,
                spine: 0.19085288,
            },
            ź: {
                title: 0.01954978,
                subtitle: 0.015002942,
                spine: 0.16227788,
            },
            Ż: {
                title: 0.029427564,
                subtitle: 0.018288013,
                spine: 0.19085288,
            },
            ż: {
                title: 0.01954978,
                subtitle: 0.015002942,
                spine: 0.16227788,
            },
            Ž: {
                title: 0.029427564,
                subtitle: 0.018288013,
                spine: 0.19085288,
            },
            ž: {
                title: 0.01954978,
                subtitle: 0.015002942,
                spine: 0.16227788,
            },
            ſ: {},
        },
    },
};
