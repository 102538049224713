import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import { Button } from '../../../../Components/Button';
import { filtersActions, filtersSelectors } from '../../../../features/Filters';
import './SearchInput.scss';

export interface ISearchInputProps {
    searchValue: string;
    search: () => void;
    setSearchValue: (value: string) => void;
}

export class SearchInput extends React.PureComponent<ISearchInputProps> {
    public render() {
        return (
            <div className='search-input-wrapper'>
                <input
                    className='search-input'
                    placeholder='Search for orders...'
                    value={this.props.searchValue}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                />
                <Button
                    buttonType='primary'
                    onClick={this.props.search}
                    id='search-button'
                >
                    Search
                </Button>
            </div>
        );
    }

    private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setSearchValue(event.target.value);
    };

    private onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.props.search();
        }
    };
}

const mapStateToProps = (state: Types.RootState) => ({
    searchValue: filtersSelectors.getSearchValue(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setSearchValue: filtersActions.setSearch,
            search: filtersActions.search
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchInput);
