import React from 'react';
import { ErrorMessage as Error } from 'formik';
import styles from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
    name: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ name }) => (
    <div className={styles['error-message']}>
        <Error name={name} />
    </div>
);
