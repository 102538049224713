import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FormInput } from '../../../../../../../../Components/FormInput';
import {
    DeliveryMethod,
    IOrderBase
} from '../../../../../../../../repository/OrderModel';
import {
    required,
    validateEmailAddress
} from '../../../../../../../../utils/validators';
import { SameAsBilling } from './Components/SameAsBilling';
import { connect, FormikContextType } from 'formik';

import './CustomerSectionContent.scss';

export interface ICustomerSectionContentProps {
    expanded: boolean;
    deliveryMethod: DeliveryMethod;
    disabled: boolean;
    addressType: 'billingAddress' | 'deliveryAddress';
}

export const CustomerSectionContent: React.FunctionComponent<ICustomerSectionContentProps & {
    formik: FormikContextType<IOrderBase>;
}> = props => {
    const sameAsBillingName = 'deliveryAddressSameAsBilling';
    const { deliveryAddressSameAsBilling: sameAsBilling } = props.formik.values;
    const { expanded, deliveryMethod, addressType } = props;
    const disabled =
        props.disabled || (sameAsBilling && addressType === 'deliveryAddress');
    const isBillingAddress = addressType === 'billingAddress';

    return (
        <CSSTransition
            timeout={500}
            classNames='customer-content'
            in={expanded}
            unmountOnExit={true}
        >
            <div className='customer-content'>
                {addressType === 'deliveryAddress' && (
                    <SameAsBilling
                        name={sameAsBillingName}
                        disabled={props.disabled}
                    />
                )}
                <div className='details-section'>
                    <div className='column'>
                        <FormInput
                            label='First Name'
                            name={`${addressType}.firstName`}
                            type='text'
                            disabled={disabled}
                            placeholder='First Name'
                            validate={required}
                        />
                        <FormInput
                            label='Phone number'
                            name={`${addressType}.phoneNumber`}
                            type='text'
                            disabled={disabled}
                            placeholder='Phone number'
                        />
                    </div>
                    <div className='column'>
                        <FormInput
                            label='Last Name'
                            name={`${addressType}.lastName`}
                            type='text'
                            disabled={disabled}
                            placeholder='Last Name'
                            validate={required}
                        />
                        {isBillingAddress && (
                            <FormInput
                                label='Email'
                                name={`${addressType}.email`}
                                type='email'
                                disabled={disabled}
                                validate={validateEmailAddress}
                                placeholder='Email address'
                            />
                        )}
                    </div>
                </div>
                <div className='details-section'>
                    <div className='column'>
                        <FormInput
                            label='Line 1'
                            name={`${addressType}.line1`}
                            type='text'
                            disabled={disabled}
                            placeholder='Address 1st line'
                            validate={required}
                        />
                        <FormInput
                            label='City'
                            name={`${addressType}.city`}
                            type='text'
                            disabled={disabled}
                            placeholder='Town/city'
                        />
                        <FormInput
                            label='Postcode'
                            name={`${addressType}.postCode`}
                            type='text'
                            disabled={disabled}
                            placeholder='Post code'
                            validate={required}
                        />
                    </div>
                    <div className='column'>
                        <FormInput
                            label='Line 2'
                            name={`${addressType}.line2`}
                            type='text'
                            disabled={disabled}
                            placeholder='Address 2nd line'
                        />

                        <FormInput
                            label='County'
                            name={`${addressType}.county`}
                            type='text'
                            disabled={disabled}
                            placeholder='County'
                        />
                        <FormInput
                            label='Country'
                            name={`${addressType}.country`}
                            type='text'
                            disabled={disabled}
                            placeholder='Country'
                            validate={required}
                        />
                    </div>
                </div>
                {!isBillingAddress && (
                    <div className='delivery-method'>
                        Delivery:{' '}
                        <span>
                            {
                                DeliveryMethod[
                                    (deliveryMethod as unknown) as keyof typeof DeliveryMethod
                                ]
                            }
                        </span>
                    </div>
                )}
            </div>
        </CSSTransition>
    );
};

export default connect<ICustomerSectionContentProps, IOrderBase>(
    CustomerSectionContent
);
