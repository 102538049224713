import classNames from 'classnames';
import * as React from 'react';
import styles from './AdditionalInfoPanel.module.scss';

export enum InfoType {
    INFO,
    WARNING,
    ERROR
}

export interface IAdditionalInfoPanelProps {
    messages: string[];
    type: InfoType;
}

export const AdditionalInfoPanel: React.FunctionComponent<
    IAdditionalInfoPanelProps
> = ({ messages, type }) => {
    const panelClassName = classNames(styles['additional-info-panel'], {
        [styles['additional-info-panel--warning']]: type === InfoType.WARNING,
        [styles['additional-info-panel--error']]: type === InfoType.ERROR
    });

    const iconClassNames = classNames(
        styles['additional-info-panel__icon'],
        'mdi',
        {
            'mdi-information': type === InfoType.INFO,
            'mdi-alert': type === InfoType.WARNING,
            'mdi-alert-octagon': type === InfoType.ERROR,
            [styles['additional-info-panel__icon--warning']]:
                type === InfoType.WARNING,
            [styles['additional-info-panel__icon--error']]:
                type === InfoType.ERROR
        }
    );

    return (
        <div className={panelClassName}>
            <i className={iconClassNames} />
            <div className={styles['additional-info-panel__messages']}>
                {messages.length > 0 &&
                    messages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
            </div>
        </div>
    );
};
