import React, { useMemo, useCallback } from 'react';
import { capitalize, replace } from 'lodash';
import { connect, FormikContextType } from 'formik';
import ReactSelect from 'react-select';
import { StylesConfig } from 'react-select/lib/styles';
import { ValueType } from 'react-select/lib/types';

import { Comment, Reason } from '../../../../repository/OrderModel';
import componentStyles from './ReasonSelect.module.scss';
import { ErrorMessage } from '../../../ErrorMessage';

export interface IOption {
    value: string;
    label: string;
}

export const ReasonSelect: React.FC<{} & {
    formik: FormikContextType<Comment>;
}> = ({ formik }) => {
    const { setFieldTouched, setFieldValue, validateForm } = formik;
    const selectStyles = useMemo(
        () =>
            ({
                control: (
                    styles: React.CSSProperties,
                    { isFocused }: { isFocused: boolean }
                ) => {
                    return {
                        ...styles,
                        border: '1px solid #757575',
                        borderRadius: 3,
                        paddingLeft: 16,
                        cursor: 'pointer',
                        boxShadow: isFocused
                            ? '0 0 3px 2px rgba(0, 113, 188, 0.5)'
                            : 'none',
                        lineHeight: 18
                    };
                },
                indicatorSeparator: () => ({
                    display: 'none'
                }),
                option: (
                    styles: React.CSSProperties,
                    { isSelected, isFocused }
                ) => {
                    return {
                        ...styles,
                        backgroundColor: isSelected
                            ? '#453c90'
                            : isFocused
                            ? '#f1efff'
                            : '#fff'
                    };
                },
                valueContainer: original => ({ ...original, padding: '0' })
            } as StylesConfig),
        []
    );

    const keys = useMemo(() => Object.keys(Reason), []);

    const options = useMemo(() => {
        const optionsList: IOption[] = [];
        keys.forEach(key => {
            if (key !== Reason.CANCELLED && key !== Reason.ON_HOLD) {
                optionsList.push({
                    value: key,
                    label: replace(capitalize(key), /_/g, ' ')
                });
            }
        });
        return optionsList;
    }, [keys]);

    const onChange = useCallback(
        async (selected: ValueType<IOption>) => {
            const value = selected
                ? selected instanceof Array
                    ? selected[0].value
                    : selected.value
                : '';
            setFieldValue('reason', value, false);
            setFieldTouched('reason', true);
            await validateForm();
            setFieldTouched('text', true);
        },
        [setFieldTouched, setFieldValue, validateForm]
    );

    const baseClassName = 'reason-select';

    return (
        <div className={componentStyles[baseClassName]}>
            <div className={componentStyles[`${baseClassName}__label`]}>
                Reason
            </div>
            <ReactSelect
                options={options}
                styles={selectStyles}
                isSearchable={false}
                placeholder={'Select reason'}
                name='reason'
                isClearable={true}
                onChange={onChange}
            />
            <ErrorMessage name={'reason'} />
        </div>
    );
};

export default connect<{}, Comment>(ReasonSelect);
