import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
    IOrderLine,
    IOrderLineStatusDetails,
    IRouteSpecification,
    ProductType,
    Status
} from '../../../../../../../../repository/OrderModel';
import { AdditionalInfoPanel } from '../AdditionalInformationPanel';
import { InfoType } from '../AdditionalInformationPanel/AdditionalInfoPanel';
import { ProductDetails } from './Components/ProductDetails';
import { ProductTitleSection } from './Components/ProductTitleSection';
import './ProductSectionContent.scss';

export interface IProductSectionContentProps {
    expanded: boolean;
    orderLineIndex: number;
    disabled: boolean;
    type: ProductType;
    coverUrl: string;
    status: IOrderLineStatusDetails;
    orderLine: IOrderLine;
    route?: IRouteSpecification;
}

export class ProductSectionContent extends React.PureComponent<
    IProductSectionContentProps
> {
    public render() {
        const {
            expanded,
            orderLineIndex,
            disabled,
            type,
            coverUrl,
            orderLine,
            route
        } = this.props;

        return (
            <CSSTransition
                timeout={500}
                classNames='product-content'
                in={expanded}
                unmountOnExit={true}
            >
                <div className='product-content'>
                    <div className='product-details'>
                        {this.renderInfoPanel()}
                        <div className='section-separator'>
                            <ProductDetails
                                orderLineIndex={orderLineIndex}
                                disabled={disabled}
                                type={type}
                                route={route}
                            />
                        </div>

                        <ProductTitleSection
                            disabled={disabled}
                            type={type}
                            coverUrl={coverUrl}
                            orderLineIndex={orderLineIndex}
                            orderLine={orderLine}
                        />
                    </div>
                </div>
            </CSSTransition>
        );
    }

    private renderInfoPanel = () => {
        const { status } = this.props;
        const { errorMessage, supplierStatus, validationWarnings } = status;

        if (
            Status[(status.status as unknown) as keyof typeof Status] ===
                Status.ERROR &&
            errorMessage
        ) {
            return (
                <AdditionalInfoPanel
                    messages={[errorMessage]}
                    type={InfoType.ERROR}
                />
            );
        }

        if (
            Status[(status.status as unknown) as keyof typeof Status] ===
                Status.REVIEW &&
            validationWarnings.length
        ) {
            return (
                <AdditionalInfoPanel
                    messages={validationWarnings}
                    type={InfoType.WARNING}
                />
            );
        }
        if (
            Status[(status.status as unknown) as keyof typeof Status] ===
                Status.FULFILLMENT &&
            supplierStatus
        ) {
            return (
                <AdditionalInfoPanel
                    messages={[`Supplier status: ${supplierStatus}`]}
                    type={InfoType.INFO}
                />
            );
        }
    };
}
