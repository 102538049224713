import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import Types from 'Types';
import { ActionType, isOfType } from 'typesafe-actions';
import { orderActions } from '.';
import { IOrderUpdateRequest } from '../../repository/OrderModel';
import ordersRepository from '../../repository/ordersRepostitory';
import history from '../../utils/history';
import { normalizeOrder } from '../../utils/OrderNormalizer';
import { getOrder, setOrder } from './actions';
import {
    ACCEPT_ORDER,
    ACCEPT_ORDER_LINE,
    CANCEL_ORDER,
    CANCEL_ORDER_LINE,
    GET_ORDER,
    PAUSE_ORDER,
    RESUBMIT_ORDER,
    RESUBMIT_ORDER_LINE,
    RESUPPLY_ORDER,
    RESUPPLY_ORDER_LINE,
    UNPAUSE_ORDER,
    UPDATE_ORDER
} from './constants';
import { emptyOrder } from './StateFixture/emptyOrder';

const getOrderEpic: Epic<Types.RootAction, Types.RootAction> = action$ =>
    action$.pipe(
        filter(isOfType(GET_ORDER)),
        switchMap(action =>
            ordersRepository
                .getOrder(action.payload)
                .then(result => {
                    return setOrder(normalizeOrder(result));
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            'Something went wrong while getting order'
                        );
                        history.push('/orders');
                    }
                    return setOrder(emptyOrder);
                })
        )
    );

const performOrderActionEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ =>
    action$.pipe(
        ofType(UNPAUSE_ORDER, ACCEPT_ORDER),
        switchMap(action => {
            type actionType = ActionType<
                | typeof orderActions.unpauseOrder
                | typeof orderActions.acceptOrder
            >;
            return ordersRepository
                .performOrderAction(
                    (action as actionType).payload.orderRef,
                    (action as actionType).payload.action
                )
                .then(() => {
                    toastr.info(
                        '',
                        (action as actionType).payload.successMessage
                    );
                    return getOrder((action as actionType).payload.orderRef);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            (action as actionType).payload.failureMessage
                        );
                    }

                    return getOrder((action as actionType).payload.orderRef);
                });
        })
    );

const performOrderActionWithCommentEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ =>
    action$.pipe(
        ofType(PAUSE_ORDER, CANCEL_ORDER, RESUPPLY_ORDER, RESUBMIT_ORDER),
        switchMap(action => {
            type actionType = ActionType<
                | typeof orderActions.pauseOrder
                | typeof orderActions.cancelOrder
                | typeof orderActions.resupplyOrder
                | typeof orderActions.resubmitOrder
            >;
            return ordersRepository
                .performOrderAction(
                    (action as actionType).payload.orderRef,
                    (action as actionType).payload.action,
                    (action as actionType).payload.reason,
                    (action as actionType).payload.comment
                )
                .then(() => {
                    toastr.info(
                        '',
                        (action as actionType).payload.successMessage
                    );
                    return getOrder((action as actionType).payload.orderRef);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            (action as actionType).payload.failureMessage
                        );
                    }

                    return getOrder((action as actionType).payload.orderRef);
                });
        })
    );

const performOrderLineActionEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ =>
    action$.pipe(
        ofType(ACCEPT_ORDER_LINE),
        switchMap(action => {
            type acttionType = ActionType<typeof orderActions.acceptOrderLine>;
            return ordersRepository
                .performOrderLineAction(
                    (action as acttionType).payload.orderRef,
                    (action as acttionType).payload.orderLineRef,
                    (action as acttionType).payload.action
                )
                .then(() => {
                    toastr.info(
                        '',
                        (action as acttionType).payload.successMessage
                    );
                    return getOrder((action as acttionType).payload.orderRef);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            (action as acttionType).payload.failureMessage
                        );
                    }

                    return getOrder((action as acttionType).payload.orderRef);
                });
        })
    );

const performOrderLineActionWithCommentEpic: Epic<
    Types.RootAction,
    Types.RootAction
> = action$ =>
    action$.pipe(
        ofType(CANCEL_ORDER_LINE, RESUPPLY_ORDER_LINE, RESUBMIT_ORDER_LINE),
        switchMap(action => {
            type acttionType = ActionType<
                | typeof orderActions.cancelOrderLine
                | typeof orderActions.resupplyOrderLine
                | typeof orderActions.resubmitOrderLine
            >;
            const {
                orderRef,
                orderLineRef,
                action: actionName,
                reason,
                comment
            } = (action as acttionType).payload;
            return ordersRepository
                .performOrderLineAction(
                    orderRef,
                    orderLineRef,
                    actionName,
                    reason,
                    comment
                )
                .then(() => {
                    toastr.info(
                        '',
                        (action as acttionType).payload.successMessage
                    );
                    return getOrder((action as acttionType).payload.orderRef);
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            (action as acttionType).payload.failureMessage
                        );
                    }

                    return getOrder((action as acttionType).payload.orderRef);
                });
        })
    );

const updateOrderEpic: Epic<
    Types.RootAction,
    Types.RootAction,
    Types.RootState
> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(UPDATE_ORDER)),
        switchMap(async action => {
            return imagesSaver(action.payload, state$.value.order.covers)
                .then(order =>
                    ordersRepository
                        .updateOrder(order)
                        .then(() => {
                            toastr.info('', 'Order was updated.');
                            return getOrder(order.referenceNumber);
                        })
                        .catch(error => {
                            if (!axios.isCancel(error)) {
                                if (
                                    error.response &&
                                    error.response.status &&
                                    error.response.status === 409 &&
                                    error.response.data &&
                                    error.response.data.message
                                ) {
                                    toastr.error(
                                        '',
                                        error.response.data.message
                                    );
                                } else {
                                    toastr.error(
                                        '',
                                        'An error occurred while updating order'
                                    );
                                }
                            }
                            return getOrder(order.referenceNumber);
                        })
                )
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        toastr.error(
                            '',
                            'An error occurred while updating order'
                        );
                    }
                    return getOrder(action.payload.referenceNumber);
                });
        })
    );

const imagesSaver = async (
    order: IOrderUpdateRequest,
    covers: { url: string; image?: File }[]
) => {
    if (covers.some(cover => cover.image !== undefined)) {
        const newOrder: IOrderUpdateRequest = { ...order };
        await Promise.all(
            covers.map(async (cover, index) => {
                if (cover.image) {
                    newOrder.orderLines[
                        index
                    ].product.coverUrl = await ordersRepository.saveImage(
                        cover.image
                    );
                }
            })
        );
        return newOrder;
    }
    return order;
};

export default combineEpics(
    getOrderEpic,
    performOrderActionEpic,
    performOrderActionWithCommentEpic,
    performOrderLineActionEpic,
    performOrderLineActionWithCommentEpic,
    updateOrderEpic
);
