import * as React from 'react';
import './AppHeader.scss';
import { UserDropdown } from './Components/UserDropdown/UserDropdown';
import logo from './logo-with-text.svg';
import { Link } from 'react-router-dom';

export const AppHeader: React.FunctionComponent = () => {
    return (
        <div className='app-header'>
            <div className='container'>
                <Link to='/orders'>
                    <img src={logo} alt='os-logo' />
                </Link>
                <UserDropdown />
            </div>
        </div>
    );
};
