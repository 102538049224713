import * as React from 'react';
import './AppFooter.scss';
import { FooterLinks } from './Components/FooterLinks/FooterLinks';
import logo from './logo-with-text-grey.svg';

export const AppFooter: React.FunctionComponent = () => {
    return (
        <div className='app-footer'>
            <div className='container'>
                <img src={logo} alt='logo' />
                <FooterLinks />
            </div>
        </div>
    );
};
