import * as React from 'react';
import { Checkbox } from '../../../../../../../../Components/Checkbox';
import { Status } from '../../../../../../../../repository/OrderModel';
import './StatusFilters.scss';

export interface IStatusFiltersProps {
    selectedStatuses: Status[];
    setStatus: (statusValue: Status) => void;
}

export class StatusFilters extends React.PureComponent<IStatusFiltersProps> {
    public render() {
        return (
            <React.Fragment>
                {Object.keys(Status).map(
                    (key: string): JSX.Element => (
                        <Checkbox
                            label={Status[key as keyof typeof Status]}
                            value={key}
                            checked={
                                this.props.selectedStatuses.indexOf(
                                    key as Status
                                ) >= 0
                            }
                            onChange={this.setStatus}
                            key={key}
                        />
                    )
                )}
            </React.Fragment>
        );
    }

    private setStatus = (value: string) => {
        this.props.setStatus(value as Status);
    };
}
