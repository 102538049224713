import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable, { RowInfo } from 'react-table';
import 'react-table/react-table.css';
import {
    IOrderBasicInfo,
    Status,
    DeliveryMethod
} from '../../../../repository/OrderModel';
import history from '../../../../utils/history';
import { statusClassNameFormatter } from '../../../../utils/statusFormatter';
import './OrdersList.scss';

export interface IOrdersListProps {
    orders: IOrderBasicInfo[];
}

export class OrdersList extends React.PureComponent<IOrdersListProps> {
    public render() {
        const { orders } = this.props;

        return (
            <div className='orders'>
                <ReactTable
                    data={orders}
                    columns={[
                        {
                            Header: 'Order no',
                            accessor: 'referenceNumber',
                            width: 160,
                            Cell: this.orderRef
                        },
                        {
                            Header: 'Date',
                            accessor: 'submittedAt',
                            className: 'date-time',
                            Cell: this.dateCell
                        },
                        {
                            Header: 'Customer',
                            className: 'client-data',
                            Cell: this.clientCell
                        },
                        {
                            Header: 'Delivery',
                            accessor: 'deliveryMethod',
                            className: 'post-code',
                            width: 95,
                            Cell: this.deliveryCell
                        },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            className: 'status',
                            Cell: this.statusCell,
                            width: 160
                        },
                        {
                            Header: '',
                            accessor: 'referenceNumber',
                            className: 'link',
                            Cell: this.editLink,
                            width: 28
                        }
                    ]}
                    className='-striped -highlight'
                    showPagination={false}
                    pageSize={Math.max(this.props.orders.length, 1)}
                    resizable={false}
                    sortable={false}
                    getTrGroupProps={this.rowProps}
                />
            </div>
        );
    }

    private deliveryCell = ({ value }: { value: DeliveryMethod }) => {
        const deliveryMethod =
            DeliveryMethod[(value as unknown) as keyof typeof DeliveryMethod];
        return <span title={deliveryMethod}>{deliveryMethod}</span>;
    };

    private orderRef = ({ value }: { value: string }) => (
        <div onClick={this.onLinkClick} className='referenceNumber link'>
            <Link
                to={`/orders/${encodeURIComponent(value)}`}
                className='order-edit-link'
                title={value}
            >
                {value}
            </Link>
        </div>
    );

    private dateCell = ({ value }: { value: string }) => (
        <React.Fragment>
            <span
                className='time'
                title={moment(value).format('DD/MM/YYYY hh:mm A')}
            >
                {moment(value).format('hh:mm A')}
            </span>
            <span
                className='date'
                title={moment(value).format('DD/MM/YYYY hh:mm A')}
            >
                {moment(value).format('DD/MM/YYYY')}
            </span>
        </React.Fragment>
    );

    private clientCell = ({ original }: { original: IOrderBasicInfo }) => {
        const { firstName, lastName, email } = original.billingAddress;

        return (
            <React.Fragment>
                {lastName && firstName && (
                    <span
                        className='name'
                        title={
                            email
                                ? `${lastName} ${firstName} ${email}`
                                : `${lastName} ${firstName}`
                        }
                    >
                        {`${lastName} ${firstName}`}
                    </span>
                )}
                {email && (
                    <a
                        href={`mailto:${email}?subject=Order no.: ${
                            original.referenceNumber
                        }`}
                        title={`${lastName} ${firstName} ${email}`}
                        onClick={this.onEmailClick}
                    >
                        {email}
                    </a>
                )}
            </React.Fragment>
        );
    };

    private onEmailClick = (event: React.MouseEvent<HTMLAnchorElement>) =>
        event.stopPropagation();

    private onLinkClick = (event: React.MouseEvent<HTMLDivElement>) =>
        event.stopPropagation();

    private statusCell = ({
        value,
        original
    }: {
        value: Status;
        original: IOrderBasicInfo;
    }) => (
        <div className={statusClassNameFormatter(value)}>
            {original.paused && <i className='mdi mdi-pause-circle-outline' />}
            {Status[(value as unknown) as keyof typeof Status]}
        </div>
    );

    private editLink = ({ value }: { value: string }) => (
        <div onClick={this.onLinkClick}>
            <Link
                to={`/orders/${encodeURIComponent(value)}`}
                className='order-edit-link'
                title={value}
            >
                <i className='mdi mdi-pencil' />
            </Link>
        </div>
    );

    private rowProps = (finalState: any, rowInfo?: RowInfo) => {
        if (rowInfo !== undefined) {
            return {
                onClick: () => this.handleRowClick(rowInfo),
                className: 'clickable'
            };
        }
        return {};
    };

    private handleRowClick = (rowInfo: RowInfo) => {
        history.push(
            `/orders/${encodeURIComponent(
                (rowInfo.original as IOrderBasicInfo).referenceNumber
            )}`
        );
    };
}
