import * as React from 'react';
import './sessionPage.scss';

export const SessionPage: React.FunctionComponent = () => {
    return (
        <div className='session-checking'>
            <div className='logo'>
                <div className='first rectangle' />
                <div className='second rectangle' />
                <div className='third rectangle' />
                <div className='fourth rectangle' />
            </div>
            <h1>Checking your session...</h1>
        </div>
    );
};
