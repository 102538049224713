import * as React from 'react';
import AuthService from '../../../../../../../../../../utils/authenticationService';
import { KIBANA_URL } from '../../../../../../../../../../utils/constants';
import { DropdownArrow } from '../../../../../../../../../DropdownArrow';
import './UserDropdown.scss';

interface IUserDropdownState {
    opened: boolean;
}

export class UserDropdown extends React.PureComponent<{}, IUserDropdownState> {
    constructor(props: any) {
        super(props);
        this.state = {
            opened: false
        };
    }

    public render() {
        const user = AuthService.getUser();
        const userName: string = user ? user.name : '';
        return (
            <div
                onMouseEnter={this.toggleDropdown}
                onMouseLeave={this.toggleDropdown}
                className='user-dropdown'
            >
                <div className='dropdown-header'>
                    <label>{userName}</label>
                    <DropdownArrow
                        expanded={this.state.opened}
                        initialArrowDown={true}
                    />
                </div>
                {this.state.opened && (
                    <div className='items'>
                        <div
                            className='item kibana'
                            onClick={this.accessKibana}
                        >
                            Access Kibana
                        </div>
                        <div className='item logout' onClick={this.logout}>
                            Logout
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private logout = (event: React.MouseEvent) => {
        AuthService.logout();
    };

    private accessKibana = () => {
        const win = window.open(KIBANA_URL, '_blank');
        if (win) {
            win.focus();
        }
    };

    private toggleDropdown = (event: React.MouseEvent) => {
        this.setState(prevState => {
            return { opened: !prevState.opened };
        });
    };
}
