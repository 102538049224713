import * as React from 'react';
import './Button.scss';

export type ButtonType = 'primary' | 'secondary' | 'danger';

export interface IButtonProps {
    children: React.ReactNode;
    id: string;
    buttonType: ButtonType;
    type?: 'submit' | 'reset' | 'button';
    disabled?: boolean;
    onClick?: () => void;
}

interface IDefaultProps {
    disabled: boolean;
}

export class Button extends React.PureComponent<IButtonProps> {
    public static defaultProps: IDefaultProps = {
        disabled: false
    };

    public render() {
        const {
            children,
            id,
            buttonType,
            onClick,
            type,
            disabled
        } = this.props;
        return (
            <button
                className={`button ${buttonType}`}
                onClick={onClick}
                id={id}
                type={type}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
}
