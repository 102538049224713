import * as React from 'react';
import { Link } from 'react-router-dom';
import './TitleSection.scss';

export interface ITitleSectionProps {
    subtitle?: string;
}

export const TitleSection: React.FunctionComponent<ITitleSectionProps> = ({
    subtitle
}) => {
    return (
        <div className='title-section'>
            <div className='container'>
                {!subtitle && <h1>Orders</h1>}
                {subtitle && (
                    <React.Fragment>
                        <Link to='/orders' className='orders-link'>
                            Orders
                        </Link>
                        <h1>/</h1>
                        <h1>{subtitle}</h1>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
