import * as React from 'react';
import { Checkbox } from '../../../../../../../../Components/Checkbox';

export interface IPauseFiltersProps {
    isPaused: boolean;
    onChange: () => void;
}

export const PauseFilters: React.FunctionComponent<
    IPauseFiltersProps
> = props => {
    const { isPaused: onlyPaused, onChange } = props;
    return (
        <React.Fragment>
            <Checkbox
                label='Show only orders on hold'
                checked={onlyPaused}
                onChange={onChange}
                value={''}
            />
        </React.Fragment>
    );
};
