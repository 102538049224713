import * as React from 'react';
import { ValueType } from 'react-select/lib/types';
import { IOptionType, Select } from './Components/Select/Select';
import './PageSizeSelect.scss';

export interface IPageSizeSelectProps {
    pageSize: number;
    changePageSize: (size: number) => void;
}

export class PageSizeSelect extends React.PureComponent<IPageSizeSelectProps> {
    private options: IOptionType[] = [
        { value: 10, label: '10' },
        { value: 30, label: '30' },
        { value: 50, label: '50' },
        { value: 100, label: '100' }
    ];

    public render() {
        const { pageSize } = this.props;

        const value = { value: pageSize, label: pageSize.toString() };

        return (
            <div className='page-size-select'>
                <span>Show</span>
                <span className='select'>
                    <Select
                        options={this.options}
                        value={value}
                        onChange={this.onChange}
                        width={80}
                    />
                </span>
                <span>orders</span>
            </div>
        );
    }

    private onChange = (value: ValueType<IOptionType>) => {
        const pageSize = value
            ? value instanceof Array
                ? value[0].value
                : value.value
            : 30;
        if (this.props.pageSize !== pageSize) {
            this.props.changePageSize(pageSize);
        }
    };
}
