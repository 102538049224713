import Types from 'Types';

export const getLoadingOrders = (state: Types.RootState) =>
    state.ordersList.loadingOrders;

export const getOrders = (state: Types.RootState) => state.ordersList.orders;

export const getPageInfo = (state: Types.RootState) =>
    state.ordersList.pageInfo;

export const getSelectedPageSize = (state: Types.RootState) =>
    state.ordersList.selectedPageSize;
