import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Root } from './Components/Root';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import setInterceptors from './utils/interceptors';

setInterceptors();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
