import React, { useCallback } from 'react';
import classNames from 'classnames';
import { connect, Field, FormikContextType, getIn } from 'formik';
import { isEmpty } from 'lodash';

import { IOrderBase } from '../../repository/OrderModel';
import { ErrorMessage } from '../ErrorMessage';

import styles from './FormInput.module.scss';

export interface IOption {
    name: string;
    value: any;
}

export interface IFormInputProps {
    label: string;
    name: string;
    type: string;
    disabled: boolean;
    placeholder: string;
    validate?: (value: any) => string | Promise<void> | undefined;
}

export const FormInput: React.FC<IFormInputProps & {
    formik: FormikContextType<IOrderBase>;
}> = ({ label, name, type, disabled, placeholder, validate, formik }) => {
    const baseClassName = 'form-input';

    const onChange = useCallback(
        (event: React.ChangeEvent) => {
            const { handleChange, setFieldTouched } = formik;
            setFieldTouched(name as keyof IOrderBase, true);
            handleChange(event);
        },
        [formik, name]
    );

    const className = classNames(styles[baseClassName], {
        [styles[`${baseClassName}--empty`]]: isEmpty(getIn(formik.values, name))
    });

    return (
        <div className={className}>
            <label htmlFor={name} className={styles[`${baseClassName}__label`]}>
                {label}
            </label>
            <Field
                type={type}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                validate={validate}
                onChange={onChange}
            />
            <ErrorMessage name={name} />
        </div>
    );
};

export default connect<IFormInputProps, IOrderBase>(FormInput);
