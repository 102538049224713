import * as React from 'react';
import './RadioGroup.scss';

export interface IRadioGroupProps {
    label: string;
    className?: string;
}

export const RadioGroup: React.FunctionComponent<IRadioGroupProps> = props => {
    const { label, className, children } = props;
    return (
        <div className={'radio-group' + (className ? ` ${className}` : '')}>
            <span className='label'>{label}</span>
            <div className='radio-buttons'>{children}</div>
        </div>
    );
};
