import moment from 'moment';
import { action } from 'typesafe-actions';
import { Status, DeliveryMethod } from '../../repository/OrderModel';
import {
    CLEAR_ALL,
    SEARCH,
    SET_IS_PAUSED,
    SET_SEARCH,
    SET_STATUS,
    SET_SUBMITTED_FROM,
    SET_SUBMITTED_TO,
    SET_DELIVERY_METHOD,
    SET_SKUS,
    SET_HAS_ROUTE
} from './constants';

export const clearAll = () => action(CLEAR_ALL);

export const setStatus = (selectedStatus: Status) =>
    action(SET_STATUS, selectedStatus);

export const setSubmittedFrom = (date: moment.Moment) =>
    action(SET_SUBMITTED_FROM, date);

export const setSubmittedTo = (date: moment.Moment) =>
    action(SET_SUBMITTED_TO, date);

export const setSearch = (searchValue: string) =>
    action(SET_SEARCH, searchValue);

export const setIsPaused = (onlyPaused: boolean) =>
    action(SET_IS_PAUSED, onlyPaused);

export const setHasRoute = (hasRoute: boolean) =>
    action(SET_HAS_ROUTE, hasRoute);

export const search = () => action(SEARCH);

export const setDeliveryMethod = (deliveryMethod: DeliveryMethod) =>
    action(SET_DELIVERY_METHOD, deliveryMethod);

export const setSKUs = (SKUs: number[]) => action(SET_SKUS, SKUs);
