import * as React from 'react';
import { DropdownArrow } from '../DropdownArrow';
import './SectionHeader.scss';

export interface ISectionHeaderProps {
    sectionTitle: string;
    expanded: boolean;
    initialArrowDown: boolean;
    toggleSection: () => void;
}

export const SectionHeader: React.FunctionComponent<
    ISectionHeaderProps
> = props => {
    const {
        sectionTitle,
        expanded,
        toggleSection,
        children,
        initialArrowDown
    } = props;
    return (
        <div className='section-header'>
            <div className='title-wrapper' onClick={toggleSection}>
                <span className='title'>{sectionTitle}</span>
                <DropdownArrow
                    expanded={expanded}
                    initialArrowDown={initialArrowDown}
                />
            </div>
            {children}
        </div>
    );
};
