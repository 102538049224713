import * as React from 'react';
import { Button } from '../../../../../../Components/Button';
import './PageButtons.scss';

export interface IPageButtonsProps {
    hasPrev: boolean;
    hasNext: boolean;
    getNextPage: () => void;
    getPreviousPage: () => void;
}

export const PageButtons: React.FunctionComponent<
    IPageButtonsProps
> = props => {
    const { hasPrev, hasNext, getPreviousPage, getNextPage } = props;
    return (
        <div className='buttons'>
            {hasPrev && (
                <Button
                    buttonType='secondary'
                    id='previous-page'
                    onClick={getPreviousPage}
                >
                    Previous page
                </Button>
            )}
            {hasNext && (
                <Button
                    buttonType='secondary'
                    id='next-page'
                    onClick={getNextPage}
                >
                    Next page
                </Button>
            )}
        </div>
    );
};
