import * as React from 'react';
import './FooterLinks.scss';

export const FooterLinks: React.FunctionComponent = () => {
    return (
        <div className='footer-right'>
            <span className='company-name'>&copy; Ordnance Survey 2019</span>
        </div>
    );
};
