import * as React from 'react';
import { FormInput } from '../../../../../../../../../../Components/FormInput';
import {
    IOrderLine,
    ProductType
} from '../../../../../../../../../../repository/OrderModel';
import { WIDTH_FIELD_NAMES } from '../../../../../../../../../../utils/validaotrsConst';
import {
    getValidator
} from '../../../../../../../../../../utils/validators';
import { CoverSelect } from './Components/CoverSelect';
import './ProductTitleSection.scss';

export interface IProductTitleSectionProps {
    disabled: boolean;
    type: ProductType;
    coverUrl: string;
    orderLineIndex: number;
    orderLine: IOrderLine;
}

export const ProductTitleSection: React.FunctionComponent<IProductTitleSectionProps> = ({ disabled, type, coverUrl, orderLineIndex, orderLine }) => {
    const {
        product: { title, subtitle, size, productType }
    } = orderLine;
    const titleValidator = getValidator(size, productType, WIDTH_FIELD_NAMES.TITLE);
    const subtitleValidator = getValidator(size, productType, WIDTH_FIELD_NAMES.SUBTITLE);
    const spineValidator = getValidator(size, productType, WIDTH_FIELD_NAMES.SPINE);

    return (
        <div>
            <h1>Cover</h1>
            <div className='inputs'>
                <div className='column'>
                    {title[0] !== undefined && (
                        <FormInput
                            label='Map title line 1'
                            name={`orderLines[${orderLineIndex}].product.title[0]`}
                            type='text'
                            placeholder='Title first line'
                            disabled={disabled}
                            validate={titleValidator}
                        />
                    )}
                    {title[1] !== undefined && (
                        <FormInput
                            label='Map title line 2'
                            name={`orderLines[${orderLineIndex}].product.title[1]`}
                            type='text'
                            placeholder='Title second line'
                            disabled={disabled}
                            validate={titleValidator}
                        />
                    )}
                    {title[2] !== undefined && (
                        <FormInput
                            label='Map title line 3'
                            name={`orderLines[${orderLineIndex}].product.title[2]`}
                            type='text'
                            placeholder='Title third line'
                            disabled={disabled}
                            validate={titleValidator}
                        />
                    )}
                </div>
                <div className='column'>
                    {subtitle[0] !== undefined && (
                        <FormInput
                            label='Subtitle 1'
                            name={`orderLines[${orderLineIndex}].product.subtitle[0]`}
                            type='text'
                            placeholder='Subtitle first line'
                            disabled={disabled}
                            validate={subtitleValidator}
                        />
                    )}
                    {subtitle[1] !== undefined && (
                        <FormInput
                            label='Subtitle 2'
                            name={`orderLines[${orderLineIndex}].product.subtitle[1]`}
                            type='text'
                            placeholder='Subtitle second line'
                            disabled={disabled}
                            validate={subtitleValidator}
                        />
                    )}
                    <FormInput
                        label='Spine'
                        name={`orderLines[${orderLineIndex}].product.spineText`}
                        type='text'
                        placeholder='Spine text'
                        disabled={disabled}
                        validate={spineValidator}
                    />
                </div>
                {type === ('FOLDED_MAP' as ProductType) && (
                    <div className='column'>
                        <CoverSelect
                            coverUrl={coverUrl}
                            orderLineIndex={orderLineIndex}
                            disabled={disabled}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
