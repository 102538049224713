import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Types from 'Types';
import {
    orderListModel,
    orderListSelectors,
    ordersListActions
} from '../../../../features/OrdersList';
import { PageButtons } from './Components/PageButtons';
import { PageInfo } from './Components/PageInfo';
import { PageSizeSelect } from './Components/PageSizeSelect';
import './Pagination.scss';

export interface IPaginationProps {
    loadingOrders: boolean;
    pageInfo: orderListModel.IPageInfo;
    selectedPageSize: number;
    changePage: (page: number) => void;
    changePageSize: (page: number) => void;
}

export class Pagination extends React.PureComponent<IPaginationProps> {
    public render() {
        const {
            hasNext,
            hasPrevious,
            pageNumber,
            pagesConut,
            pageStart,
            pageEnd,
            totalItems
        } = this.props.pageInfo;

        const { selectedPageSize, changePageSize, loadingOrders } = this.props;

        return (
            <React.Fragment>
                {!loadingOrders && totalItems > 0 && (
                    <div className='pagination'>
                        <PageSizeSelect
                            pageSize={selectedPageSize}
                            changePageSize={changePageSize}
                        />
                        {(hasNext || hasPrevious) && (
                            <PageButtons
                                hasPrev={hasPrevious}
                                hasNext={hasNext}
                                getNextPage={this.getNextPage}
                                getPreviousPage={this.getPreviousPage}
                            />
                        )}
                        <PageInfo
                            pageNumber={pageNumber}
                            pagesConut={pagesConut}
                            pageStart={pageStart}
                            pageEnd={pageEnd}
                            totalItems={totalItems}
                            showingButtons={hasPrevious || hasNext}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }

    private getNextPage = () => {
        this.props.changePage(this.props.pageInfo.pageNumber + 1);
    };

    private getPreviousPage = () => {
        this.props.changePage(this.props.pageInfo.pageNumber - 1);
    };
}

const mapStateToProps = (state: Types.RootState) => ({
    loadingOrders: orderListSelectors.getLoadingOrders(state),
    pageInfo: orderListSelectors.getPageInfo(state),
    selectedPageSize: orderListSelectors.getSelectedPageSize(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            changePage: ordersListActions.changePage,
            changePageSize: ordersListActions.changePageSize
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pagination);
