import { ActionType } from 'typesafe-actions';
import { ICover, IOrder } from '../../repository/OrderModel';
import * as orderActions from './actions';
import { GET_ORDER, SET_COVER, SET_ORDER, UPDATE_ORDER } from './constants';
import { emptyOrder } from './StateFixture/emptyOrder';

export type OrderState = {
    readonly loadingOrder: boolean;
    readonly order: IOrder;
    readonly covers: ICover[];
};

export type OrderActions = ActionType<typeof orderActions>;

const initialState: OrderState = {
    loadingOrder: true,
    order: emptyOrder,
    covers: []
};

const orderReducer = (
    state: OrderState = initialState,
    action: OrderActions
) => {
    switch (action.type) {
        case GET_ORDER:
            return { ...state, loadingOrder: true };
        case SET_ORDER:
            return {
                ...state,
                loadingOrder: false,
                order: action.payload,
                covers: action.payload.orderLines.map(
                    orderLine => ({ url: orderLine.product.coverUrl } as ICover)
                )
            };
        case UPDATE_ORDER: {
            return { ...state, loadingOrder: true };
        }
        case SET_COVER: {
            const covers = [...state.covers];
            covers[action.payload.coverIndex] = {
                url: action.payload.url,
                image: action.payload.file
            };
            return { ...state, covers };
        }
        default:
            return state;
    }
};

export default orderReducer;
